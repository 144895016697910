import { faLocationDot, faUser, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import AsyncSection from '~/components/common/AsyncSection';
import { formatCountry, formatGender, formatGroup, FormatWebsite } from '~/components/common/Filter/formatFilter';

const Information: React.FC = () => {
  //Declare data from Reducers
  const { status, customerDetail } = useAppSelector((state) => state.customerReducer);
  const { addresses } = customerDetail;

  const renderPersonalInfoRow = (className: string, title: string, value: string, key: string) => {
    return (
      <div className={`row ${className}`} key={key}>
        <div className="col-sm-4">
          <span className="fs-14 mb-3 fw-medium">{title}</span>
        </div>
        <div className="col-sm-8">
          <span className="fs-14 mb-3 text-display-inline">{value}</span>
        </div>
      </div>
    );
  };

  const renderInformation = () => {
    const infoFields = [
      { label: 'First Name', value: customerDetail.firstname },
      { label: 'Middle Name', value: customerDetail.middlename ? customerDetail.middlename : 'N/A' },
      { label: 'Last Name', value: customerDetail.lastname },
      { label: 'Email', value: customerDetail.email },
      { label: 'Associate to Website', value: FormatWebsite(customerDetail.website_id) },
      { label: 'Group', value: formatGroup(customerDetail.group_id) },
      { label: 'Date Of Birth', value: customerDetail.dob ? moment(customerDetail.dob).format('DD/MM/YYYY') : 'N/A' },
      { label: 'Gender', value: formatGender(customerDetail.gender) },
      // { label: 'Send Welcome Email From', value: FormatWebsite(customerDetail.website_id) },
      { label: 'Tax/VAT Number', value: customerDetail.taxvat ? customerDetail.taxvat : 'N/A' },
    ];

    return (
      <div className="col-12 col-lg-6">
        <div className="col-info h-100 mb-2 mb-lg-0">
          <div className="bg-white px-4 py-4 wrapper h-100">
            <div className="d-flex">
              <div className="icon">
                <FontAwesomeIcon icon={faUser} className="fs-5 border rounded-circle icon" />
              </div>
              <div className="ms-4 w-100">
                <p className="person-info-title mt-2 mb-4">account information</p>
                {infoFields.map((field) => renderPersonalInfoRow('mb-4', field.label, field.value, field.label))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderShippingAddress = () => {
    return (
      <div className="col-12">
        <div className="bg-white px-4 py-4 wrapper h-100">
          <div className="d-flex">
            <div className="icon">
              <FontAwesomeIcon icon={faLocationDot} className="fs-5 border rounded-circle icon-shipping" />
            </div>
            <div className="ms-4 w-100">
              <p className="person-info-title mt-2 mb-4">shipping address</p>
              {addresses.length > 0 && addresses.filter((item: any) => item.default_shipping === true).length > 0 ? (
                addresses
                  .filter((item: any) => item.default_shipping === true)
                  .map((item: any) => {
                    const infoFields = [
                      { label: 'Name', value: `${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}` },
                      {
                        label: 'Street Address',
                        value: item.street.map((itemAdds: string, index: number) =>
                          index === item.street.length - 1 ? ` ${itemAdds}` : `${itemAdds}, `,
                        ),
                      },
                      { label: 'City & Zip', value: `${item.city}${item.region.region ? `, ${item.region.region}` : ''}, ${item.postcode}` },
                      { label: 'Country', value: formatCountry(item.country_id) },
                      { label: 'Phone', value: item.telephone },
                    ];
                    return infoFields.map((field) => renderPersonalInfoRow('mb-3', field.label, field.value, field.label));
                  })
              ) : (
                <div className="row mb-3">
                  <span className="fs-14 fw-medium">The customer does not have default shipping address.</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBillingAddress = () => {
    return (
      <div className="col-12 mb-3">
        <div className="bg-white px-4 py-4 wrapper h-100">
          <div className="d-flex">
            <div className="icon">
              <FontAwesomeIcon icon={faDollarSign} className="fs-5 border rounded-circle icon-billing" />
            </div>
            <div className="ms-4 w-100">
              <p className="person-info-title mt-2 mb-4">billing address</p>
              {addresses.length > 0 && addresses.filter((item: any) => item.default_billing === true).length > 0 ? (
                addresses
                  .filter((item: any) => item.default_billing === true)
                  .map((item: any) => {
                    const infoFields = [
                      { label: 'Name', value: `${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}` },
                      {
                        label: 'Street Address',
                        value: item.street.map((itemAdds: string, index: number) =>
                          index === item.street.length - 1 ? ` ${itemAdds}` : `${itemAdds}, `,
                        ),
                      },
                      { label: 'City & Zip', value: `${item.city}${item.region.region ? `, ${item.region.region}` : ''}, ${item.postcode}` },
                      { label: 'Country', value: formatCountry(item.country_id) },
                      { label: 'Phone', value: item.telephone },
                    ];
                    return infoFields.map((field) => renderPersonalInfoRow('mb-3', field.label, field.value, field.label));
                  })
              ) : (
                <div className="row mb-3">
                  <span className="fs-14 fw-medium">The customer does not have default billing address.</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <AsyncSection status={status} className="row mt-4">
      {renderInformation()}
      <div className="col-12 col-lg-6">
        <div className="address-col row h-100">
          {renderBillingAddress()}
          {renderShippingAddress()}
        </div>
      </div>
    </AsyncSection>
  );
};

export default Information;
