import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { TABLE_HEAD_OPTIONS_ATTRIBUTE } from '~/app/constants';
import { SCOPE_OF_ATTRIBUTE, dynamicCatalog } from '~/app/constants/filter';
import Button from '~/components/common/Button';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import { Input } from '~/components/common/Input';
import Select from '~/components/common/Select';
import dragImg from '~/app/images/drag.png';
import { v4 as uuidv4 } from 'uuid';
import { dynamicSchemaFormCreateEditAttribute, formCreateEditAttributeSchema } from './Schema';
import { iDataCreateEditAttributes, scopeAttribute } from '~/app/models';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { changeDataEdit, createAttribute, editAttribute } from './redux/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface FormDataProductAttribute {
  is_required: boolean;
  is_unique: boolean;
  update_product_preview_image: boolean;
  default_frontend_label: string;
  attribute_code: string;
  frontend_input: string;
  scope: scopeAttribute;
}

export interface iOptionsOfAttribute {
  id: string;
  is_default: boolean;
  label: string;
}

const CreateEditAttribute = () => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { statusCreateEditAttribute, dataEdit } = useAppSelector((s) => s.productAttributeReducer);

  const [optionsOfAttribute, setOptionsOfAttribute] = useState<iOptionsOfAttribute[]>([]);

  const allSchema = useMemo(() => {
    return formCreateEditAttributeSchema.concat(dynamicSchemaFormCreateEditAttribute(optionsOfAttribute));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsOfAttribute]);

  useEffect(() => {
    if (!dataEdit) {
      resetForm();
      setOptionsOfAttribute([]);
      return;
    }

    const { is_required, is_unique, attribute_code, default_frontend_label, frontend_input, scope, options, default_value } = dataEdit;
    resetForm();
    setValues({
      is_required,
      is_unique: !!+is_unique,
      attribute_code,
      default_frontend_label,
      frontend_input: frontend_input ? frontend_input : values.frontend_input,
      scope,
      update_product_preview_image: false,
    });

    if (!['multiselect', 'select', 'swatch_visual', 'swatch_text'].includes(frontend_input as string) && !!optionsOfAttribute.length)
      setOptionsOfAttribute([]);

    if (Array.isArray(options) && options.length && ['multiselect', 'select', 'swatch_visual', 'swatch_text'].includes(frontend_input as string)) {
      const newOptions: iOptionsOfAttribute[] = options
        .filter((item) => !!item.value)
        .map((item) => ({
          id: uuidv4(),
          is_default: default_value === item.value,
          label: item.label,
        }));

      !!newOptions.length && setOptionsOfAttribute(newOptions);
    }

    return () => resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  useEffect(() => {
    optionsOfAttribute.map((item) => setFieldValue(item.id, item.label));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsOfAttribute]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    setValues,
    resetForm,
    validateField,
  } = useFormik<FormDataProductAttribute>({
    initialValues: {
      is_required: false,
      is_unique: false,
      update_product_preview_image: true,
      default_frontend_label: '',
      attribute_code: '',
      frontend_input: 'swatch_text',
      scope: 'global',
    },
    validationSchema: allSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: handleSubmitForm,
  });

  function handleAddOption() {
    const newOption: iOptionsOfAttribute = {
      id: uuidv4(),
      is_default: false,
      label: '',
    };

    setOptionsOfAttribute((prev) => [...prev, newOption]);
  }

  function handleCancel() {
    dataEdit && dispatch(changeDataEdit(null));
    resetForm();
  }

  function handleDeleteOption(id: string) {
    // Remove error
    if (errors[id as keyof typeof errors]) setFieldError(id, undefined);
    setOptionsOfAttribute((prev) => prev.filter((item) => item.id !== id));
  }

  function handleChangeOption(e: ChangeEvent<HTMLInputElement>, id: string) {
    const { value, name } = e.target;

    const optionList = [...optionsOfAttribute];
    const index = optionsOfAttribute.findIndex((option) => option.id === id);
    if (index < 0) return;

    // Remove error
    if (errors[id as keyof typeof errors]) setFieldError(id, undefined);
    optionList[index] = { ...optionList[index], [name]: value };
    setOptionsOfAttribute(optionList);
  }

  function handleChangeRadio(id: string) {
    const optionList = optionsOfAttribute.map((o) => ({ ...o, is_default: o.id === id ? true : false }));
    setOptionsOfAttribute(optionList);
  }

  function handleSubmitForm(values: FormDataProductAttribute) {
    const { attribute_code, default_frontend_label, frontend_input, is_required, is_unique, scope } = values;
    const { attribute_id } = dataEdit || {};

    const _optionsOfAttribute = [...optionsOfAttribute];
    const optionsPayload = _optionsOfAttribute?.reverse().map((option, i) => {
      return {
        label: option.label,
        is_default: option.is_default,
        sort_order: i,
      };
    });

    const payload: iDataCreateEditAttributes = {
      attribute: {
        ...(dataEdit ? { attribute_id } : {}),
        attribute_code,
        default_frontend_label,
        entity_type_id: '4',
        frontend_input,
        is_required,
        scope,
        is_unique: (+is_unique).toString(),
        options: optionsPayload,
        ...(!dataEdit && { is_visible_on_front: '1' }),
      },
    };

    // Edit
    if (dataEdit) {
      dispatch(
        editAttribute({
          currentStore,
          attribute_code: dataEdit.attribute_code,
          data: payload,
        }),
      );
      return;
    }

    // Create
    dispatch(
      createAttribute({
        currentStore,
        data: payload,
      }),
    );
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          {TABLE_HEAD_OPTIONS_ATTRIBUTE.map((item, i) => (
            <th className={item.className} key={i}>
              {item.name}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);

  function handleSort() {
    let _optionsOfAttribute = [...optionsOfAttribute];

    // remove and save the dragged item content
    const draggedItemContent = _optionsOfAttribute.splice(dragItem.current, 1)[0];

    // switch the position
    _optionsOfAttribute.splice(dragOverItem.current, 0, draggedItemContent);

    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    // update the actual array
    setOptionsOfAttribute(_optionsOfAttribute);
  }

  function renderTableBody() {
    return (
      <tbody className="info-item">
        {optionsOfAttribute.map((option, index) => {
          const { id, label, is_default } = option;

          return (
            <tr
              key={id}
              data-id={id}
              className="option-of-attribute bg-white align-middle"
              draggable
              onDragStart={() => (dragItem.current = index)}
              onDragEnter={() => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
            >
              <td className="fs-14 py-3 pe-3 align-middle">
                <img src={dragImg} alt="drag icon" />
              </td>
              <td className="fs-14 py-3 pe-3 align-middle">
                <div className="form-check">
                  <input
                    checked={is_default}
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="is_default"
                    onChange={() => handleChangeRadio(id)}
                  />
                </div>
              </td>
              <td className="fs-14 py-3 pe-3 align-middle" draggable={false}>
                <div className="d-flex flex-column gap-2">
                  <Input className="fs-14" name="label" value={label} onChange={(e) => handleChangeOption(e, id)} onBlur={() => validateField(id)} />
                  {errors[id as keyof typeof errors] && <span className="text-danger fs-7">{errors[id as keyof typeof errors]}</span>}
                </div>
              </td>
              <td className="fs-14 py-3 align-middle text-end link" onClick={() => handleDeleteOption(id)}>
                Remove
              </td>
            </tr>
          );
        })}
        <tr>
          <td className="bg-white py-3" colSpan={TABLE_HEAD_OPTIONS_ATTRIBUTE.length}>
            <span className="cursor-pointer btn-add-option fs-14" onClick={handleAddOption}>
              + Add Swatch
            </span>
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <div className="create-edit-attribute wrapper p-4 d-flex flex-column gap-4">
      <div
        className={`d-flex align-items-center gap-3 w-fit-content ${dataEdit ? 'form-heading cursor-pointer' : ''}`}
        onClick={() => dataEdit && handleCancel()}
      >
        {dataEdit && <FontAwesomeIcon icon={faChevronLeft} />}
        <h3 className="m-0 fw-semibold fs-5">{dataEdit ? `Edit #${dataEdit.default_frontend_label}` : 'New Product Attribute'}</h3>
      </div>
      {/* Checkbox rounded */}
      <div className="d-flex flex-column gap-3">
        {!['weee', 'media_image'].includes(values.frontend_input) && (
          <div className="d-flex align-items-center justify-content-between gap-4">
            <label className="fs-14 cursor-pointer" htmlFor="is_required">
              Values Required
            </label>
            <CheckboxRounded check={values.is_required} id="is_required" name="is_required" onChange={handleChange} />
          </div>
        )}

        {['swatch_visual', 'swatch_text'].includes(values.frontend_input) && (
          <div className="d-flex align-items-center justify-content-between gap-4">
            <div className="d-flex flex-column gap-1">
              <label className="fs-14 cursor-pointer" htmlFor="update_product_preview_image">
                Update Product Preview Image
              </label>
              <span className="text-dark-600 fs-7">Filtering by this attribute will update the product image on catalog page.</span>
            </div>
            <CheckboxRounded
              check={values.update_product_preview_image}
              id="update_product_preview_image"
              name="update_product_preview_image"
              onChange={handleChange}
            />
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between gap-4">
          <div className="d-flex flex-column gap-1">
            <label className="fs-14 cursor-pointer" htmlFor="is_unique">
              Unique Value
            </label>
            <span className="text-dark-600 fs-7">Not shared with other products.</span>
          </div>
          <CheckboxRounded check={values.is_unique} id="is_unique" name="is_unique" onChange={handleChange} />
        </div>
      </div>

      {/* Body */}
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-2">
          <label className="fs-14 cursor-pointer" htmlFor="default_frontend_label">
            Name <span className="text-danger">*</span>
          </label>
          <Input
            className="fs-14"
            value={values.default_frontend_label}
            type="text"
            id="default_frontend_label"
            name="default_frontend_label"
            onChange={(e) => {
              errors.default_frontend_label && setFieldTouched('default_frontend_label', false);
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {errors.default_frontend_label && touched.default_frontend_label && (
            <span className="fs-7 text-danger">{errors.default_frontend_label}</span>
          )}
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fs-14 cursor-pointer" htmlFor="attribute_code">
            Attribute Code
          </label>
          <Input
            className="fs-14"
            value={values.attribute_code}
            type="text"
            id="attribute_code"
            name="attribute_code"
            onChange={(e) => {
              errors.attribute_code && setFieldTouched('attribute_code', false);
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {errors.attribute_code && touched.attribute_code && <span className="fs-7 text-danger">{errors.attribute_code}</span>}
        </div>

        <div className="row gy-3">
          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="frontend_input">
                Catalog Input Type for Store Owner
              </label>
              <Select disabled className="w-100 fs-14" name="frontend_input" id="frontend_input" value={values.frontend_input}>
                {dynamicCatalog(!!dataEdit, values.frontend_input).map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="scope">
                Scope
              </label>
              <Select className="w-100 fs-14" name="scope" id="scope" value={values.scope} onChange={handleChange}>
                {SCOPE_OF_ATTRIBUTE.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>

      {['multiselect', 'swatch_visual', 'swatch_text', 'select'].includes(values.frontend_input) && (
        <table className="table-product-attributes w-100">
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      )}

      {/* Action */}
      <div className="d-flex justify-content-between gap-3 align-items-center">
        <Button disabled={statusCreateEditAttribute === 'pending'} className="flex-grow-1 w-100" outline onClick={handleCancel}>
          CANCEL
        </Button>
        <Button
          disabled={statusCreateEditAttribute === 'pending'}
          showLoadingRight={statusCreateEditAttribute === 'pending'}
          className="flex-grow-1 w-100"
          onClick={handleSubmit}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default CreateEditAttribute;
