import * as yup from 'yup';
import { iOptionsOfAttribute } from '../CreateEditAttribute';

const regexCharacter = /^[a-zA-Z]+[a-zA-Z0-9_]+$/;
export const formCreateEditAttributeSchema = yup.object().shape({
  default_frontend_label: yup.string().required('This field is required.'),
  attribute_code: yup
    .string()
    .max(60, 'Please enter less or equal than 60 symbols.')
    .matches(
      regexCharacter,
      'Please use only letters (a-z or A-Z), numbers (0-9) or underscore (_) in this field, and the first character should be a letter.',
    ),
});

export function dynamicSchemaFormCreateEditAttribute(optionsOfAttribute: iOptionsOfAttribute[]) {
  const dynamicSchema = optionsOfAttribute.reduce((result, item) => ({ ...result, [item.id]: yup.string().required('This field is required.') }), {});
  const result = yup.object().shape(dynamicSchema);
  return result;
}
