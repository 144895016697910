import './style.scss';

interface Props {
  children: string;
  disabled?: boolean;
  type?: 'submit' | 'button';
  classCss?: string | null
}

const ButtonForm = (props: Props) => {
  const { children, disabled, type, classCss } = props;
  return (
    <button
      className={`${!!classCss && classCss} button-form text-center w-100 mt-3 py-3 cursor-pointer text-uppercase border-0 text-white`}
      type={type || 'submit'}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonForm;
