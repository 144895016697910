import {
  faChartSimple,
  faList,
  faBagShopping,
  faUsers,
  faLineChart,
  faChevronDown,
  faChevronUp,
  faUser,
  faTags,
  faPercent,
  faGear,
  faBullhorn,
  faStore,
  faStarHalfStroke,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { getQtyNewTicket } from '~/components/layout/FullWidthLayout/redux/actions';
import { addFilterGroup, addFilterItem, addFilters, getStoreId, searchCriteriaBuilder } from '~/app/utils';

interface iNavigation {
  icon: any;
  name: string;
  path: string;
  realName: string | [];
  child?: any[] | undefined;
  badge?: any;
}

interface Props {}

const Navigation: React.FC<Props> = (props: Props) => {
  const [storeId, setStoreId] = useState<number>();
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  const { qtyNewTicket, accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  const { queryString: customerQuery } = useAppSelector((s) => s.customerReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);
  const { queryString: sellerQuery } = useAppSelector((s) => s.sellerReducer);
  const { queryString: ticketQuery } = useAppSelector((s) => s.ticketReducer);
  const { queryString: orderQuery } = useAppSelector((s) => s.orderReducer);
  const { queryString: promotionQuery } = useAppSelector((s) => s.promotionReducer);
  const { queryString: reviewQuery } = useAppSelector((s) => s.reviewReducer);
  const { queryString: brandQuery } = useAppSelector((s) => s.brandReducer);
  const { queryString: productAttributesQuery } = useAppSelector((s) => s.productAttributeReducer);
  const { isDisplayNav } = useAppSelector((state) => state.pageReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  //const arrPathName = pathname.split('/');
  //const dashboard = pathname === '/';

  // Declare useEffect
  useEffect(() => {
    setStoreId(getStoreId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    const closeBtn = document.querySelectorAll('#ckf-modal-close') as any;

    if (!closeBtn) return;
    closeBtn[0]?.click();
  }, [pathname]);

  useEffect(() => {
    if (storeId) {
      dispatch(
        getQtyNewTicket(
          searchCriteriaBuilder(
            Number.MAX_VALUE,
            1,
            addFilterGroup(addFilters(addFilterItem('is_read', '0', 'eq')), addFilters(addFilterItem('store_id', `${storeId}`, 'eq'))),
          ),
          accessToken,
          currentStore,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, storeData, currentStore]);

  const navigation = [
    { realName: '/', icon: faChartSimple, name: 'Dashboard', path: '/dashboard' },
    //{ realName: 'stores', icon: faShop, name: 'Stores', path: '/stores' },
    {
      realName: ['products', 'product'],
      icon: faList,
      name: 'Products',
      path: `/products/${productQuery}`,
      child: [
        { realName: 'products', name: 'Product Listing', path: `/products/${productQuery}` },
        { realName: 'product', name: 'Create Product', path: '/product/create' },
      ],
    },
    {
      realName: ['orders', 'order'],
      icon: faBagShopping,
      name: 'Orders',
      path: `/orders/${orderQuery}`,
      // child: [
      //   { realName: 'orders', name: 'Order Listing', path: `/orders/${orderQuery}` },
      //   { realName: 'order', name: 'Create Order', path: '/order/create' },
      // ],
    },
    {
      realName: ['customers', 'customer'],
      icon: faUsers,
      name: 'Customers',
      path: `/customers/${customerQuery}`,
      // child: [
      // { realName: 'customers', name: 'Customers Listing', path: `/customers/${customerQuery}` },
      // { realName: 'customer', name: 'Create Customer', path: '/customer/create' },
      // ],
    },
    {
      realName: ['sellers', 'seller'],
      icon: faUser,
      name: 'Sellers',
      path: `/sellers/${sellerQuery}`,
      // child: [
      // { realName: 'sellers', name: 'Sellers Listing', path: `/sellers/${sellerQuery}` },
      // { realName: 'seller', name: 'Create Seller', path: '/seller/create' },
      // ],
    },
    //{ realName: 'attributes', icon: faToggleOn, name: 'Attributes', path: '/attributes' },
    { realName: 'sales-report', icon: faLineChart, name: 'Reports', path: `/sales-report` },
    {
      realName: ['tickets', 'ticket'],
      icon: faTags,
      name: 'Tickets',
      path: `/tickets/${ticketQuery}`,
      badge: qtyNewTicket ? (
        <span className="badge bg-danger rounded-pill badge-new-ticket text-center">{qtyNewTicket > 10 ? '10+' : qtyNewTicket}</span>
      ) : null,
    },
    {
      realName: ['promotions', 'promotion'],
      icon: faPercent,
      name: 'Promotion',
      path: `/promotions/${promotionQuery}`,
    },
    {
      realName: ['reviews'],
      icon: faGear,
      name: 'Reviews',
      path: `/reviews/${reviewQuery}`,
    },
    {
      realName: ['brands'],
      icon: faGear,
      name: 'Brands',
      path: `/brands/${brandQuery}`,
    },
    {
      realName: ['categories', 'attributes-set', 'product-attributes'],
      icon: faGear,
      name: 'Configuration',
      path: `/product-attributes`,
      child: [
        { realName: 'product-attributes', name: 'Product Attributes', path: '/product-attributes' },
        { realName: 'categories', name: 'Categories', path: `/categories` },
        { realName: 'attributes-set', name: 'Attribute Set', path: '/attributes-set' },
      ],
    },
  ] as iNavigation[];

  const showChildMenu = (child: any[] | undefined, childPositionLast: boolean = false) => {
    if (typeof child === 'undefined' || !child || child.length <= 0) return '';

    return (
      <ul className={`sub-menu-item mt-2 mt-xxl-4 ${childPositionLast ? 'mb-2 mb-xxl-4' : 'mb-0'}`}>
        {child.map((item, i) => {
          const activeCss = checkToShowActive(pathname, item.realName) ? 'active' : '';
          return (
            <li key={i}>
              <Link to={item.path} className={`${activeCss} navigate-item fw-medium px-0`} title={item.name}>
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const checkToShowActive = (list1: string, list2: string | any[]) => {
    if (!list1 || list1.length <= 0 || !list2 || list2.length <= 0) return false;

    const realPath = list1.split('/');

    if (typeof list2 === 'string') {
      return list2 === list1 ? true : realPath.includes(list2);
    }

    let findX = false;
    realPath.forEach((x) => {
      if (!findX) findX = list2.includes(x);
    });

    return findX;
  };

  function handleClickNavigateItem(path: null | string, index: number) {
    if (path) {
      activeIndex !== undefined && setActiveIndex(undefined);
      return;
    }

    setActiveIndex(activeIndex === index ? undefined : index);
  }

  return (
    <nav className={`navigator`}>
      <div className="navigator--items">
        {isDisplayNav
          ? navigation.map((item, i: number) => {
              const Comp = item.path ? Link : 'div';
              const activeCss = checkToShowActive(pathname === '/dashboard' ? '/' : pathname, item.realName) ? 'active' : '';
              const childPositionLast = navigation.length - 1 === i ? !!item.child : false;

              return (
                <div key={i} className={`item-wrapper w-100 overflow-hidden`}>
                  <Comp
                    onClick={() => handleClickNavigateItem(item.path, i)}
                    className={`navigate-item d-flex align-items-center justify-content-between py-4 w-100 cursor-pointer ${activeCss}`}
                    title={item.name}
                    to={item.path}
                  >
                    <span className="d-flex align-items-center">
                      {/* <span className="menu-icon"> */}
                      <div className="icon-wrap d-flex align-items-center">
                        <FontAwesomeIcon icon={item.icon} className="fs-5" />
                      </div>
                      {/* </span> */}
                      <span className="fw-medium">{item.name}</span>
                      {item.badge && <div className="ms-2">{item.badge}</div>}
                    </span>
                    {!!item.child && (
                      <span className="float-end">
                        <FontAwesomeIcon icon={activeCss || activeIndex === i ? faChevronUp : faChevronDown} />
                      </span>
                    )}
                  </Comp>
                  {(activeCss || activeIndex === i) && showChildMenu(item.child, childPositionLast)}
                </div>
              );
            })
          : // Menu only show icon
            navigation.map((item, i: number) => {
              const activeCss = checkToShowActive(pathname === '/dashboard' ? '/' : pathname, item.realName) ? 'active' : '';
              return (
                <div key={i} className={`item-wrapper w-100 overflow-hidden`}>
                  <Link
                    className={`d-inline-block px-3 py-4 w-100 text-center ${activeCss}`}
                    to={item.path ? item.path : item?.child?.[0].path}
                    title={item.name}
                  >
                    <span className="d-inline">
                      <span className="menu-icon">
                        <div className="position-relative">
                          <FontAwesomeIcon icon={item.icon} className="fs-4 fs-xxl-3" />
                          {item.badge && <div className="position-absolute top-0 start-100 translate-middle">{item.badge}</div>}
                        </div>
                      </span>
                    </span>
                  </Link>
                </div>
              );
            })}
      </div>
    </nav>
  );
};

export default Navigation;
