import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { timeConvert } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetCreditMemos, iGetOrderData, iTableHead } from '~/app/models';
import { addFilterGroup, addFilterItem, addFilters, formatMoney, searchCriteriaBuilder } from '~/app/utils';
import AsyncSection from '~/components/common/AsyncSection';
import Button from '~/components/common/Button';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';
import { getMemos } from '../redux/actions';

type Props = {};
const CREDIT_TABLE: iTableHead[] = [
  { name: 'Credit Memo', className: 'py-3 ps-4 fs-14 fw-medium td-item' },
  { name: 'Created', className: 'py-3 fs-14 fw-medium text-end td-item' },
  { name: 'Order #', className: 'py-3 fs-14 fw-medium td-item' },
  { name: 'Order Date', className: 'py-3 fs-14 fw-medium text-end td-item' },
  { name: 'Bill-to Name', className: 'py-3 fs-14 fw-medium td-item' },
  { name: 'Refunded', className: 'py-3 fs-14 fw-medium text-end td-item' },
  { name: 'Status', className: 'py-3 fs-14 fw-medium text-center td-item' },
  { name: 'Action', className: 'py-3 fs-14 fw-medium text-center td-item' },
];
const CreditMemo: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { status, memos, dataDetails } = useAppSelector((s) => s.orderReducer);

  const inititalState: iGetCreditMemos = {
    pageSize: 10,
    currentPage: 1,
    accessToken,
  };

  const [dataPayload, setDataPayload] = useState<iGetCreditMemos>(inititalState);

  const { pageSize, currentPage } = dataPayload;

  const { items, total_count, search_criteria } = memos;

  const { extension_attributes } = dataDetails;

  const { orderId } = useParams();

  useEffect(() => {
    const payload: iGetOrderData = {
      accessToken,
      searchURL: searchCriteriaBuilder(
        +pageSize,
        +currentPage,
        addFilterGroup(addFilters(addFilterItem('order_id', `${orderId}`, 'eq'))),
        // addSortOrder('desc')
      ),
    };

    dispatch(getMemos(payload, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPayload.currentPage, dataPayload.pageSize, dispatch]);

  const onChangeHandler = (e: any) => {
    const { value, name } = e.target;
    setDataPayload({ ...dataPayload, [name]: value });
  };

  const onChangePage = (currentPage: string | number) => {
    setDataPayload({ ...dataPayload, currentPage });
  };

  const navigate = useNavigate();

  const formatStatus = (status: any) => {
    if (!status) return <></>;
    if (status === 1) return <span className="d-inline-block pills bg-warning text-white text-capitalize fw-medium fs-14">Pending</span>;
    if (status === 2) return <span className="d-inline-block pills bg-success text-white text-capitalize fw-medium fs-14">Refunded</span>;
    if (status === 3) return <span className="d-inline-block pills bg-danger text-white text-capitalize fw-medium fs-14">Canceled</span>;
  };

  const renderTable = () => {
    return (
      status === 'fulfilled' && (
        <div className="border-rd-20 overflow-auto bg-white wrapper">
          <Table status="fulfilled" dataTableHead={CREDIT_TABLE}>
            {items.length ? (
              items.map((item: any, key: number) => {
                return (
                  <tr className="table--item" key={key}>
                    <td className="td-item ps-4 pe-2 py-4 fs-14">{item.increment_id}</td>
                    <td className="td-item px-2 py-4 fs-14 text-end">
                      <div className="d-flex justify-content-end flex-column flex-xl-row gap-0 gap-xl-2 text-nowrap">
                        {timeConvert(item.created_at)}
                      </div>
                    </td>
                    <td className="td-item px-2 py-4 fs-14 ">{dataDetails.increment_id}</td>
                    <td className="td-item px-2 py-4 fs-14 text-end">
                      <div className="d-flex justify-content-end flex-column flex-xl-row gap-0 gap-xl-2 text-nowrap">
                        {timeConvert(dataDetails.created_at)}
                      </div>
                    </td>

                    <td className="td-item px-2 py-4 fs-14">
                      {extension_attributes &&
                        `${dataDetails.billing_address.firstname}
                  ${dataDetails.billing_address.lastname}`}
                    </td>
                    <td className="td-item px-2 py-4 fs-14 text-end">{formatMoney(item.base_grand_total, currentStore)}</td>
                    <td className="td-item px-2 py-4 fs-14 text-center">{formatStatus(item.state)}</td>
                    <td className="td-item px-2 py-4 fs-14 text-center">
                      <Link className="text-decoration-none fs-6 view-icon" to={`${item.entity_id}`}></Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecord tableHeaders={CREDIT_TABLE} />
            )}
          </Table>
        </div>
      )
    );
  };

  return (
    <>
      <AsyncSection status={status} className="credit-memo mt-4">
        <div className="row">
          {dataDetails?.extension_attributes?.can_creditmemo === '1' && (
            <div className="d-flex justify-content-end">
              <Button className="mb-3" onClick={() => navigate('create')}>
                CREATE CREDIT MEMO
              </Button>
            </div>
          )}

          <div className="col-sm-12">{renderTable()}</div>
        </div>
      </AsyncSection>
      <Pagination
        status={status}
        className=""
        currentPage={search_criteria.current_page}
        totalCount={total_count}
        pageSize={search_criteria.page_size}
        onChangePageSizeEvent={onChangeHandler}
        onChangePageEvent={onChangePage}
      />
    </>
  );
};

export default CreditMemo;
