import Button from '~/components/common/Button';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import { Input, TextArea } from '~/components/common/Input';
import uploadImg from '~/app/images/camera.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import { convertImageBase64ToPayload, convertSize, slugify, swalToast } from '~/app/utils';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { convertBase64 } from '~/app/utils/convertBase64';
import { useFormik } from 'formik';
import { formCategorySchema } from './Schema';
import { defaultMessageError } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { createCategories, editCategoryById, setCurrentAction, setFormDataCategory, setParentCategory } from './redux/action';
import { iCategoryItemPayload } from '~/app/models/category';
import Loading from '~/components/common/Loading';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export interface FormDataCategory {
  filter_price_range: string;
  include_in_menu: boolean;
  is_active: boolean;
  name: string;
  parent_id: number;
  description: string;
  image: string;
  shipping_percat: string;
  image_name: string;
  size: number;
  url_key: string;
  meta_title: string;
  meta_keywords: string;
  meta_description: string;
  // display_mode: string;
  // is_anchor: boolean;
  // available_sort_by: string[];
  // mp_ln_hide_attribute_ids: string[];
  // default_sort_by: string;
}

// interface iUserDefault {
//   use_all_available_sort_by: boolean;
//   use_config_default_available_sort_by: boolean;
//   use_config_filter_price_range: boolean;
// }

const CreateEditCategory = () => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { formData, currentAction, parentCategory, statusCreateCategory, statusGetCategoryById, statusEditCategoryById } = useAppSelector(
    (s) => s.categoryReducer,
  );
  const [showSearchOptimize, setShowSearchOptimize] = useState<boolean>(false);
  // const [showSetting, setShowSetting] = useState<boolean>(false);
  // const [useDefault, setUserDefault] = useState<iUserDefault>({
  //   use_all_available_sort_by: true,
  //   use_config_default_available_sort_by: true,
  //   use_config_filter_price_range: true,
  // });

  const initialValues: FormDataCategory = useMemo(
    () => ({
      filter_price_range: '',
      include_in_menu: true,
      is_active: true,
      name: '',
      description: '',
      image: '',
      parent_id: 1,
      shipping_percat: '',
      image_name: '',
      size: 0,
      url_key: '',
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
      // available_sort_by: [],
      // display_mode: 'PRODUCTS',
      // mp_ln_hide_attribute_ids: [],
      // default_sort_by: 'position',
      // is_anchor: true,
    }),
    [],
  );

  // const validationSchema = useMemo(() => {
  //   let validationSchema = formCategorySchema;

  //   if (!useDefault.use_all_available_sort_by) validationSchema = validationSchema.concat(availableProductListSortBySchema);
  //   if (!useDefault.use_config_filter_price_range) validationSchema = validationSchema.concat(filterPriceRangeSchema);

  //   return validationSchema;
  // }, [useDefault.use_all_available_sort_by, useDefault.use_config_filter_price_range]);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setValues, resetForm } =
    useFormik<FormDataCategory>({
      initialValues,
      validationSchema: formCategorySchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: handleSubmitForm,
    });

  // For edit category
  useEffect(() => {
    if (!formData) return;
    resetForm();

    const { is_active, include_in_menu, name, parent_id } = formData;

    // setUserDefault({
    //   use_all_available_sort_by: available_sort_by ? false : true,
    //   use_config_default_available_sort_by: convertAttribute('default_sort_by', true) ? false : true,
    //   use_config_filter_price_range: convertAttribute('filter_price_range', true) ? false : true,
    // });

    const imagePath: string = convertAttribute('image');
    const imageName = imagePath.slice(0)?.split('/')?.reverse()[0];
    const image: string = imagePath ? `${process.env.REACT_APP_URL || ''}${imagePath}` : '';

    setValues({
      ...initialValues,
      is_active,
      include_in_menu,
      name,
      image,
      parent_id,
      image_name: imageName,
      description: convertAttribute('description'),
      shipping_percat: convertAttribute('shipping_percat'),
      url_key: convertAttribute('url_key'),
      meta_description: convertAttribute('meta_description'),
      meta_keywords: convertAttribute('meta_keywords'),
      meta_title: convertAttribute('meta_title'),
      // available_sort_by: available_sort_by || initialValues.available_sort_by,
      // default_sort_by: convertAttribute('default_sort_by'),
      // display_mode: convertAttribute('display_mode'),
      // is_anchor: !!+convertAttribute('is_anchor'),
      // filter_price_range: convertAttribute('filter_price_range'),
      // mp_ln_hide_attribute_ids: convertAttribute('mp_ln_hide_attribute_ids'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  function convertAttribute(attributeName: string, noReturnInitialValueWhenValueUndefined: boolean = false): any {
    const value = formData?.custom_attributes.find((attribute) => attribute.attribute_code === attributeName)?.value;

    if (noReturnInitialValueWhenValueUndefined) return value;
    return value ?? initialValues[attributeName as keyof FormDataCategory];
  }

  //   For add sub category
  useEffect(() => {
    if (!parentCategory) return;
    resetForm();

    setFieldValue('parent_id', parentCategory.id);
    dispatch(setFormDataCategory(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentCategory]);

  useEffect(() => {
    handleCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  function handleCancel() {
    resetForm();

    parentCategory && dispatch(setParentCategory(null));

    if (currentAction === 'edit') {
      dispatch(setCurrentAction('create'));
      dispatch(setFormDataCategory(null));
    }
  }

  function handleRemoveCategoryImage() {
    setFieldValue('image', '');
  }

  // function handleChangeUserDefault(e: ChangeEvent<HTMLInputElement>) {
  //   const { checked, name } = e.target;

  //   if (name === 'use_all_available_sort_by') {
  //     setFieldTouched('default_sort_by', false);
  //     setFieldTouched('available_sort_by', false);
  //   }

  //   setUserDefault({ ...useDefault, [name]: checked });
  // }

  function handleSubmitForm(valuesForm: FormDataCategory) {
    // Format data
    const values: any = { ...valuesForm, image: convertImageBase64ToPayload(valuesForm.image) };
    delete values.image_name;
    delete values.size;

    // Delete some fields
    // if (useDefault.use_all_available_sort_by) delete values.available_sort_by;
    // if (useDefault.use_config_default_available_sort_by) delete values.default_sort_by;
    // if (useDefault.use_config_filter_price_range) delete values.filter_price_range;

    const keyNotOfCustomAttributes = ['include_in_menu', 'is_active', 'name', 'parent_id', 'available_sort_by'];
    const newValues = Object.keys(values)
      .filter((key) => keyNotOfCustomAttributes.includes(key))
      .reduce((result, key) => ({ ...result, [key]: values[key as keyof typeof values] } || ''), {}) as any;

    const arr_custom_attributes = Object.keys(values).map((key) => {
      let value = values[key as keyof typeof values];

      if (typeof value === 'boolean') {
        value = value ? '1' : '0';
      }

      if (value === '' || value === undefined) {
        value = null;
      }

      return {
        attribute_code: key,
        value: value,
      };
    });

    const custom_attributes = arr_custom_attributes.length ? { custom_attributes: arr_custom_attributes } : {};

    const payload: iCategoryItemPayload = {
      category: {
        ...newValues,
        ...custom_attributes,
      },
    };

    // Close content toggle
    // setShowSetting(false);
    setShowSearchOptimize(false);

    if (currentAction === 'create') {
      return dispatch(
        createCategories({
          currentStore,
          data: payload,
        }),
      );
    }

    return dispatch(
      editCategoryById({
        currentStore,
        id: formData?.id || 0,
        data: payload,
      }),
    );
  }

  function handleUploadCategoryImage(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    async function convertBase64Image() {
      if (file) {
        const typeFileAccept = ['image/png', 'image/jpeg'];
        const { type, name } = file;

        if (!typeFileAccept.includes(type)) {
          swalToast.fire({
            title: "Your photos couldn't be uploaded. Photos should be saved as JPG, PNG, JPEG.",
            icon: 'error',
          });

          // Remove file when have error
          e.target.value = '';
          return;
        }

        try {
          // Change name
          const nameLengthAfterReplace = name.replace(/[&/\\#,+()$~%'":*?<>{}||@!^]/g, '').split('.')[0].length === 0;
          const fileType = name.split('.').reverse()[0];
          const newName = nameLengthAfterReplace ? `${values.name}-image` : name.replace(/[&/\\#,+()$~%'":*?<>{}|@!^]/g, '').split('.')[0];

          const fileCovert = (await convertBase64(file)) as string;
          if (typeof fileCovert !== 'string') return;

          const y = fileCovert.endsWith('==') ? 2 : 1;
          const newSize = convertImageBase64ToPayload(fileCovert).length * 0.75 - y;

          setFieldValue('image', fileCovert);
          setFieldValue('size', Math.round(newSize));
          setFieldValue('image_name', `${slugify(newName)}.${fileType}`);
        } catch (error) {
          swalToast.fire({
            title: defaultMessageError,
            icon: 'error',
          });
        }
      }
    }
    convertBase64Image();
  }

  function summaryImageName(categoryImageName: string) {
    if (typeof categoryImageName !== 'string' || categoryImageName.length < 20) return categoryImageName;

    const type = categoryImageName.split('.').reverse()[0];
    const name = categoryImageName.split('.').reverse().slice(1).reverse().join('');

    const newName = `${name.slice(0, 5)}...${name.slice(-3)}.${type}`;

    return newName;
  }

  function handleChangeCurrentAction() {
    dispatch(setCurrentAction('create'));
    parentCategory && dispatch(setParentCategory(null));
    formData && dispatch(setFormDataCategory(null));
    resetForm();
  }

  function renderContent() {
    if (statusGetCategoryById === 'pending') {
      return (
        <div className="wrapper p-4">
          <Loading />
        </div>
      );
    }

    return (
      <div className="d-flex flex-column gap-4">
        <div className="create-edit-form wrapper p-4 d-flex flex-column gap-4">
          <h3
            className={`fs-5 fw-semibold m-0 d-flex align-items-center gap-3 w-100 ${currentAction === 'edit' ? 'form-heading cursor-pointer' : ''}`}
            onClick={() => currentAction === 'edit' && handleChangeCurrentAction()}
          >
            {currentAction === 'edit' && <FontAwesomeIcon icon={faChevronLeft} />}
            <span className="text-truncate">
              {currentAction === 'create'
                ? `Add ${parentCategory ? `Subcategory For ${parentCategory.name} (ID: ${parentCategory.id})` : 'New Category'}`
                : `Edit Category: ${values.name}`}
            </span>
          </h3>

          <div className="form-body d-flex flex-column gap-3">
            <div className="d-flex align-items-center justify-content-between">
              <label className="fs-16 cursor-pointer" htmlFor="is_active">
                Enable Category
              </label>
              <CheckboxRounded check={values.is_active} onChange={handleChange} name="is_active" id="is_active" />
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <label className="fs-16 cursor-pointer" htmlFor="include_in_menu">
                Include In Menu
              </label>
              <CheckboxRounded check={values.include_in_menu} onChange={handleChange} name="include_in_menu" id="include_in_menu" />
            </div>

            <div className="d-flex flex-column gap-2">
              <label className="fs-16 cursor-pointer" htmlFor="name">
                Category Name <span className="text-danger">*</span>
              </label>
              <Input
                className="fs-14"
                value={values.name}
                id="name"
                name="name"
                type="text"
                onChange={(e) => {
                  errors.name && setFieldTouched('name', false);
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && <span className="text-danger fs-7">{errors.name}</span>}
            </div>

            <div className="d-flex flex-column gap-2">
              <label className="fs-16 cursor-pointer" htmlFor="shipping_percat">
                Shipping Rate
              </label>
              <Input
                className="fs-14"
                value={values.shipping_percat}
                id="shipping_percat"
                name="shipping_percat"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="d-flex flex-column gap-2">
              <label className="fs-16 cursor-pointer" htmlFor="description">
                Description
              </label>
              <TextArea value={values.description} className="h-150px fs-14" id="description" onChange={handleChange} onBlur={handleBlur} />
            </div>
            <div className="d-flex flex-column gap-2">
              <label className="fs-16 cursor-pointer" htmlFor="upload-category">
                Image
              </label>
              {values.image ? (
                <div className="preview-category d-flex align-items-center gap-3 px-3 py-12">
                  <img className="preview-category-image flex-shrink-0" src={values.image} alt="category-preview" />
                  <div className="d-flex flex-column gap-1 flex-grow-1">
                    <h3 className="fs-14 m-0">{summaryImageName(values.image_name)}</h3>
                    <span className="fs-7">{convertSize(values.size || 0)}</span>
                  </div>
                  <FontAwesomeIcon className="close-icon cursor-pointer" icon={faClose} onClick={handleRemoveCategoryImage} />
                </div>
              ) : (
                <label
                  htmlFor="upload-category"
                  className="upload-category d-flex align-items-center justify-content-center gap-2 p-3 cursor-pointer"
                >
                  <input type="file" id="upload-category" accept="image/png, image/gif, image/jpeg" hidden onChange={handleUploadCategoryImage} />
                  <img className="upload-img user-select-none pe-none" src={uploadImg} alt="upload-img" />
                  <span className="upload-placeholder fs-14 text-dark-200">Click here to change</span>
                </label>
              )}
            </div>
          </div>
        </div>

        {/* Display settings */}
        {/* <div className="wrapper d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between gap-4 p-4 cursor-pointer" onClick={() => setShowSetting(!showSetting)}>
            <h3 className="fs-5 fw-semibold m-0">Display Settings</h3>
            <FontAwesomeIcon icon={showSetting ? faChevronUp : faChevronDown} />
          </div>

          {showSetting && (
            <div className="d-flex flex-column gap-3 pb-4 px-4">
              <div className="d-flex align-items-center justify-content-between">
                <label className="fs-16 cursor-pointer" htmlFor="is_anchor">
                  Anchor
                </label>
                <CheckboxRounded check={values.is_anchor} onChange={handleChange} name="is_anchor" id="is_anchor" />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="display_mode">
                  Display Mode
                </label>
                <Select className="w-100" value={values.display_mode} id="display_mode" onChange={handleChange} onBlur={handleBlur}>
                  {CATEGORY_DISPLAY_MODE_OPTION.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer">Available Product Listing Sort By</label>
                <Select
                  disabled={useDefault.use_all_available_sort_by}
                  className={`w-100 150px ${useDefault.use_all_available_sort_by ? 'overflow-hiddle' : 'overflow-auto'}`}
                  value={values.available_sort_by || ''}
                  id="available_sort_by"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    var options = e.target.options;
                    var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    setFieldValue('available_sort_by', value);
                  }}
                  onBlur={handleBlur}
                  multiple
                >
                  {CATEGORY_AVAILABLE_PRODUCT__SHORT_BY.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
                {errors.available_sort_by && touched.available_sort_by && <span className="text-danger fs-7">{errors.available_sort_by}</span>}

                <CustomCheckBox
                  name="use_all_available_sort_by"
                  checkBoxClass="fs-16"
                  className="w-fit-content"
                  checked={useDefault.use_all_available_sort_by}
                  label="Use All"
                  onChange={handleChangeUserDefault}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="use_config_default_available_sort_by">
                  Default Product Listing Sort By
                </label>
                <Select
                  disabled={useDefault.use_config_default_available_sort_by}
                  className="w-100"
                  value={values.default_sort_by}
                  id="default_sort_by"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {CATEGORY_AVAILABLE_PRODUCT__SHORT_BY.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
                {errors.default_sort_by && touched.default_sort_by && <span className="text-danger fs-7">{errors.default_sort_by}</span>}
                <CustomCheckBox
                  name="use_config_default_available_sort_by"
                  checkBoxClass="fs-16"
                  className="w-fit-content"
                  checked={useDefault.use_config_default_available_sort_by}
                  label="Use Config Settings"
                  onChange={handleChangeUserDefault}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="use_config_default_available_sort_by">
                  Layered Navigation Price Step
                </label>

                <div
                  className={`form-input-wrap form-control form-control--custom d-flex align-items-center gap-2 py-0 overflow-hidden ${
                    useDefault.use_config_filter_price_range ? 'disabled' : ''
                  }`}
                >
                  <span className="fs-16 text-dark-400 pe-2">{formatCurrency(currentStore)}</span>
                  <Input
                    disabled={useDefault.use_config_filter_price_range}
                    className="h-100 border-0 flex-grow-1 fs-16 p-0"
                    value={values.filter_price_range}
                    id="filter_price_range"
                    type="number"
                    onChange={(e) => {
                      errors.filter_price_range && setFieldTouched('filter_price_range', false);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.filter_price_range && touched.filter_price_range && <span className="text-danger fs-7">{errors.filter_price_range}</span>}

                <CustomCheckBox
                  name="use_config_filter_price_range"
                  checkBoxClass="fs-16"
                  className="w-fit-content"
                  checked={useDefault.use_config_filter_price_range}
                  label="Use Config Settings"
                  onChange={handleChangeUserDefault}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="mp_ln_hide_attribute_ids">
                  Hide Filter Attributes on Layered Navigation
                </label>
                <Select
                  className="w-100"
                  value={values.mp_ln_hide_attribute_ids || ''}
                  id="mp_ln_hide_attribute_ids"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    var options = e.target.options;
                    var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }
                    setFieldValue('mp_ln_hide_attribute_ids', value);
                  }}
                  onBlur={handleBlur}
                  multiple
                  style={{
                    height: `calc((26px * ${CATEGORY_HIDE_FILTER_ATTRIBUTE.length}) + (6px * 2))`,
                  }}
                >
                  {CATEGORY_HIDE_FILTER_ATTRIBUTE.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div> */}

        {/* Search Optimize */}
        <div className="wrapper d-flex flex-column">
          <div
            className="d-flex align-items-center justify-content-between gap-4 p-4 cursor-pointer"
            onClick={() => setShowSearchOptimize(!showSearchOptimize)}
          >
            <h3 className="fs-5 fw-semibold m-0">Search Engine Optimization</h3>
            <FontAwesomeIcon icon={showSearchOptimize ? faChevronUp : faChevronDown} />
          </div>

          {showSearchOptimize && (
            <div className="d-flex flex-column gap-3 pb-4 px-4">
              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="url_key">
                  URL Key
                </label>
                <Input className="fs-14" value={values.url_key} id="url_key" name="url_key" type="text" onChange={handleChange} onBlur={handleBlur} />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="meta_title">
                  Meta Title
                </label>
                <Input
                  className="fs-14"
                  value={values.meta_title}
                  id="meta_title"
                  name="meta_title"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="meta_keywords">
                  Meta Keywords
                </label>
                <Input
                  className="fs-14"
                  value={values.meta_keywords}
                  id="meta_keywords"
                  name="meta_keywords"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <label className="fs-16 cursor-pointer" htmlFor="meta_description">
                  Meta Description
                </label>
                <Input
                  className="fs-14"
                  value={values.meta_description}
                  id="meta_description"
                  name="meta_description"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        <div className="d-flex align-items-center gap-3">
          <Button
            disabled={statusCreateCategory === 'pending' || statusEditCategoryById === 'pending'}
            className="w-100"
            outline
            onClick={handleCancel}
          >
            CANCEL
          </Button>

          <Button
            disabled={statusCreateCategory === 'pending' || statusEditCategoryById === 'pending'}
            className="w-100"
            onClick={handleSubmit}
            showLoadingRight={statusCreateCategory === 'pending' || statusEditCategoryById === 'pending'}
          >
            SAVE
          </Button>
        </div>
      </div>
    );
  }

  return renderContent();
};

export default CreateEditCategory;
