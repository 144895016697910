import { FC, Fragment } from 'react';
import './style.scss';

export interface MenuItem {
  menuId: number;
  icon?: React.FunctionComponent;
  title: string;
}

interface Props {
  menu: MenuItem[];
  activeMenuId: number;
  onChange: (menuId: number) => void;
  className?: string;
}

const MenuHorizontal: FC<Props> = ({ menu, activeMenuId, onChange, className = '' }) => {
  return (
    <div className={`menu-horizontal d-flex align-items-center mb-4 ${className}`}>
      {menu.map((item, i) => {
        const { menuId, title, icon } = item;
        const isActive = activeMenuId === menuId ? 'active' : '';
        const Icon = icon ? icon : Fragment;

        return (
          <li className={`menu-item fs-6 fw-medium pb-4 text-nowrap ${isActive}`} key={i} onClick={() => onChange(menuId)}>
            {!!icon && <span className="menu-icon">{<Icon />}</span>}
            <span> {title}</span>
          </li>
        );
      })}
    </div>
  );
};

export default MenuHorizontal;
