import { AxiosResponse } from 'axios';
import { iCategoryItem, iCategoryTreeItem, iGetCategories, iResponseListing } from '../models';
import { iGetAllPromotion } from '../models/promotion';
import axiosConfig from './axiosConfig';
import { config } from './axiosConfig';

const promotionApi = {
  //get website ids
  getWebsiteId: (accessToken: string, store: string = 'all') => {
    const url = `${store}/store/websites`;
    return axiosConfig.get(url);
  },
  getCategories: ({ currentStore, searchURL }: iGetCategories): Promise<AxiosResponse<iResponseListing<iCategoryItem[]>>> => {
    const url = `${currentStore}/V1/categories/list?${searchURL}`;
    return axiosConfig.get(url);
  },
  getCategoriesTree: (currentStore: string): Promise<AxiosResponse<iCategoryTreeItem>> => {
    const url = `${currentStore}/V1/categories`;
    return axiosConfig.get(url);
  },
  getAll: (payload: iGetAllPromotion, controller: AbortController, store: string = 'all') => {
    const { searchUrl } = payload;
    const url = `${store}/V1/customRules/getSearchList?${searchUrl}`;
    return axiosConfig.get(url, {
      signal: controller.signal,
    });
  },
  getPromotionId: (rule_id: number, store: string = 'all') => {
    const url = `${store}/V1/customRules/${rule_id}`;
    return axiosConfig.get(url);
  },
  getPromotionListing: (searchURL: string, accessToken: string, store: string = 'all', controller: AbortController) => {
    const url = `${store}/V1/customRules/getSearchList?${searchURL}`;
    return axiosConfig.get(url, {
      ...config(accessToken),
      signal: controller.signal,
    });
  },
  getCouponCode: (payload: iGetAllPromotion) => {
    const { currentStore = 'all', searchUrl } = payload;
    const url = `${currentStore}/V1/coupons/search?${searchUrl}`;
    return axiosConfig.get(url);
  },
  deletePromotion: (ruleId: number, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/customRules/delete/${ruleId}`;
    return axiosConfig.delete(url, config(accessToken));
  },
  editPromotion: (ruleId: number, payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/customRules/edit/${ruleId}`;
    return axiosConfig.put(url, payload, config(accessToken));
  },
  createPromotion: (payload: any, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/customRules`;
    return axiosConfig.post(url, payload, config(accessToken));
  },

  //get categories in create promotion
  getCateList: (searchURL: string, accessToken: string, store: string = 'all') => {
    const url = `${store}/V1/categories/list?${searchURL}`;
    return axiosConfig.get(url);
  },
  getCateTree: (store: string = 'all') => {
    const url = `${store}/V1/categories`;
    return axiosConfig.get(url);
  },
};

export default promotionApi;
