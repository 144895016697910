import productAttributesApi from '~/app/apis/product-attributes.api';
import * as types from './index';
import { AxiosResponse } from 'axios';
import { iPayloadCreateAttribute, iPayloadEditAttribute, iPayloadGetProductAttributes, iProductAttributeItem, iResponseListing } from '~/app/models';
import { convertMessageError, swalToast } from '~/app/utils';

const { rest } = types;

export const getProductAttributes = (payload: iPayloadGetProductAttributes) => async (dispatch: any) => {
  dispatch(rest.getDataPending());
  try {
    const { data }: AxiosResponse<iResponseListing<iProductAttributeItem[]>> = await productAttributesApi.getProductAttributeListing(payload);
    dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;
    dispatch(rest.getDataRejected());
  }
};

export const createAttribute = (payload: iPayloadCreateAttribute) => async (dispatch: any) => {
  dispatch(rest.createAttributePending());

  try {
    const { data }: AxiosResponse<iProductAttributeItem> = await productAttributesApi.createAttribute(payload);
    dispatch(rest.createAttributeFulfilled(data));

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Show notification
    swalToast.fire({
      title: 'Save successfully',
      icon: 'success',
    });
  } catch (error) {
    dispatch(rest.createAttributeRejected());
    const message = convertMessageError(error);

    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
  }
};

export const editAttribute = (payload: iPayloadEditAttribute) => async (dispatch: any) => {
  dispatch(rest.editAttributePending());

  try {
    const { data }: AxiosResponse<iProductAttributeItem> = await productAttributesApi.editAttribute(payload);
    dispatch(rest.editAttributeFulfilled(data));

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Show notification
    swalToast.fire({
      title: 'Edit successfully',
      icon: 'success',
    });
  } catch (error) {
    dispatch(rest.editAttributeRejected());
    const message = convertMessageError(error);

    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};
export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(q));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};

export const changeDataEdit = (dataEdit: iProductAttributeItem | null) => (dispatch: any) => {
  dispatch(rest.changeDataEdit(dataEdit));
};
