import { createSlice } from '@reduxjs/toolkit';
import { iInitState } from '~/app/models';
interface iState extends iInitState {
  message: string;
  isDisplayNav: boolean;
  status: '' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState: iState = {
  status: '',
  message: '',
  isDisplayNav: true,
};

const pageSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resizeWindow: (state, action) => ({ ...state, isDisplayNav: action.payload }),
  },
});

export const { ...rest } = pageSlice.actions;

const pageReducer = pageSlice.reducer;

export default pageReducer;
