import React from 'react';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logOutAction, resetPathname } from '../FullWidthLayout/redux/actions';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';

interface Props {
  classNa?: string | null;
}

const Logout = (props: Props) => {
  const { classNa } = props;
  const { isDisplayNav } = useAppSelector((state) => state.pageReducer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logOutAction(navigate));
    dispatch(resetPathname());
  };

  return (
    <Link className={`text-center d-flex justify-content-center align-items-center light ${classNa ? classNa : ''}`} to="#" onClick={logout}>
      <FontAwesomeIcon icon={faRightFromBracket} className={`${isDisplayNav ? 'me-3' : 'fs-4'}`} />
      {isDisplayNav && <span className="fw-medium">Log Out</span>}
    </Link>
  );
};

export default Logout;
