import { iPayloadCreateAttribute, iPayloadEditAttribute, iPayloadGetOptionByAttributeCode, iPayloadGetProductAttributes } from '../models';
import axiosConfig from './axiosConfig';

const productAttributesApi = {
  getProductAttributeListing: (payload: iPayloadGetProductAttributes) => {
    const { controller, currentStore, search } = payload;
    const url = `${currentStore}/V1/products/attributes?${search}`;
    return axiosConfig.get(url, {
      signal: controller?.signal,
    });
  },
  createAttribute: (payload: iPayloadCreateAttribute) => {
    const { data, currentStore } = payload;
    const url = `${currentStore}/V1/products/attributes`;
    return axiosConfig.post(url, data);
  },
  editAttribute: (payload: iPayloadEditAttribute) => {
    const { data, currentStore, attribute_code } = payload;
    const url = `${currentStore}/V1/products/attributes/${attribute_code}`;
    return axiosConfig.put(url, data);
  },
  getOptionsByAttrCode: (payload: iPayloadGetOptionByAttributeCode) => {
    const { attribute_code, currentStore } = payload;
    const url = `${currentStore}/V1/products/attributes/${attribute_code}/options`;
    return axiosConfig.get(url);
  },
};

export default productAttributesApi;
