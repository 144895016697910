import { ReactNode, useEffect } from 'react';
import './style.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const PopupWrap = ({ children, className = '' }: Props) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.overflow = 'overlay';
    };
  }, []);
  return (
    <div className="overlay d-flex align-items-center justify-content-center">
      <div className={`content-wrap d-flex flex-column ${className}`}>{children}</div>
    </div>
  );
};

export default PopupWrap;
