import React from 'react';
import { CustomerReport, InvoicedReport, OrderReport, ProductReport, RefundedReport } from './Tables';

interface Props {
  type: 'customer' | 'order' | 'refund' | 'product' | 'invoice';
}

const GeneralReport: React.FC<Props> = (props: Props) => {
  const { type } = props;

  return (
    <>
      {type === 'order' && <OrderReport />}
      {type === 'customer' && <CustomerReport />}
      {type === 'invoice' && <InvoicedReport />}
      {type === 'product' && <ProductReport />}
      {type === 'refund' && <RefundedReport />}
    </>
  );
};

export default GeneralReport;
