import { iPayloadCreateBrand, iPayloadDeleteBrandById, iPayloadEditBrandById, iPayloadGetBrand } from '../models';
import axiosConfig from './axiosConfig';

const brandApi = {
  getBrand: (payload: iPayloadGetBrand) => {
    const { currentStore, controller, search } = payload;
    const url = `${currentStore}/V1/ShopBrand/getSearch?${search}`;
    return axiosConfig.get(url, {
      signal: controller?.signal,
    });
  },
  deleteBrandById: (payload: iPayloadDeleteBrandById) => {
    const { currentStore, id } = payload;
    const url = `${currentStore}/V1/ShopBrand/delete/${id}`;
    return axiosConfig.delete(url);
  },
  createBrand: (payload: iPayloadCreateBrand) => {
    const { currentStore, data } = payload;
    const url = `${currentStore}/V1/ShopBrand`;
    return axiosConfig.post(url, data);
  },
  editBrandById: (payload: iPayloadEditBrandById) => {
    const { currentStore, id, data } = payload;
    const url = `${currentStore}/V1/ShopBrand/edit/${id}`;
    return axiosConfig.put(url, data);
  },
};

export default brandApi;
