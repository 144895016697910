import { store } from '~/store';
import { addFilterItem, addFilterItemPromotion, addFilters, addFiltersPromotion } from './search';
import moment from 'moment';
import { FilterProductsInCategory } from '~/components/pages/CategoryListing/ProductInCategory';
import { FormFilterValuesBrand } from '~/components/pages/BrandListing';
import { FormFilterValuesProductAttribute } from '~/components/pages/ProductAttributes/ProductAttributeListing';

//Product Listing Filter
export const builderCriteriaProduct = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(addFilters(addFilterItem('name', `%${data.searchTerm}%`, 'like'), addFilterItem('sku', `%${data.searchTerm}%`, 'like')));

  //Filter Section
  if (data.name) filter.push(addFilters(addFilterItem('name', `%${data.name}%`, 'like')));
  if (data.sku) filter.push(addFilters(addFilterItem('sku', `%${data.sku}%`, 'like')));

  //ID
  if (data.idFrom) {
    filter.push(addFilters(addFilterItem('entity_id', `${data.idFrom}`, 'from')));
  } else if (!data.idFrom && data.idTo) {
    filter.push(addFilters(addFilterItem('entity_id', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.idTo) filter.push(addFilters(addFilterItem('entity_id', `${data.idTo}`, 'to')));

  //PRICE
  if (data.priceFrom) {
    filter.push(addFilters(addFilterItem('price', `${data.priceFrom}`, 'from')));
  } else if (!data.priceFrom && data.priceTo) {
    filter.push(addFilters(addFilterItem('price', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.priceTo) filter.push(addFilters(addFilterItem('price', `${data.priceTo}`, 'to')));

  //QUANTITY
  if (data.qtyFrom) {
    filter.push(addFilters(addFilterItem('qty', `${data.qtyFrom}`, 'from')));
  } else if (!data.qtyFrom && data.qtyTo) {
    filter.push(addFilters(addFilterItem('qty', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.qtyTo) filter.push(addFilters(addFilterItem('qty', `${data.qtyTo}`, 'to')));

  //VISIBILITY
  if (data.visibility) filter.push(addFilters(addFilterItem('visibility', `%${data.visibility}%`, 'like')));

  //PRODUCT TYPE
  if (data.type) filter.push(addFilters(addFilterItem('type_id', `%${data.type}%`, 'like')));

  //PRODUCT STATUS
  if (data.status && parseInt(data.status) === 1) {
    filter.push(addFilters(addFilterItem('status', `%${data.status}%`, 'like')));
  } else if (data.status && parseInt(data.status) !== 1) {
    filter.push(addFilters(addFilterItem('status', '1', 'neq')));
  }
  return filter;
};

//Order Listing Filter
export const builderCriteriaOrder = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(
      addFilters(addFilterItem('increment_id', `%${data.searchTerm}%`, 'like'), addFilterItem('customer_email', `%${data.searchTerm}%`, 'like')),
    );

  //Filter Section
  if (data.id) filter.push(addFilters(addFilterItem('increment_id', `%${data.id}%`, 'like')));
  if (data.filterStatus) filter.push(addFilters(addFilterItem('status', `${data.filterStatus}`, 'eq')));
  if (data.customerEmail) filter.push(addFilters(addFilterItem('customer_email', `%${data.customerEmail}%`, 'like')));

  //Total Refunded
  if (data.totalRefundedFrom) {
    filter.push(addFilters(addFilterItem('total_refunded', `${data.totalRefundedFrom}`, 'from')));
  } else if (!data.totalRefundedFrom && data.totalRefundedTo) {
    filter.push(addFilters(addFilterItem('total_refunded', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.totalRefundedTo) filter.push(addFilters(addFilterItem('total_refunded', `${data.totalRefundedTo}`, 'to')));

  //Grand Total
  if (data.baseGrandTotalFrom) {
    filter.push(addFilters(addFilterItem('base_grand_total', `${data.baseGrandTotalFrom}`, 'from')));
  } else if (!data.baseGrandTotalFrom && data.baseGrandTotalTo) {
    filter.push(addFilters(addFilterItem('base_grand_total', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.baseGrandTotalTo) filter.push(addFilters(addFilterItem('base_grand_total', `${data.baseGrandTotalTo}`, 'to')));

  //Purchase Date
  if (data.purchaseDateFrom) {
    filter.push(addFilters(addFilterItem('created_at', `${data.purchaseDateFrom}`, 'from')));
  } else if (!data.purchaseDateFrom && data.purchaseDateTo) {
    filter.push(addFilters(addFilterItem('created_at', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.purchaseDateTo)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.purchaseDateTo).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));
  return filter;
};

//Seller Listing Filter
export const builderCriteriaSeller = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(addFilters(addFilterItem('vendor_id', `%${data.searchTerm}%`, 'like'), addFilterItem('email', `%${data.searchTerm}%`, 'like')));

  //Filter Section
  if (data.sellerIdFrom) filter.push(addFilters(addFilterItem('vendor_id', `${data.sellerIdFrom}`, 'from')));
  if (data.sellerIdTo) filter.push(addFilters(addFilterItem('vendor_id', `${data.sellerIdTo}`, 'to')));
  if (data.statusSeller) filter.push(addFilters(addFilterItem('is_active', `%${data.statusSeller}%`, 'like')));
  if (data.sellerEmail) filter.push(addFilters(addFilterItem('email', `%${data.sellerEmail}%`, 'like')));
  if (data.sellerFirstName) filter.push(addFilters(addFilterItem('firstname', `%${data.sellerFirstName}%`, 'like')));
  if (data.sellerLastName) filter.push(addFilters(addFilterItem('lastname', `%${data.sellerLastName}%`, 'like')));

  //Seller Created
  if (data.sellerCreatedFrom) {
    filter.push(addFilters(addFilterItem('created_at', `${data.sellerCreatedFrom}`, 'from')));
  } else if (!data.sellerCreatedFrom && data.sellerCreatedTo) {
    filter.push(addFilters(addFilterItem('created_at', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.sellerCreatedTo)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.sellerCreatedTo).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));
  if (data.statusSeller) filter.push(addFilters(addFilterItem('is_active', `%${data.statusSeller}%`, 'like')));
  return filter;
};

//Customer Listing Filter
export const builderCriteriaCustomer = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(
      addFilters(
        addFilterItem('entity_id', `%${data.searchTerm}%`, 'like'),
        addFilterItem('billing_telephone', `%${data.searchTerm}%`, 'like'),
        addFilterItem('email', `%${data.searchTerm}%`, 'like'),
        addFilterItem('name', `%${data.searchTerm}%`, 'like'),
      ),
    );

  //Filter Section
  if (data.customersEmail) filter.push(addFilters(addFilterItem('email', `%${data.customersEmail}%`, 'like')));
  if (data.customerIdFrom) filter.push(addFilters(addFilterItem('entity_id', `${data.customerIdFrom}`, 'from')));
  if (data.customerIdTo) filter.push(addFilters(addFilterItem('entity_id', `${data.customerIdTo}`, 'to')));

  //Customer Created
  if (data.customerSinceFrom) {
    filter.push(addFilters(addFilterItem('created_at', `${data.customerSinceFrom}`, 'from')));
  } else if (!data.customerSinceFrom && data.customerSinceTo) {
    filter.push(addFilters(addFilterItem('created_at', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.customerSinceTo)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.customerSinceTo).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));

  if (data.customerPhone) filter.push(addFilters(addFilterItem('billing_telephone', `%${data.customerPhone}%`, 'like')));
  if (data.customerProvince) filter.push(addFilters(addFilterItem('billing_region', `%${data.customerProvince}%`, 'like')));
  if (data.customerCountry) filter.push(addFilters(addFilterItem('billing_country_id', `%${data.customerCountry}%`, 'like')));
  if (data.customerWebsite) filter.push(addFilters(addFilterItem('website_id', `%${data.customerWebsite}%`, 'like')));
  if (data.customerName) {
    filter.push(addFilters(addFilterItem('name', `%${data.customerName}%`, 'like')));
  }

  //Customer Order Listing Filter

  //Customer Created
  if (data.customerPurchaseOrderFrom) {
    filter.push(addFilters(addFilterItem('created_at', `${data.customerPurchaseOrderFrom}`, 'from')));
  } else if (!data.customerPurchaseOrderFrom && data.customerPurchaseOrderTo) {
    filter.push(addFilters(addFilterItem('created_at', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.customerPurchaseOrderTo)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.customerPurchaseOrderTo).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));

  //Grand Total
  if (data.customerOrderTotalFrom) {
    filter.push(addFilters(addFilterItem('base_grand_total', `${data.customerOrderTotalFrom}`, 'from')));
  } else if (!data.customerOrderTotalFrom && data.customerOrderTotalTo) {
    filter.push(addFilters(addFilterItem('base_grand_total', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.customerOrderTotalTo) filter.push(addFilters(addFilterItem('base_grand_total', `${data.customerOrderTotalTo}`, 'to')));

  if (data.customerOrderId) filter.push(addFilters(addFilterItem('increment_id', `%${data.customerOrderId}%`, 'like')));
  if (data.customerShipToName) {
    filter.push(addFilters(addFilterItem('shipping_name', `%${data.customerShipToName}%`, 'like')));
  }
  if (data.customerBillToName) {
    filter.push(addFilters(addFilterItem('billing_name', `%${data.customerBillToName}%`, 'like')));
  }
  return filter;
};

export const builderCriteriaTicket = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm) filter.push(addFilters(addFilterItem('customer_name', `%${data.searchTerm}%`, 'like')));

  //Filter Section
  if (data.customer_name) filter.push(addFilters(addFilterItem('customer_name', `%${data.customer_name}%`, 'like')));
  if (data.priority_id) filter.push(addFilters(addFilterItem('priority_id', `${data.priority_id}`, 'eq')));
  if (data.created_at_from) filter.push(addFilters(addFilterItem('created_at', `${data.created_at_from}`, 'from')));
  if (data.created_at_to)
    filter.push(addFilters(addFilterItem('created_at', `${moment(data.created_at_to).add(1, 'days').format('YYYY-MM-DD')}`, 'to')));

  if (data.status_id < 0) {
    filter.push(addFilters(addFilterItem('status_id', data.status_id, 'gt')));
  } else {
    filter.push(addFilters(addFilterItem('status_id', data.status_id, 'eq')));
  }

  return filter;
};

//Promotion Listing Filter
export const builderCriteriaPromotion = (data: any) => {
  const filter: any = [];

  //Search Bar
  if (data.searchTerm)
    filter.push(
      addFiltersPromotion(
        addFilterItemPromotion('name', `%${data.searchTerm}%`, 'like'),
        addFilterItemPromotion('code', `%${data.searchTerm}%`, 'like'),
        addFilterItemPromotion('rule_id', `${data.searchTerm}`, 'like'),
      ),
    );

  //Filter Section
  if (data.name) filter.push(addFiltersPromotion(addFilterItemPromotion('name', `%${data.name}%`, 'like')));
  if (data.id) filter.push(addFiltersPromotion(addFilterItemPromotion('rule_id', `%${data.id}%`, 'like')));

  // COUPON CODE
  if (data.coupon_code) filter.push(addFiltersPromotion(addFilterItemPromotion('code', `%${data.coupon_code}%`, 'like')));

  //PRIORITY
  if (data.priority) filter.push(addFiltersPromotion(addFilterItemPromotion('sort_order', `%${data.priority}%`, 'like')));

  //PROMOITON STATUS
  if (data.status) filter.push(addFiltersPromotion(addFilterItemPromotion('is_active', `${data.status}`, 'eq')));

  //Date From
  if (data.from_dateFrom) {
    filter.push(addFiltersPromotion(addFilterItemPromotion('from_date', `${data.from_dateFrom}`, 'from')));
  } else if (!data.from_dateFrom && data.from_dateTo) {
    filter.push(addFiltersPromotion(addFilterItemPromotion('from_date', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.from_dateTo) filter.push(addFiltersPromotion(addFilterItemPromotion('from_date', `${data.from_dateTo}`, 'to')));

  //Date To
  if (data.to_dateFrom) {
    filter.push(addFiltersPromotion(addFilterItemPromotion('to_date', `${data.to_dateFrom}`, 'from')));
  } else if (!data.to_dateFrom && data.to_dateTo) {
    filter.push(addFiltersPromotion(addFilterItemPromotion('to_date', '0000-00-00', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.to_dateTo) filter.push(addFiltersPromotion(addFilterItemPromotion('to_date', `${data.to_dateTo}`, 'to')));
  return filter;
};

//filter for SKU table

export const builderCriteriaSkuTable = (data: any) => {
  const filter: any = [];

  //Filter Section
  if (data.sku_in) {
    const sku = store.getState().createEditPromotionReducer.dataHeader.value_sku || '';

    const arrSku = sku.split(', ');
    const newSku = arrSku.join(',');
    filter.push(addFilters(addFilterItem('sku', `${newSku}`, data.sku_in === 'Yes' ? 'in' : 'nin')));
  }
  if (data.name) filter.push(addFilters(addFilterItem('name', `%${data.name}%`, 'like')));
  if (data.sku) filter.push(addFilters(addFilterItem('sku', `%${data.sku}%`, 'like')));
  if (data.type_id) filter.push(addFilters(addFilterItem('type_id', `%${data.type_id}%`, 'like')));
  if (data.attribute_set_id) filter.push(addFilters(addFilterItem('attribute_set_id', `%${data.attribute_set_id}%`, 'like')));
  return filter;
};

export const builderCriteriaProductsInCategories = (data: FilterProductsInCategory) => {
  const filter: any[] = [];

  if (data.isSelect) {
    const arr_sku = store.getState().categoryReducer.currentListSkuOfCategory;

    const sku = Array.isArray(arr_sku) ? arr_sku.join(',') : '';
    filter.push(addFilters(addFilterItem('sku', sku, data.isSelect === '1' ? 'in' : 'nin')));
  }

  if (data.id) filter.push(addFilters(addFilterItem('entity_id', `${data.id}`, 'eq')));

  if (data.name) filter.push(addFilters(addFilterItem('name', `%${data.name}%`, 'like')));
  if (data.sku) filter.push(addFilters(addFilterItem('sku', `%${data.sku}%`, 'like')));
  if (data.visibility) filter.push(addFilters(addFilterItem('visibility', `${data.visibility}`, 'eq')));

  //PRICE
  if (data.priceFrom) {
    filter.push(addFilters(addFilterItem('price', `${data.priceFrom}`, 'from')));
  } else if (!data.priceFrom && data.priceTo) {
    filter.push(addFilters(addFilterItem('price', '0', 'from'))); //Prevent search condition type "to" is null
  }
  if (data.priceTo) filter.push(addFilters(addFilterItem('price', `${data.priceTo}`, 'to')));

  return filter;
};

export function builderCriteriaBrand(data: FormFilterValuesBrand) {
  const filter: any[] = [];

  if (data.searchTerm)
    filter.push(addFilters(addFilterItem('title', `%${data.searchTerm}%`, 'like'), addFilterItem('urlkey', `%${data.searchTerm}%`, 'like')));
  if (data.status) filter.push(addFilters(addFilterItem('status', `${data.status}`, 'eq')));
  if (data.title) filter.push(addFilters(addFilterItem('title', `%${data.title}%`, 'like')));
  if (data.urlkey) filter.push(addFilters(addFilterItem('urlkey', `%${data.urlkey}%`, 'like')));

  return filter;
}

export function builderCriteriaProductAttribute(data: FormFilterValuesProductAttribute) {
  const filter: any[] = [];

  if (data.attribute_code) filter.push(addFilters(addFilterItem('attribute_code', `%${data.attribute_code}%`, 'like')));
  if (data.frontend_label) filter.push(addFilters(addFilterItem('frontend_label', `%${data.frontend_label}%`, 'like')));
  if (data.is_comparable) filter.push(addFilters(addFilterItem('is_comparable', data.is_comparable, 'eq')));
  if (data.is_required) filter.push(addFilters(addFilterItem('is_required', data.is_required, 'eq')));
  if (data.is_searchable) filter.push(addFilters(addFilterItem('is_searchable', data.is_searchable, 'eq')));
  if (data.is_user_defined) filter.push(addFilters(addFilterItem('is_user_defined', data.is_user_defined, 'eq')));
  if (data.is_visible_on_front) filter.push(addFilters(addFilterItem('is_visible_on_front', data.is_visible_on_front, 'eq')));
  if (data.scope) filter.push(addFilters(addFilterItem('is_global', data.scope === 'global' ? '1' : '0', 'eq')));

  return filter;
}
