import { formatEnableStatus } from '~/components/common/Filter/formatFilter';
import { ReactComponent as IconEdit } from '~/app/images/edit.svg';
import { ReactComponent as IconRemove } from '~/app/images/trash.svg';
import { NoRecord } from '../Err';
import { deleteBrandById, setCurrentAction, setFormDataBrand } from './redux/action';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import numeral from 'numeral';
import { swalConfirmDelete } from '~/app/utils';
import Loading from '~/components/common/Loading';
import { iBrandItem } from '~/app/models';

const TableBrand = () => {
  const dispatch = useAppDispatch();
  const { data, status: getBrandStatus } = useAppSelector((s) => s.brandReducer);
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { items } = data || {};

  function handleEditBrand(brandItem: iBrandItem) {
    dispatch(setCurrentAction('edit'));
    dispatch(setFormDataBrand(brandItem));
  }

  function handleShowPopupDelete(brandId: number) {
    swalConfirmDelete
      .fire({
        title: 'Delete Brand',
        html: 'Are you sure?<br/>This brand will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteBrandById({
              currentStore,
              id: brandId,
            }),
          );
        }
      });
  }

  function renderBrandListing() {
    if (!getBrandStatus || getBrandStatus === 'pending') {
      return <Loading />;
    }

    return (
      <>
        {items.length && getBrandStatus === 'fulfilled' ? (
          items.map((item) => {
            const { shopbrand_id, status, image, order, title, urlkey } = item;
            const logo = image ? `${process.env.REACT_APP_IMAGE_URL || ''}/${image}` : null;
            const brand_link = `${process.env.REACT_APP_URL || ''}brand/${urlkey}.html`;
            return (
              <div className="col-12 col-lg-6 col-xxl-4" key={shopbrand_id}>
                <div className="wrapper p-4 d-flex flex-column gap-3 h-100">
                  <div className="d-flex flex-column gap-3 flex-grow-1">
                    <div className="brand-item-heading d-flex align-items-center justify-content-between">
                      {logo && (
                        <div className="w-100">
                          <img className="brand-image" src={logo} alt="logo" />
                        </div>
                      )}

                      <div className="ms-auto">{formatEnableStatus(+status)}</div>
                    </div>

                    <div className="brand-item-body d-flex flex-column gap-2 mt-auto">
                      <h3 className="brand-name m-0 fs-16 fw-medium text-uppercase text-truncate">{title}</h3>
                      <span className="brand-order-count text-truncate fs-14">
                        Order:
                        <span className="ms-2">
                          {numeral(order || 0)
                            .format('0,0')
                            .replaceAll(',', '.')}
                        </span>
                      </span>
                      <a className="link fs-14" href={brand_link} target="_blank" rel="noopener noreferrer">
                        Visit Page
                      </a>
                    </div>
                  </div>

                  <div className="brand-item-action d-flex align-items-center pt-3 mt-auto">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center delete-action-wrap">
                      <div
                        className="delete-action d-flex align-items-center gap-3 fs-14 w-fit-content"
                        onClick={() => {
                          handleShowPopupDelete(shopbrand_id);
                        }}
                      >
                        <IconRemove />
                        <span>Remove</span>
                      </div>
                    </div>

                    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                      <div className="edit-action d-flex align-items-center gap-3 fs-14 w-fit-content" onClick={() => handleEditBrand(item)}>
                        <IconEdit />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <table className="wrapper p-4">
            <tbody>
              <NoRecord tableHeaders={[]} />
            </tbody>
          </table>
        )}
      </>
    );
  }

  return <div className="row g-3 g-xxl-4">{renderBrandListing()}</div>;
};

export default TableBrand;
