import axios from 'axios';

const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export async function getInfoVideoVimeo(urlVideo: string) {
  let thumb;
  let infoVideo;
  try {
    const res = await axios.get(`https://vimeo.com/api/oembed.json?url=${urlVideo}`);
    thumb = res.data.thumbnail_url;
    infoVideo = res.data;
  } catch (error) {}
  const thumbBase64 = await getBase64FromUrl(thumb as string);

  return {
    ...infoVideo,
    thumbBase64,
  };
}
