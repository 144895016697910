import { faFilter, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import successImg from '~/app/images/success.png';

import { dataProductHead, dataPromotionHead } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { addFilterGroup, addFilterItem, addFilters, addSortOrder, filterEmptyProperties, getWebsiteId, search_criteriaBuilder } from '~/app/utils';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { NoRecord } from '../Err';
import { getData, setIsDESC, setKeySearch, setQueryString } from './redux/action';
import './style.scss';
import Swal from 'sweetalert2';
import Separator from '~/components/common/Separator';
import { iGetAllPromotion } from './../../../app/models/promotion';
import SearchBar from '~/components/common/Filter/searchBar';
import { formatFilterEnableStatusPromotion, formatIsActive } from '~/components/common/Filter/formatFilter';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { deletePromotion } from '../CreateEditPromotion/redux/actions';
import { TextFilter, SelectFilter, FilterButtons, ResetFilterButton, DateRangeFilter, NumberFilter } from '~/components/common/Filter/filterInput';
import { formatFilterActive } from '~/components/common/Filter/formatFilter';
import { builderCriteriaPromotion } from '~/app/utils/builderCriteria';
import { promotionStatusFilter } from '~/app/constants/filter';

export interface iApiStatus {
  name: string;
  btnStatus: string;
}

type FormFilterValues = {
  name: string;
  id: string;
  priority: string;
  from_dateFrom: string;
  from_dateTo: string;
  to_dateFrom: string;
  to_dateTo: string;
  coupon_code: string;
  status: string;
  searchTerm: string;
};

const PromotionListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Reducers
  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);
  //eslint-disable-next-line
  const { data: dataUser, storeData } = useAppSelector((state) => state.userReducer);
  const { data, status: promotionStatus, message, isDESC, keySearch, controller } = useAppSelector((state) => state.promotionReducer);
  const { items: promotionList = [] } = data || {};
  const { deletePromotionStatus } = useAppSelector((state) => state.createEditPromotionReducer);
  const websiteList = JSON.parse(dataUser.website_ids || '{}');

  const { total_count } = data;
  const sort = sorterCriteria(keySearch, isDESC);

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));

  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const name = searchParams.get('name') || '';
  const coupon_code = searchParams.get('coupon_code') || '';
  const priority = searchParams.get('priority') || '';
  const from_dateFrom = searchParams.get('from_dateFrom') || '';
  const from_dateTo = searchParams.get('from_dateTo') || '';
  const to_dateFrom = searchParams.get('to_dateFrom') || '';
  const to_dateTo = searchParams.get('to_dateTo') || '';
  const searchTerm = searchParams.get('searchTerm') || '';
  const status = searchParams.get('status') || '';
  const id = searchParams.get('id') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    id,
    name,
    coupon_code,
    priority,
    status,
    from_dateFrom,
    from_dateTo,
    to_dateFrom,
    to_dateTo,
    searchTerm,
  } as FormFilterValues;

  // Declare useState
  const [query, setQuery] = useState<string>(window.location.search);
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [websiteId, setWebsiteId] = useState<number>();

  const swalConfirmDelete = Swal.mixin({
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: 'ok',
    cancelButtonText: 'cancel',
    customClass: {
      popup: 'p-32px border-rd-25',
      closeButton: 'mb-n4 text-dark btn-not-hover',
      title: 'h3 text-dark text-center m-0 fw-semibold p-0',
      htmlContainer: 'text-dark text-center mt-3 mb-0 mx-0 fw-normal',
      actions: 'mt-3 w-100',
      confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-40',
      cancelButton: 'btn btn-primary btn-cancel fs-14 fw-medium btn-border-radius border-rd-25 order-0 w-40',
    },
  });

  const filters = builderCriteriaPromotion(submitFilterPayload);

  const payload: iGetAllPromotion = {
    searchUrl: search_criteriaBuilder(
      convertStringToNumber(pageSize.toString(), 10),
      convertStringToNumber(currentPage.toString(), 1),
      // addFilterGroup(...filter),
      addFilterGroup(...filters, addFilters(addFilterItem('website_id', `${websiteId}`, 'eq'))),
      addSortOrder(...sort),
    ),
    currentStore,
  };

  useEffect(() => {
    setWebsiteId(getWebsiteId(storeData, currentStore));
  }, [storeData, currentStore]);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
    };
  }, [setFirstMounted, controller]);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      id: '',
      name: '',
      from_dateFrom: '',
      from_dateTo: '',
      to_dateFrom: '',
      to_dateTo: '',
      priority: '',
      status: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      id: '',
      name: '',
      from_dateFrom: '',
      from_dateTo: '',
      to_dateFrom: '',
      to_dateTo: '',
      priority: '',
      status: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
  }, [query, dispatch]);

  useEffect(() => {
    if ((isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) && websiteId) {
      dispatch(getData(payload, controller));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    //eslint-disable-next-line
  }, [currentPage, pageSize, storeData, currentStore, keySearch, isDESC, query, isFilterSubmit, websiteId]);

  //Change Filter Handler

  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event

  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  const sortOrder = (column: string) => {
    if (keySearch !== column) {
      dispatch(setKeySearch(column));
      dispatch(setIsDESC(true));
    } else {
      dispatch(setIsDESC(!isDESC));
    }
  };
  const renderWebsite = (data: any[]) => {
    return data.map((item: any, key: number) => (
      <p className="mb-0" key={key}>
        {websiteList[item]?.name}
      </p>
    ));
  };

  useEffect(() => {
    if (deletePromotionStatus === 'fulfilled') {
      swalConfirmDelete
        .fire({
          title: `<img src="${successImg}" alt="success" className="mx-auto" />`,
          text: 'You have successfully deleted ',
          showCancelButton: false,
          showCloseButton: false,
          confirmButtonText: 'ok',
          customClass: {
            popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
            title: 'h3 text-dark text-center m-0 fw-semibold p-0',
            htmlContainer: 'message text-center m-0 fw-semibold',
            actions: 'mt-0 w-100',
            confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
          },
        })
        .then(() => {
          if (websiteId) {
            dispatch(getData(payload, controller));
          }
        });
    } else if (deletePromotionStatus === 'rejected') {
      swalConfirmDelete.fire({
        title: 'Delete Promotion',
        text: 'Something went wrong. Please wait and try again.',
        showCancelButton: false,
        showCloseButton: false,
        confirmButtonText: 'ok',
        customClass: {
          popup: 'p-32px border-rd-25 content-success bg-white d-flex flex-column gap-3',
          title: 'h3 text-dark text-center m-0 fw-semibold p-0',
          htmlContainer: 'message text-center m-0 fw-semibold',
          actions: 'mt-0 w-100',
          confirmButton: 'btn btn-primary btn-apply fs-14 fw-medium btn-border-radius border-rd-25 order-1 w-100',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePromotionStatus]);

  const onClickDeletePromotion = (promotionId: number) => {
    swalConfirmDelete
      .fire({
        title: 'Delete Promotion',
        html: 'Are you sure?<br />This promotion will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deletePromotion(promotionId, accessToken, currentStore));
        }
      });
  };

  // const onSearchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   setFilterPayload({ ...filterPayload, searchTerm: value });
  // };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      id: '',
      name: '',
      from_dateFrom: '',
      from_dateTo: '',
      to_dateFrom: '',
      to_dateTo: '',
      priority: '',
      status: '',
      searchTerm: '',
      coupon_code: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      id: '',
      name: '',
      from_dateFrom: '',
      from_dateTo: '',
      to_dateFrom: '',
      to_dateTo: '',
      priority: '',
      status: '',
      searchTerm: '',
      coupon_code: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  useEffect(() => {
    if (isFilterSubmit) {
      setSearchParams({
        currentPage: '1',
        pageSize: pageSize.toString(),
        ...submitFilterPayload,
        isFilter: 'true',
      });
      resetUrlData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterSubmit]);

  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const { target } = e;
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload });
        setSubmitFilterPayload(filterPayload);
        const filteredObject = filterEmptyProperties(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  };

  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'From Date',
        value:
          from_dateFrom + from_dateTo
            ? `${from_dateFrom ? moment(from_dateFrom).format('MM/DD/YYYY') : '...'} - ${
                from_dateTo ? moment(from_dateTo).format('MM/DD/YYYY') : '...'
              }`
            : '',
        resetHandler: () => resetFilterFromToSection('from_dateFrom', 'from_dateTo'),
      },
      {
        label: 'End Date',
        value:
          to_dateFrom + to_dateTo
            ? `${to_dateFrom ? moment(to_dateFrom).format('MM/DD/YYYY') : '...'} - ${to_dateTo ? moment(to_dateTo).format('MM/DD/YYYY') : '...'}`
            : '',
        resetHandler: () => resetFilterFromToSection('to_dateFrom', 'to_dateTo'),
      },
      {
        label: 'Status',
        value: formatFilterEnableStatusPromotion(status),
        resetHandler: () => resetFilterSection('status'),
      },
      {
        label: 'ID',
        // value: idFrom + idTo ? `${idFrom ? idFrom : '...'} - ${idTo ? idTo : '...'}` : '',
        value: id,
        resetHandler: () => resetFilterFromToSection('id', 'id'),
      },
      { label: 'Promotion Name', value: name, resetHandler: () => resetFilterSection('name') },
      // { label: 'Coupon Code', value: coupon_code, resetHandler: () => resetFilterFromToSection('coupon_code', 'coupon_code') },
      { label: 'Coupon Code', value: coupon_code, resetHandler: () => resetFilterSection('coupon_code') },
      {
        label: 'Priority',
        value: priority,
        resetHandler: () => resetFilterFromToSection('priority', 'priority'),
      },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilter = () => {
    return (
      <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>
        <div className="filter-section bg-white mb-4 p-4 wrapper border-rd-20">
          <div className="row">
            <h5 className="col-12 mb-4 text-dark ">Filters</h5>
            <DateRangeFilter
              filterClass="col-lg-4 col-xl-3"
              label="From Date"
              name="from_date"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />
            <DateRangeFilter filterClass="col-lg-4 col-xl-3" label="End Date" name="to_date" value={filterPayload} onChange={onFilterChangeHandler} />

            <SelectFilter
              filterClass="col-lg-4 col-xl-3"
              label="Status"
              name="status"
              options={promotionStatusFilter}
              value={filterPayload.status}
              onChange={onFilterChangeHandler}
            />
          </div>
          <div className="row">
            <NumberFilter filterClass="col-lg-4 col-xl-3 mt-3" label="ID" name="id" value={filterPayload.id} onChange={onFilterChangeHandler} />

            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Promotion Name"
              name="name"
              value={filterPayload.name}
              onChange={onFilterChangeHandler}
            />

            <TextFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Coupon Code"
              name="coupon_code"
              value={filterPayload.coupon_code}
              onChange={onFilterChangeHandler}
            />

            <NumberFilter
              filterClass="col-lg-4 col-xl-3 mt-3"
              label="Priority"
              name="priority"
              value={filterPayload.priority}
              onChange={onFilterChangeHandler}
            />
          </div>
          <FilterButtons onSubmit={onSubmit} status={status} setIsEnableFilter={() => setIsEnableFilter(false)} />
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="col-sm-12 col-lg-12">
        {renderFilter()}
        {renderFilterActive()}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        sortOrder={sortOrder}
        dataTableHead={dataPromotionHead}
        status={promotionStatus}
        message={message}
        className="striped-table table-responsive"
        keySearch={keySearch}
        isDESC={isDESC}
      >
        {promotionStatus === 'fulfilled' && promotionList.length ? (
          promotionList.map((item, i: number) => {
            const { coupon_code } = item;
            // const itemCode = couponCodeList.find((codeItem: any) => codeItem.rule_id === rule_id);
            // const code = itemCode?.code || '';
            return (
              <tr key={i}>
                <td className="td-item py-3 px-2 text-center fs-14 ">{item.rule_id}</td>
                <td className="td-item py-3 px-2 text-start fs-14">{item.name}</td>
                <td className="td-item py-3 px-2 text-start fs-14 ">{coupon_code}</td>
                <td className="td-item py-3 px-2 text-end fs-14 ">{item.from_date && moment(item.from_date).format('ll')}</td>
                <td className="td-item py-3 px-2 text-end fs-14 ">{item.to_date && moment(item.to_date).format('ll')}</td>
                <td className="td-item py-3 px-2 text-center fs-14 ">{item.sort_order}</td>
                <td className="td-item py-3 px-2 text-center fs-14 ">{renderWebsite(item.website_ids)}</td>
                <td className="td-item py-3 px-2 text-center fs-14 ">{formatIsActive(item.is_active)}</td>
                <td className="td-item py-3 px-2 text-center">
                  <div className="d-flex justify-content-center">
                    <div className="fw-light mb-0 text-center cursor-pointer text-primary edit-icon">
                      <Link to={`/promotion/edit/${item.rule_id}`}>
                        <FontAwesomeIcon className="fs-4" icon={faPenToSquare} />
                      </Link>
                    </div>
                    <div className="fw-light mb-0 text-center cursor-pointer text-danger trash-icon ms-3">
                      <span onClick={() => onClickDeletePromotion(item.rule_id)}>
                        <FontAwesomeIcon className="fs-4" icon={faTrashCan} />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <NoRecord tableHeaders={dataProductHead} message={message} />
        )}
      </Table>
    );
  };

  return (
    <DefaultLayout pageTitle="Promotion Listing">
      <div className="content-wrapper">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
                <SearchBar
                  name="searchTerm"
                  status={promotionStatus}
                  isTotalCount={true}
                  totalCount={total_count}
                  searchKeyPressHandler={searchKeyPressHandler}
                  onFilterChangeHandler={onFilterChangeHandler}
                  value={filterPayload.searchTerm}
                />
                <div className="text-end w-fit-content d-flex align-items-center justify-content-end justify-content-md-start">
                  <div className="btn-add-product btn-border-radius btn btn-primary border-rd-25 fw-medium me-2 p-0 d-flex justify-content-between align-items-center cursor-pointer text-nowrap">
                    <Link
                      to="/promotion/create"
                      className="border-0 text-white text-decoration-none d-flex align-items-center justify-content-center text-uppercase h-100 px-3 px-xl-4 flex-grow-1 fw-medium"
                    >
                      New Promotion
                    </Link>
                  </div>
                  <div
                    className="filters--button fs-14 d-flex justify-content-center align-items-center btn btn-border-radius fw-medium border-rd-25 text-uppercase mt-0"
                    onClick={() => setIsEnableFilter(!isEnableFilter)}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filters
                  </div>
                </div>
              </Separator>
              {renderContentSection()}

              <div className="table-section bg-white wrapper overflow-auto border-rd-20" style={{ overflowX: 'auto' }}>
                {renderTable()}
              </div>
              <Pagination
                className=""
                currentPage={+currentPage}
                pageSize={+pageSize}
                onChangePageSizeEvent={onChangePageSizeEvent}
                onChangePageEvent={onChangePage}
                status={promotionStatus}
                totalCount={total_count}
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PromotionListing;
