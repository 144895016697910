import DefaultLayout from '~/components/layout/DefaultLayout';
import ProductAttributeListing from './ProductAttributeListing';
import './style.scss';
import CreateEditAttribute from './CreateEditAttribute';

const ProductAttributes = () => {
  return (
    <DefaultLayout pageTitle="Product Attributes">
      <div className="product-attr-page row gy-4">
        <div className="col-12 col-lg-4 col-xxl-5">
          <CreateEditAttribute />
        </div>
        <div className="col-12 col-lg-8 col-xxl-7">
          <ProductAttributeListing />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ProductAttributes;
