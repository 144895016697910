import React from 'react';
import { useAppSelector } from '~/app/hooks/hooks';
import './style.scss';

interface props {
  onDisplayNav?: any;
}

const Hamburger: React.FC<props> = (props: props) => {
  const { onDisplayNav } = props;
  const { isDisplayNav } = useAppSelector((state) => state.pageReducer);
  return (
    <div className={`nav-icon-1 me-32 ${isDisplayNav ? 'open' : ''}`} onClick={onDisplayNav}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
