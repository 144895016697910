import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import folderImg from '~/app/images/folder.svg';
import { NoRecord } from '../Err';
import { iCategoryTreeItem } from '~/app/models';
import numeral from 'numeral';
import { Fragment, MouseEvent, useState } from 'react';
import { swalConfirmDelete } from '~/app/utils';
import {
  deleteCategoryById,
  getCategoryById,
  getProductsInCategoryById,
  setCurrentAction,
  setCurrentListSkuOfCategory,
  setFormDataCategory,
  setOldListSkuOfCategory,
  setParentCategory,
} from './redux/action';
import { iPayloadGetCategoryById } from '~/app/models/category';
import Loading from '~/components/common/Loading';
import ProductInCategory from './ProductInCategory';
import { resetDataAndStatus } from '../ProductListing/redux/action';

const CategoriesTree = () => {
  const dispatch = useAppDispatch();

  const { statusGetCategoriesTree, dataCateTree } = useAppSelector((s) => s.createEditProductReducer);
  const { formData, oldListSkuOfCategory, currentListSkuOfCategory, parentCategory } = useAppSelector((s) => s.categoryReducer);
  const { currentStore } = useAppSelector((s) => s.authReducer);

  const [idListActive, setIdListActive] = useState<number[]>([]);
  const [viewProducts, setViewProducts] = useState<boolean>(false);

  function handleChangeIdActive(id: number, e: MouseEvent) {
    const isClickedAction = (e?.target as HTMLElement)?.parentElement?.classList.contains('category-action') ?? true;
    if (isClickedAction) return;

    const isActive = idListActive.find((item) => item === id);
    const result = isActive ? idListActive.filter((item) => item !== id) : [...idListActive, id];
    setIdListActive(result);
  }

  function handleAddSubCategory(category: iCategoryTreeItem) {
    dispatch(setCurrentAction('create'));
    dispatch(setFormDataCategory(null));
    dispatch(setParentCategory(category));
  }

  function handleCollapseAll() {
    setIdListActive([]);
  }

  function handleExpandAll() {
    if (!dataCateTree?.length && !Array.isArray(dataCateTree)) return;

    const result: number[] = [];

    function handleForEach(item: iCategoryTreeItem) {
      if (!item.children_data.length) return;

      result.push(item.id);
      item.children_data.forEach(handleForEach);
    }

    dataCateTree.forEach(handleForEach);

    setIdListActive(result);
  }

  function handleCloseViewProducts() {
    dispatch(setCurrentListSkuOfCategory([]));
    dispatch(setOldListSkuOfCategory(null));
    dispatch(resetDataAndStatus());
    setViewProducts(false);
  }

  function handleBeforeCloseViewProducts() {
    const isChanged =
      oldListSkuOfCategory?.length === currentListSkuOfCategory.length && currentListSkuOfCategory.every((sku) => oldListSkuOfCategory.includes(sku))
        ? false
        : true;

    if (!isChanged) {
      handleCloseViewProducts();
      return;
    }

    setViewProducts(false);

    swalConfirmDelete
      .fire({
        title: 'Discard changes?',
        html: 'Your changes have not been saved.',
        width: 'auto',
        focusConfirm: true,
        focusCancel: false,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleCloseViewProducts();
        }

        if (result.isDismissed) {
          setViewProducts(true);
        }
      });
  }

  function handleShowViewProducts(id: number) {
    setViewProducts(true);
    dispatch(
      getProductsInCategoryById({
        currentStore,
        id,
      }),
    );
  }

  function handleGetCategoriesById(id: number) {
    if (formData?.id === id) return;

    const payload: iPayloadGetCategoryById = {
      currentStore,
      id,
    };

    dispatch(getCategoryById(payload));
  }

  function handleDeleteCategories(id: number) {
    swalConfirmDelete
      .fire({
        title: 'Delete Category',
        html: 'Are you sure?<br />This category will also be deleted from other stores.',
        width: 'auto',
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteCategoryById({
              currentStore,
              id,
            }),
          );
        }
      });
  }

  function renderCategoryList(categoryListSameLevelAndParent: iCategoryTreeItem[]) {
    if (!dataCateTree.length || !Array.isArray(dataCateTree))
      return (
        <table>
          <tbody>
            <NoRecord tableHeaders={[]} />
          </tbody>
        </table>
      );

    return categoryListSameLevelAndParent.map((item) => {
      const { children_data, id } = item;

      const showChildren = idListActive.find((item) => item === id);

      return (
        <Fragment key={id}>
          {renderCategoryItem(item)}

          {Array.isArray(children_data) && !!children_data.length && (
            <div className={`list-category-children h-100 d-flex flex-column gap-2 ${showChildren ? 'd-block' : 'd-none'}`}>
              {renderCategoryList(children_data)}
            </div>
          )}
        </Fragment>
      );
    });
  }

  function renderCategoryItem(item: iCategoryTreeItem) {
    const { children_data, product_count, id, name, is_active: is_disabled } = item;

    const hasChildren = Array.isArray(children_data) && !!children_data.length ? true : false;
    const isActive = formData?.id === id || parentCategory?.id === id;

    return (
      <div
        className={`category-item p-2 d-flex align-items-center justify-content-between cursor-pointer gap-3 ${isActive ? 'active' : ''} ${
          is_disabled ? '' : 'disabled'
        }`}
        onClick={(e) => hasChildren && handleChangeIdActive(id, e)}
      >
        <div className="category-item-left d-flex align-items-center gap-2 flex-grow-1">
          <div className="folder-wrap d-flex align-items-center justify-content-center" key={id}>
            <img className="folder-img" src={folderImg} alt="folder" />
          </div>

          <div className="info-categories d-flex align-items-center gap-2 fs-14 flex-grow-1">
            <span className="category-name two-line" title={name}>
              {name}
            </span>
            <div className="sub-info-wrap d-flex align-items-center gap-2">
              <span className="text-dark-400 text-nowrap">ID: {id}</span>
              <span className="text-dark-400 text-nowrap">{numeral(product_count).format('0,000').replaceAll(',', '.')} Products</span>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center gap-2">
          {/* Action */}
          <div className={`category-action fs-14 d-flex align-items-center gap-2 flex-shrink-0 text-nowrap ${isActive ? 'active' : ''}`}>
            <span className="link cursor-pointer" onClick={() => handleShowViewProducts(id)}>
              View Products
            </span>
            <span className="link cursor-pointer" onClick={() => handleAddSubCategory(item)}>
              + Add New
            </span>
            <span className="link cursor-pointer" onClick={() => handleGetCategoriesById(id)}>
              Edit
            </span>
            <span className="link cursor-pointer" onClick={() => handleDeleteCategories(id)}>
              Delete
            </span>
          </div>

          <div
            className={`toggle-icon-wrap d-flex align-items-center justify-content-center cursor-pointer ${hasChildren ? 'visible' : 'invisible'}`}
          >
            <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} className="toggle-img" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="wrapper p-4 h-100">
      {statusGetCategoriesTree === 'pending' ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 mb-4">
            <h3 className="fs-5 fw-semibold m-0">Category Listing</h3>
            <div className="utils-action d-flex align-items-center gap-2">
              <span className="link fs-14" onClick={handleCollapseAll}>
                Collapse All
              </span>
              <span className="link fs-14" onClick={handleExpandAll}>
                Expand All
              </span>
            </div>
          </div>

          <div className="d-flex flex-column gap-2">{renderCategoryList(dataCateTree)}</div>

          {/* Show popup */}
          {viewProducts && <ProductInCategory handleClose={handleBeforeCloseViewProducts} />}
        </>
      )}
    </div>
  );
};

export default CategoriesTree;
