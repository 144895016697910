import { TABLE_HEAD_PRODUCT_ATTRIBUTES } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import Loading from '~/components/common/Loading';
import { NoRecord } from '../Err';
import { convertScope } from '~/app/utils';
import { changeDataEdit } from './redux/action';
import { iProductAttributeItem } from '~/app/models';

const TableProductAttributes = () => {
  const dispatch = useAppDispatch();
  const { data, status } = useAppSelector((s) => s.productAttributeReducer);
  const { items } = data;

  function handleChangeDataEdit(item: iProductAttributeItem) {
    dispatch(changeDataEdit(item));
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          {TABLE_HEAD_PRODUCT_ATTRIBUTES.map((item, i) => (
            <th className={item.className} key={i}>
              {item.name}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  function renderTableBody() {
    if (status === 'pending') {
      return (
        <tbody>
          <tr>
            <td className="py-3" colSpan={TABLE_HEAD_PRODUCT_ATTRIBUTES.length + 1}>
              <Loading />
            </td>
          </tr>
        </tbody>
      );
    }

    if (status === 'rejected' || items.length === 0) {
      return (
        <tbody>
          <NoRecord tableHeaders={TABLE_HEAD_PRODUCT_ATTRIBUTES} />
        </tbody>
      );
    }
    return (
      <tbody className="info-item">
        {items.map((item) => {
          const {
            default_frontend_label,
            attribute_code,
            attribute_id,
            scope,
            is_required,
            is_user_defined,
            // is_visible_on_front,
            // is_searchable,
            // is_comparable,
          } = item;

          return (
            <tr key={attribute_id}>
              <td className="py-3 pe-3 fs-14 min-width-100px">
                <span className="three-line">{default_frontend_label}</span>
              </td>
              <td className="py-3 pe-3 fs-14 text-break min-width-100px">
                <span className="three-line">{attribute_code}</span>
              </td>
              <td className="py-3 pe-3 fs-14 text-nowrap">{convertScope(scope)}</td>
              <td className="py-3 pe-3 fs-14">{is_required ? 'Yes' : 'No'}</td>
              <td className="py-3 pe-3 fs-14">{is_user_defined ? 'No' : 'Yes'}</td>
              {/* <td className="py-3 pe-3 fs-14">{!!+is_visible_on_front ? 'Yes' : 'No'}</td>
              <td className="py-3 pe-3 fs-14">{!!+is_searchable ? 'Yes' : 'No'}</td>
              <td className="py-3 pe-3 fs-14">{!!+is_comparable ? 'Yes' : 'No'}</td> */}
              <td className="link py-3 fs-14 text-end" onClick={() => handleChangeDataEdit(item)}>
                Edit
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="table-product-attributes w-100 mb-2">
        {renderTableHeader()}
        {renderTableBody()}
      </table>
    </div>
  );
};

export default TableProductAttributes;
