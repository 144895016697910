import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { timeConvert } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { iGetOrderData, iGetShipment, iTableHead } from '~/app/models';
import { addFilterGroup, addFilterItem, addFilters, searchCriteriaBuilder } from '~/app/utils';
import AsyncSection from '~/components/common/AsyncSection';
import Button from '~/components/common/Button';
import Pagination from '~/components/common/Pagination';
import { Table } from '~/components/common/Table';
import { NoRecord } from '~/components/pages/Err';
import { getShipments } from '../redux/actions';

type Props = {};
const SHIPMENT_TABLE: iTableHead[] = [
  { name: 'Shipment', className: 'ps-4 fs-14 py-3 fw-medium td-item' },
  { name: 'Ship Date', className: 'fs-14 py-3 fw-medium text-end td-item' },
  { name: 'Order #', className: 'fs-14 py-3 fw-medium td-item' },
  { name: 'Order Date', className: 'fs-14 py-3 fw-medium text-end td-item' },
  { name: 'Ship-to Name', className: 'fs-14 py-3 fw-medium td-item' },
  { name: 'Total Quantity', className: 'fs-14 py-3 fw-medium text-end td-item' },
  { name: 'Action', className: 'fs-14 py-3 fw-medium text-center td-item' },
];
const Shipment: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { status, shipments, dataDetails } = useAppSelector((s) => s.orderReducer);

  const inititalState: iGetShipment = {
    pageSize: 10,
    currentPage: 1,
    accessToken,
  };

  const [dataPayload, setDataPayload] = useState<iGetShipment>(inititalState);

  const { pageSize, currentPage } = dataPayload;

  const { items, total_count, search_criteria } = shipments;

  const { extension_attributes } = dataDetails;

  const { orderId } = useParams();

  useEffect(() => {
    const payload: iGetOrderData = {
      accessToken,
      searchURL: searchCriteriaBuilder(+pageSize, +currentPage, addFilterGroup(addFilters(addFilterItem('order_id', `${orderId}`, 'eq')))),
    };
    dispatch(getShipments(payload, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const navigate = useNavigate();

  const onChangeHandler = (e: any) => {
    const { value, name } = e.target;
    setDataPayload({ ...dataPayload, [name]: value });
  };

  const onChangePage = (currentPage: string | number) => {
    setDataPayload({ ...dataPayload, currentPage });
  };

  const renderTable = () => {
    return (
      status === 'fulfilled' && (
        <div className="border-rd-20 overflow-auto bg-white wrapper">
          <Table status="fulfilled" dataTableHead={SHIPMENT_TABLE}>
            {items.length > 0 ? (
              items.map((item: any, key: number) => {
                return (
                  <tr className="table--item" key={key}>
                    <td className="td-item ps-4 pe-2 py-4 fs-14">{item.increment_id}</td>
                    <td className="td-item px-2 py-4 fs-14 text-end">
                      <div className="d-flex justify-content-end flex-column flex-xl-row gap-0 gap-xl-2 text-nowrap">
                        {timeConvert(item.created_at)}
                      </div>
                    </td>
                    <td className="td-item px-2 py-4 fs-14">{dataDetails.increment_id}</td>
                    <td className="td-item px-2 py-4 fs-14 text-end">
                      <div className="d-flex justify-content-end flex-column flex-xl-row gap-0 gap-xl-2 text-nowrap">
                        {timeConvert(item.created_at)}
                      </div>
                    </td>
                    <td className="td-item px-2 py-4 fs-14">
                      {extension_attributes &&
                        `${extension_attributes.shipping_assignments[0].shipping.address.firstname} ${extension_attributes.shipping_assignments[0].shipping.address.lastname}`}
                    </td>
                    <td className="td-item px-2 py-4 fs-14 text-end">{item.total_qty ? item.total_qty : 0}</td>
                    <td className="td-item px-2 py-4 fs-14 text-center">
                      <Link className="text-link text-decoration-none fs-6 view-icon" to={`${item.entity_id}`}></Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecord tableHeaders={SHIPMENT_TABLE} />
            )}
          </Table>
        </div>
      )
    );
  };

  return (
    <>
      <AsyncSection status={status} className="credit-memo mt-4">
        <div className="row">
          {dataDetails?.extension_attributes?.can_ship === '1' && (
            <div className="d-flex justify-content-end">
              <Button className="mb-3" onClick={() => navigate('create')}>
                CREATE SHIPMENT
              </Button>
            </div>
          )}

          <div className="col-sm-12">{renderTable()}</div>
        </div>
      </AsyncSection>
      <Pagination
        status={status}
        className=""
        currentPage={search_criteria.current_page}
        totalCount={total_count}
        pageSize={search_criteria.page_size}
        onChangePageSizeEvent={onChangeHandler}
        onChangePageEvent={onChangePage}
      />
    </>
  );
};

export default Shipment;
