import { ReactComponent as FilterIcon } from '~/app/images/filter.svg';
import TableProductAttributes from './TableProductAttributes';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useSearchParams } from 'react-router-dom';
import { FormEvent, useEffect, useState } from 'react';
import { getProductAttributes, setQueryString } from './redux/action';
import { addFilterGroup, addFilterItem, addFilters, addSortOrder, convertScope, filterEmptyProperties, searchCriteriaBuilder } from '~/app/utils';
import { FilterButtons, ResetFilterButton } from '~/components/common/Filter/filterInput';
import { Input } from '~/components/common/Input';
import Select from '~/components/common/Select';
import { FILTER_SCOPE_OPTIONS, FILTER_YES_NO_OPTIONS } from '~/app/constants/filter';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { iPayloadGetProductAttributes } from '~/app/models';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { builderCriteriaProductAttribute } from '~/app/utils/builderCriteria';
import { formatFilterActive, formatYesNo } from '~/components/common/Filter/formatFilter';
import Pagination from '~/components/common/Pagination';

export type FormFilterValuesProductAttribute = {
  frontend_label: string;
  attribute_code: string;
  scope: string;
  is_required: string;
  is_user_defined: string;
  is_visible_on_front: string;
  is_searchable: string;
  is_comparable: string;
};

const ProductAttributeListing = () => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { controller, keySearch, isDESC, data, status, statusCreateEditAttribute } = useAppSelector((s) => s.productAttributeReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const { total_count } = data;

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;

  const frontend_label = searchParams.get('frontend_label') || '';
  const attribute_code = searchParams.get('attribute_code') || '';
  const scope = searchParams.get('scope') || '';
  const is_required = searchParams.get('is_required') || '';
  const is_user_defined = searchParams.get('is_user_defined') || '';
  const is_visible_on_front = searchParams.get('is_visible_on_front') || '';
  const is_searchable = searchParams.get('is_searchable') || '';
  const is_comparable = searchParams.get('is_comparable') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    frontend_label,
    attribute_code,
    scope,
    is_required,
    is_user_defined,
    is_visible_on_front,
    is_searchable,
    is_comparable,
  } as FormFilterValuesProductAttribute;

  const [query, setQuery] = useState<string>(window.location.search);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [filterPayload, setFilterPayload] = useState<FormFilterValuesProductAttribute>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      frontend_label: '',
      attribute_code: '',
      scope: '',
      is_required: '',
      is_user_defined: '',
      is_visible_on_front: '',
      is_searchable: '',
      is_comparable: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      frontend_label: '',
      attribute_code: '',
      scope: '',
      is_required: '',
      is_user_defined: '',
      is_visible_on_front: '',
      is_searchable: '',
      is_comparable: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    handleGetProductAttribute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, currentStore, keySearch, isDESC, query]);

  useEffect(() => {
    if (statusCreateEditAttribute === 'fulfilled' && !firstMounted) {
      handleGetProductAttribute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateEditAttribute]);

  function handleGetProductAttribute() {
    const filter = builderCriteriaProductAttribute(submitFilterPayload);
    const sort = sorterCriteria(keySearch, isDESC);

    const payload: iPayloadGetProductAttributes = {
      controller,
      search: searchCriteriaBuilder(
        convertStringToNumber(pageSize.toString(), 10),
        convertStringToNumber(currentPage.toString(), 1),
        addFilterGroup(...filter, addFilters(addFilterItem('is_visible', `1`, 'eq'))),
        addSortOrder(...sort),
      ),
      currentStore,
    };

    // Run at first load
    if (isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) {
      dispatch(getProductAttributes(payload));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
  }

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      frontend_label: '',
      attribute_code: '',
      scope: '',
      is_required: '',
      is_user_defined: '',
      is_visible_on_front: '',
      is_searchable: '',
      is_comparable: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      frontend_label: '',
      attribute_code: '',
      scope: '',
      is_required: '',
      is_user_defined: '',
      is_visible_on_front: '',
      is_searchable: '',
      is_comparable: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  }

  //Declare render component
  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Name Product Attribute', value: frontend_label, resetHandler: () => resetFilterSection('frontend_label') },
      { label: 'Attribute Code', value: attribute_code, resetHandler: () => resetFilterSection('attribute_code') },
      { label: 'Scope', value: convertScope(scope), resetHandler: () => resetFilterSection('scope') },
      { label: 'Required', value: formatYesNo(is_required), resetHandler: () => resetFilterSection('is_required') },
      { label: 'System', value: formatYesNo(is_user_defined), resetHandler: () => resetFilterSection('is_user_defined') },
      { label: 'Visible', value: formatYesNo(is_visible_on_front), resetHandler: () => resetFilterSection('is_visible_on_front') },
      { label: 'Searchable', value: formatYesNo(is_searchable), resetHandler: () => resetFilterSection('is_searchable') },
      { label: 'Comparable', value: formatYesNo(is_comparable), resetHandler: () => resetFilterSection('is_comparable') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={() => resetFilter()} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilter = () => {
    return (
      <div className={`${isEnableFilter ? '' : 'd-none'} mb-4`}>
        <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>

        {/* Filter controller */}
        <div className="row g-3">
          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="frontend_label">
                Name Product Attribute
              </label>
              <Input
                className="fs-14"
                value={filterPayload.frontend_label}
                type="text"
                id="frontend_label"
                name="frontend_label"
                onChange={onFilterChangeHandler}
              />
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="attribute_code">
                Attribute Code
              </label>
              <Input
                className="fs-14"
                value={filterPayload.attribute_code}
                type="text"
                id="attribute_code"
                name="attribute_code"
                onChange={onFilterChangeHandler}
              />
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="attribute_code">
                Scope
              </label>
              <Select className="w-100 fs-14" name="scope" value={filterPayload.scope} onChange={onFilterChangeHandler}>
                {FILTER_SCOPE_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="is_required">
                Required
              </label>
              <Select className="w-100 fs-14" name="is_required" value={filterPayload.is_required} onChange={onFilterChangeHandler}>
                {FILTER_YES_NO_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="is_user_defined">
                System
              </label>
              <Select className="w-100 fs-14" name="is_user_defined" value={filterPayload.is_user_defined} onChange={onFilterChangeHandler}>
                {FILTER_YES_NO_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="is_visible_on_front">
                Visible
              </label>
              <Select className="w-100 fs-14" name="is_visible_on_front" value={filterPayload.is_visible_on_front} onChange={onFilterChangeHandler}>
                {FILTER_YES_NO_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="is_searchable">
                Searchable
              </label>
              <Select className="w-100 fs-14" name="is_searchable" value={filterPayload.is_searchable} onChange={onFilterChangeHandler}>
                {FILTER_YES_NO_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-12 col-lg-2">
            <div className="d-flex flex-column gap-2">
              <label className="fs-14 cursor-pointer" htmlFor="is_comparable">
                Comparable
              </label>
              <Select className="w-100 fs-14" name="is_comparable" value={filterPayload.is_comparable} onChange={onFilterChangeHandler}>
                {FILTER_YES_NO_OPTIONS.map((o, i) => (
                  <option value={o.value} key={i}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <FilterButtons onSubmit={onSubmit} status={'fulfilled'} setIsEnableFilter={() => setIsEnableFilter(false)} />
      </div>
    );
  };

  return (
    <div className="wrapper p-4">
      <div className="d-flex justify-content-between align-items-center gap-4 mb-4">
        <h3 className="m-0 fw-semibold fs-5">Product Attribute listing</h3>
        <div className={`filter-icon cursor-pointer ${isEnableFilter ? 'active' : ''}`} onClick={() => setIsEnableFilter(!isEnableFilter)}>
          <FilterIcon />
        </div>
      </div>
      {renderFilter()}
      {renderFilterActive()}
      <TableProductAttributes />
      <Pagination
        currentPage={+currentPage}
        pageSize={+pageSize}
        status={status}
        totalCount={total_count}
        onChangePageEvent={onChangePage}
        onChangePageSizeEvent={onChangePageSizeEvent}
      />
    </div>
  );
};

export default ProductAttributeListing;
