import { ReactComponent as TicketAll } from '~/app/images/tickets-all.svg';

export const MENU_REVIEW = [
  {
    menuId: -1,
    icon: TicketAll,
    title: 'All Reviews',
  },
  {
    menuId: 2,
    title: 'Pending',
  },
  {
    menuId: 1,
    title: 'Approved',
  },
  {
    menuId: 3,
    title: 'Not Approved',
  },
];

export const MENU_CUSTOM_FORM: { id: number; title: string }[] = [
  {
    id: 1,
    title: 'General',
  },
  {
    id: 2,
    title: 'Form Field',
  },
];
