import moment from 'moment';
import { NoRecord } from '../Err';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import PopupEdit from './PopupEdit';
import { convertReviewStatusIdToClassName, convertReviewStatusIdToText } from '~/app/utils';

const TableReview = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [infoReview, setInfoReview] = useState<any>({});

  const reviewList: any[] = [
    {
      id: 256,
      product_name: 'Rogers Wood/Walnut Nesting Round Coffee Table',
      create_at: '2023-08-16',
      rating: 4,
      title: 'Not Bad',
      description:
        'Solid & Sturdy Living Room console. The finishing is great. I would recommend this to anyone looking for something special to compliment their living room.',
      customer_type: 'Guess',
      customer_name: 'Lisa Lee',
      status: 1,
    },
    {
      id: 257,
      product_name: 'Table Z for students',
      create_at: '2023-08-16',
      rating: 3,
      title: 'Seat warmers are amazing for the winter.',
      description: 'Al-Balad is essentially Jeddah old town. It was once surrounded by a wall, but now only the gates... hmm',
      customer_type: 'Guess',
      customer_name: 'Victor',
      status: 2,
    },
    {
      id: 256,
      product_name: '#256: Windham Living Room Console and Coffee Table Set',
      create_at: '2023-08-16',
      rating: 3,
      title: 'I think the exterior and interior is much nicer than other cars in its class.',
      description: 'This a great historic site to visit. I rented a little cart and the driver was a really good friendly.',
      customer_type: 'Customer',
      customer_name: 'Káyon Nguyen',
      status: 3,
    },
  ];

  function handleShowPopup(infoReview: any) {
    setShowPopup(true);
    setInfoReview(infoReview);
  }

  function handleClose() {
    setShowPopup(false);
  }

  function renderReviewListing() {
    return (
      <div className="row gy-4">
        {reviewList.length ? (
          reviewList.map((review, i) => {
            const { id, title, description, create_at, rating, product_name, customer_type, customer_name, status } = review;
            const startNotFill = new Array(5 - (rating || 5)).fill(1);
            const startFill = new Array(rating).fill(1);

            return (
              <div className={`col-12 col-lg-6`} key={i}>
                <div className="wrapper p-4 overflow-x-auto">
                  <div className="review-item-heading d-flex align-items-center gap-4">
                    <img
                      className="thumbnail cover flex-shrink-0"
                      src="https://file.hstatic.net/200000455999/article/y_nghia_hoa_hong_a3efe1fd01cc435a8c85d1ddfd71748f.png"
                      alt="hoa"
                    />

                    <div className="info flex-grow-1 d-flex flex-column gap-2">
                      <h3
                        className="title fs-6 fw-medium two-line m-0 cursor-pointer"
                        onClick={() => handleShowPopup(review)}
                      >{`#${id}: ${product_name}`}</h3>
                      <span className="text-dark-600 fs-14">Create at {moment(create_at).format('ll')}</span>
                      <div className="rating d-flex align-items-center gap-1">
                        {startFill.map((_, i) => (
                          <FontAwesomeIcon className="text-danger" icon={faStar} key={i} />
                        ))}
                        {startNotFill.map((_, i) => (
                          <FontAwesomeIcon className="no-fill" icon={faStar} key={i} />
                        ))}
                      </div>
                    </div>

                    <span className={`btn-outline-custom fs-14 fw-medium align-self-start text-nowrap ${convertReviewStatusIdToClassName(status)}`}>
                      {convertReviewStatusIdToText(status)}
                    </span>
                  </div>

                  <div className="review-item-footer d-flex flex-column gap-2 pt-3 mt-3">
                    <span className="fs-14 text-dark-600">{`By ${customer_type}: ${customer_name}`}</span>
                    <h3 className="fs-6 fw-medium m-0">{title}</h3>
                    <p className="fs-14 text-dark-600 three-line m-0">{description}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <table>
            <tbody>
              <NoRecord tableHeaders={[]} />
            </tbody>
          </table>
        )}
      </div>
    );
  }

  return (
    <>
      {showPopup && <PopupEdit infoReview={infoReview} handleClose={handleClose} />}
      {renderReviewListing()}
    </>
  );
};

export default TableReview;
