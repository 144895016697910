import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import { PopupWrap } from '~/components/common/Popup';
import { Table } from '~/components/common/Table';
import { NoRecord } from '../Err';
import { dataHeadOfProductInCategory } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { saveChangeProductsInCategory, setCurrentListSkuOfCategory, setIsDESC, setKeySearch } from './redux/action';
import Pagination from '~/components/common/Pagination';
import Button from '~/components/common/Button';
import Select from '~/components/common/Select';
import { PRODUCT_IN_CATEGORY_STATUS, STATUS_OPTIONS, visibilityOptions } from '~/app/constants/filter';
import { addFilterGroup, addFilterItem, addFilters, addSortOrder, formatMoney, searchCriteriaBuilder } from '~/app/utils';
import { builderCriteriaProductsInCategories } from '~/app/utils/builderCriteria';
import { iGetAllProduct } from '~/app/models';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { getData } from '../ProductListing/redux/action';
import { formatEnableStatus, formatVisibility } from '~/components/common/Filter/formatFilter';
import CustomCheckBox from '~/components/common/CustomCheckBox';
import { Input } from '~/components/common/Input';
import { iDataAddProductToCategoryId } from '~/app/models/category';

interface Props {
  handleClose: () => void;
}

export interface FilterProductsInCategory {
  isSelect: '' | '1' | '0';
  id?: string;
  name?: string;
  sku?: string;
  status: '' | '1' | '0';
  visibility?: '' | '1' | '2' | '3' | '4';
  priceFrom?: string;
  priceTo?: string;
}

interface iPagination {
  currentPage: number;
  pageSize: number;
}

const ProductInCategory: FC<Props> = ({ handleClose }) => {
  const dispatch = useAppDispatch();

  const { isDESC, keySearch, currentCategoryId, oldListSkuOfCategory, currentListSkuOfCategory, statusSaveChangeProductsInCategory } = useAppSelector(
    (s) => s.categoryReducer,
  );
  const { status: productStatus, controller, data } = useAppSelector((s) => s.productReducer);
  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { items, total_count } = data;

  const initialFilter: FilterProductsInCategory = useMemo(
    () => ({
      isSelect: '1',
      id: '',
      name: '',
      sku: '',
      visibility: '',
      status: '',
      priceFrom: '',
      priceTo: '',
    }),
    [],
  );
  const [filter, setFilter] = useState<FilterProductsInCategory>(initialFilter);
  const [submitFilter, setSubmitFilter] = useState<FilterProductsInCategory>(initialFilter);
  const [pagination, setPagination] = useState<iPagination>({
    currentPage: 1,
    pageSize: 10,
  });
  const [isFilter, setIsFilter] = useState<boolean>(true);

  useEffect(() => {
    if (oldListSkuOfCategory === null || !isFilter) return;

    //Declare getData Payload
    const sort = sorterCriteria(keySearch, isDESC);
    const filter = builderCriteriaProductsInCategories(submitFilter);

    const payload: iGetAllProduct = {
      accessToken,
      searchUrl: searchCriteriaBuilder(
        pagination.pageSize,
        pagination.currentPage,
        addFilterGroup(...filter, addFilters(addFilterItem('website_id', `1`, 'eq'))),
        addSortOrder(...sort),
      ),
      currentStore,
    };

    setIsFilter(false);
    dispatch(getData(payload, controller));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldListSkuOfCategory, submitFilter, isFilter]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, handleClose);

  //Declare sort order function
  const sortOrder = (key: string) => {
    if (!key) return;

    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  function handleChangeFilter(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    const { value, name } = e.target;

    setFilter({ ...filter, [name]: value });
  }

  function handleFilter() {
    setSubmitFilter(filter);
    setPagination({ ...pagination, currentPage: 1 });
    setIsFilter(true);
  }

  function handleResetFilter() {
    setSubmitFilter({ ...initialFilter, isSelect: '' });
    setFilter({ ...initialFilter, isSelect: '' });
    setIsFilter(true);
  }

  function handleSaveProductsInCategory() {
    const listDelete = Array.isArray(oldListSkuOfCategory) ? oldListSkuOfCategory.filter((sku) => !currentListSkuOfCategory.includes(sku)) : [];
    const listCreateNew: iDataAddProductToCategoryId[] = currentListSkuOfCategory
      .filter((sku) => !oldListSkuOfCategory?.includes(sku))
      .map((sku) => ({
        productLink: { sku, position: 0, category_id: currentCategoryId ? `${currentCategoryId}` : '0' },
      }));

    dispatch(
      saveChangeProductsInCategory({
        categoryId: currentCategoryId || 0,
        currentStore,
        listCreateNew,
        listDelete,
        handleClose,
      }),
    );
  }

  // Change Page Size Event
  function onChangePageSizeEvent(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setPagination((pre) => ({ ...pre, pageSize: +value || 10 }));
    setIsFilter(true);
  }

  // Change current Page
  function onChangePage(currentPage: number) {
    setPagination((pre) => ({ ...pre, currentPage }));
    setIsFilter(true);
  }

  function handleChangeSelect(e: ChangeEvent<HTMLInputElement>, sku: string) {
    const isChecked = e.target.checked;

    dispatch(setCurrentListSkuOfCategory(isChecked ? [...currentListSkuOfCategory, sku] : currentListSkuOfCategory.filter((item) => item !== sku)));
  }

  function handleCheckOrUnCheckAllCurrentPage() {
    const isCheckAll = items.every((item) => currentListSkuOfCategory.includes(item.sku));
    const listSkuCurrentPage = items.map((item) => item.sku);

    // Handle uncheck
    if (isCheckAll) {
      const newListSku = currentListSkuOfCategory.filter((sku) => !listSkuCurrentPage.includes(sku));
      dispatch(setCurrentListSkuOfCategory(newListSku));
      return;
    }

    const newListSku = [...currentListSkuOfCategory, ...listSkuCurrentPage.filter((sku) => !currentListSkuOfCategory.includes(sku))];
    dispatch(setCurrentListSkuOfCategory(newListSku));
    return;
  }

  function renderTable() {
    return (
      <Table
        sortOrder={sortOrder}
        dataTableHead={dataHeadOfProductInCategory}
        status={productStatus}
        className="striped-table table-responsive"
        onChange={!productStatus || productStatus === 'pending' ? undefined : handleCheckOrUnCheckAllCurrentPage}
        isDESC={isDESC}
        keySearch={keySearch}
        checked={items.every((item) => currentListSkuOfCategory.includes(item.sku))}
      >
        <>
          {productStatus !== 'pending' && (
            <tr className="table-column-filter no-border">
              <td className="td-item px-2 align-top">
                <div className="d-flex justify-content-center align-items-center">
                  <Select onChange={handleChangeFilter} value={filter.isSelect} name="isSelect" className="w-fit-content">
                    {PRODUCT_IN_CATEGORY_STATUS.map((o, i) => (
                      <option value={o.value} key={i}>
                        {o.label}
                      </option>
                    ))}
                  </Select>
                </div>
              </td>
              <td className="td-item px-2 fs-14 fw-normal align-top w-60px">
                <Input value={filter.id} name="id" onChange={handleChangeFilter} className="w-100 input-filter-custom-short" />
              </td>
              <td className="td-item px-2 fs-14 fw-normal align-top">
                <Input value={filter.name} name="name" onChange={handleChangeFilter} className="w-100" />
              </td>
              <td className="td-item px-2 fs-14 fw-normal align-top">
                <Input value={filter.sku} name="sku" onChange={handleChangeFilter} className="w-100" />
              </td>
              <td className="td-item px-2 fs-14 fw-normal align-top">
                <Select value={filter.visibility} name="visibility" onChange={handleChangeFilter} className="w-100">
                  {visibilityOptions.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
              </td>
              <td className="td-item px-2 fs-14 fw-normal align-top">
                <Select value={filter.status} name="status" onChange={handleChangeFilter} className="w-100 select-filter-custom-medium">
                  {STATUS_OPTIONS.map((o, i) => (
                    <option key={i} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </Select>
              </td>
              <td className="td-item px-2 fs-14 fw-normal d-flex flex-column gap-2 align-top">
                <Input
                  value={filter.priceFrom}
                  name="priceFrom"
                  onChange={handleChangeFilter}
                  className="w-100 input-filter-custom-short"
                  placeholder="From"
                />
                <Input
                  value={filter.priceTo}
                  name="priceTo"
                  onChange={handleChangeFilter}
                  className="w-100 input-filter-custom-short"
                  placeholder="To"
                />
              </td>
            </tr>
          )}
          {productStatus === 'fulfilled' && items && items.length ? (
            items.map((item, i: number) => (
              <tr key={i}>
                <td className="td-item py-3 px-2 text-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <CustomCheckBox
                      className="checkbox-custom-position"
                      checked={currentListSkuOfCategory.includes(item.sku)}
                      onChange={(e) => handleChangeSelect(e, item.sku)}
                    />
                  </div>
                </td>
                <td className="td-item py-3 px-2 text-center fs-14 fw-light">{item.id}</td>
                <td className="td-item py-3 px-2 fs-14 fw-light">{item.name}</td>
                <td className="td-item py-3 px-2 fw-normal fs-14">{item.sku}</td>
                <td className="td-item py-3 px-2 fw-normal fs-14">{formatVisibility(item.visibility)}</td>
                <td className="td-item py-3 px-2 fw-normal text-center fs-14">
                  <p className="mb-0 fw-semibold text-center">{formatEnableStatus(item.status)}</p>
                </td>
                <td className="td-item py-3 px-2 pe-4 fw-normal fs-14 text-end">{formatMoney(item.price || 0, currentStore)}</td>
              </tr>
            ))
          ) : (
            <NoRecord tableHeaders={dataHeadOfProductInCategory} />
          )}
        </>
      </Table>
    );
  }

  return (
    <PopupWrap>
      <div className="popup-view-products wrapper p-32 d-flex flex-column gap-4" ref={ref}>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fs-5 fw-medium m-0">Products in Category</h3>
          <FontAwesomeIcon icon={faClose} className="close-icon" onClick={handleClose} />
        </div>

        <div>
          <div className="table-section bg-white wrapper overflow-auto border-rd-20">{renderTable()}</div>
          <Pagination
            currentPage={pagination.currentPage}
            pageSize={pagination.pageSize}
            onChangePageEvent={onChangePage}
            onChangePageSizeEvent={onChangePageSizeEvent}
            status={productStatus}
            totalCount={total_count}
          />
        </div>

        <div className="action d-flex align-items-center justify-content-end gap-2 flex-grow-1 mt-auto">
          <Button
            className="align-self-end"
            disabled={!productStatus || productStatus === 'pending' || statusSaveChangeProductsInCategory === 'pending'}
            onClick={handleFilter}
          >
            FILTER
          </Button>
          <Button
            className="align-self-end"
            disabled={!productStatus || productStatus === 'pending' || statusSaveChangeProductsInCategory === 'pending'}
            onClick={handleResetFilter}
          >
            RESET FILTER
          </Button>
          <Button
            className="align-self-end"
            disabled={!productStatus || productStatus === 'pending' || statusSaveChangeProductsInCategory === 'pending'}
            onClick={handleSaveProductsInCategory}
            showLoadingRight={statusSaveChangeProductsInCategory === 'pending'}
          >
            SAVE
          </Button>
          <Button
            className="align-self-end"
            disabled={!productStatus || productStatus === 'pending' || statusSaveChangeProductsInCategory === 'pending'}
            outline
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </PopupWrap>
  );
};

export default ProductInCategory;
