import Skeleton from 'react-loading-skeleton';
import './style.scss';

export const ChartLoading = () => {
  const renderSkeleton = () => {
    const skeletonArr = new Array(12);
    // Assign 1 to use the Array.map method
    skeletonArr.fill(1);

    return skeletonArr.map((_, index) => (
      <Skeleton height={Math.floor(Math.random() * 280) + 20} width="100%" key={index} />
    ));
  };

  return <div className="chart-skeleton">{renderSkeleton()}</div>;
};
