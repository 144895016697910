import { faClose, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { REVIEW_STATUS_OPTIONS } from '~/app/constants/filter';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import Button from '~/components/common/Button';
import { PopupWrap } from '~/components/common/Popup';
import Select from '~/components/common/Select';

interface Props {
  infoReview: any;
  handleClose: () => void;
}
const PopupEdit: FC<Props> = ({ infoReview, handleClose }) => {
  const { status, create_at, id, product_name, rating } = infoReview;
  const ref = useRef<HTMLDivElement>(null);

  const [newStatus, setNewStatus] = useState<number>(status);
  useClickOutside(ref, handleClose);

  const FAKE_ARR_MEDIA_ATTACHMENT: any[] = [1, 2];

  const startNotFill = useMemo(() => new Array(5 - (rating || 5)).fill(1), [rating]);
  const startFill = useMemo(() => new Array(rating).fill(1), [rating]);

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    setNewStatus(+e.target.value);
  }

  return (
    <PopupWrap className="d-flex align-items-center justify-content-center overflow-hidden">
      <div className="wrapper w-700px p-4 d-flex flex-column gap-3 overflow-auto" ref={ref}>
        <div className="review-item-heading d-flex align-items-center gap-4">
          <img
            className="thumbnail cover flex-shrink-0"
            src="https://file.hstatic.net/200000455999/article/y_nghia_hoa_hong_a3efe1fd01cc435a8c85d1ddfd71748f.png"
            alt="hoa"
          />

          <div className="info flex-grow-1 d-flex flex-column gap-2">
            <h3 className="title fs-6 fw-medium two-line m-0 cursor-pointer">{`#${id}: ${product_name}`}</h3>
            <span className="text-dark-600 fs-14">Create at {moment(create_at).format('ll')}</span>
            <div className="rating d-flex align-items-center gap-1">
              {startFill.map((_, i) => (
                <FontAwesomeIcon className="text-danger" icon={faStar} key={i} />
              ))}
              {startNotFill.map((_, i) => (
                <FontAwesomeIcon className="no-fill" icon={faStar} key={i} />
              ))}
            </div>
          </div>

          <FontAwesomeIcon icon={faClose} className="close-icon cursor-pointer align-self-start" onClick={handleClose} />
        </div>

        <div className="popup-body row gy-20px fs-14">
          <div className="col-12 col-lg-3 align-self-center">
            <span className="fw-medium">Status</span>
          </div>
          <div className="col-12 col-lg-9">
            <Select className="w-100" value={newStatus} onChange={handleChange}>
              {REVIEW_STATUS_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </div>

          <div className="col-12 col-lg-3">
            <span className="fw-medium">Nickname</span>
          </div>
          <div className="col-12 col-lg-9">
            <span>Kayson Lee</span>
          </div>

          <div className="col-12 col-lg-3">
            <span className="fw-medium">Title</span>
          </div>
          <div className="col-12 col-lg-9">
            <span className="two-line">The table looks really nice, I like it very much. The table looks really nice, I like it very much.</span>
          </div>

          <div className="col-12 col-lg-3">
            <span className="fw-medium">Review</span>
          </div>
          <div className="col-12 col-lg-9">
            <span className="three-line">
              The table looks really nice, I like it very much. The table looks really nice, I like it very much. The table looks really nice, I like
              it very much. The table looks really nice, I like it very much.
            </span>
          </div>

          <div className="col-12 col-lg-3">
            <span className="fw-medium">Link Product</span>
          </div>
          <div className="col-12 col-lg-9">
            <a
              className="link text-truncate d-block"
              href="https://addin-dev.lotustest.net/tesst-porrtasl-poroduct-singapore"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://addin-dev.lotustest.net/tesst-porrtasl-poroduct-singapore
            </a>
          </div>

          {!!FAKE_ARR_MEDIA_ATTACHMENT.length && (
            <>
              <div className="col-12 col-lg-3">
                <span className="fw-medium">Attachment</span>
              </div>

              <div className="col-12 col-lg-9">
                <div className="d-flex gap-2 flex-wrap">
                  {FAKE_ARR_MEDIA_ATTACHMENT.map((_, i) => (
                    <img
                      className="review-attachment-img"
                      src="https://www.daklak.city/uploads/files/2022/05/17/cac-loai-hoa-cuc-1.jpg"
                      alt="hoa"
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="d-flex align-items-center gap-4">
          <Button className="w-100" outline>
            DELETE
          </Button>
          <Button className="w-100" disabled={status === newStatus}>
            UPDATE STATUS
          </Button>
        </div>
      </div>
    </PopupWrap>
  );
};

export default PopupEdit;
