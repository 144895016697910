import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import './style.scss';
import { onChangeDataHeader, onChangeListWebsiteSelected } from '../redux/actions';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import { FormikProps } from 'formik';
import { Input, TextArea } from '~/components/common/Input';
import { iFormik } from '..';
import { MultiSelect } from '~/components/common/MultiSelect';
import { useParams } from 'react-router-dom';

interface Props {
  formik: FormikProps<iFormik>;
}
const Header: FC<Props> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { dataHeader, dataWebsiteSelect } = useAppSelector((state) => state.createEditPromotionReducer);
  const { data: dataUser } = useAppSelector((state) => state.userReducer);
  const { currentStore } = useAppSelector((state) => state.authReducer);
  const [query, setQuery] = useState<string>('');
  const { website_ids } = dataUser || {};
  const { idPromotion = 0 } = useParams();

  const listWebsiteActive = useMemo(() => {
    if (!website_ids) {
      return [];
    }
    const storeListObj = JSON.parse(website_ids || '');
    const storeList = Object.entries(storeListObj);

    const result = storeList.map((item: any) => {
      const [id, websiteStore] = item;
      return {
        id: +id,
        name: websiteStore.name,
      };
    });
    return result;
  }, [website_ids]);

  const { name, is_active, description, from_date, to_date, coupon_code, sort_order, uses_per_customer } = dataHeader;

  const { handleBlur, handleChange, errors, touched } = formik;

  // useEffect(() => {
  //   dispatch(onChangeListWebsiteSelected(dataSelected));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataSelected]);

  // useEffect(() => {
  //   if (!idPromotion) return;
  //   setDataSelected(dataWebsiteSelect);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataWebsiteSelect]);

  useEffect(() => {
    if (!website_ids || idPromotion) return;

    const storeListObj = JSON.parse(website_ids || '');
    const storeList = Object.entries(storeListObj);

    const defaultSelectWebsite = storeList.filter((item: any) => {
      const websiteStore = item[1];
      const codeOfStoreNow = websiteStore.stores.some((store: any) => store.code === currentStore);
      return codeOfStoreNow;
    });

    const result = defaultSelectWebsite?.map((item: any) => {
      const [id, websiteStore] = item;
      return {
        id: +id,
        name: websiteStore.name,
      };
    });
    dispatch(onChangeListWebsiteSelected(result));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [website_ids]);

  function handleChangeData(e: any) {
    const { value, name, type, checked } = e.target;
    const newData = {
      ...dataHeader,
      [name]: type === 'checkbox' ? checked : value,
    };
    dispatch(onChangeDataHeader(newData));
  }

  function renderName() {
    return (
      <div>
        <div className="d-flex align-items-center ">
          <label htmlFor="name" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
            Name <span className="text-danger">*</span>
          </label>
          <div className="d-flex flex-column flex-grow-1">
            <Input
              type="text"
              id="name"
              name="name"
              className="coupon-res-input"
              value={name}
              onChange={(e) => {
                handleChangeData(e);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </div>
        </div>
        {touched.name && errors.name ? <div className="error-message validate-coupon">{errors.name}</div> : null}
      </div>
    );
  }

  function renderEnablePromotion() {
    return (
      <CheckboxRounded
        check={is_active}
        onChange={handleChangeData}
        name="is_active"
        label="Enable"
        className="fs-16 title text-end align-items-center"
        showCheckbox
      />
    );
  }

  function renderEnableLimit() {
    return (
      <CheckboxRounded
        check={!!uses_per_customer}
        onChange={handleChangeData}
        name="uses_per_customer"
        label="Limit Coupon"
        className="fs-16 title text-end align-items-center"
        showCheckbox
      />
    );
  }
  function renderContent() {
    return (
      <>
        <div className="d-flex ">
          <label htmlFor="description" className="title d-flex justify-content-end fs-16 m-0 fw-normal me-4 cursor-pointer">
            Description
          </label>
          <div className="d-flex flex-column gap-2 description-coupon">
            <TextArea className="form-control-short-desc" name="description" id="description" value={description} onChange={handleChangeData} />
          </div>
        </div>
        <div className="start-date-promotion">
          <div className="d-flex align-items-center w-fit-content">
            <label htmlFor="from_date" className="title text-end fw-normal m-0 me-4 flex-shrink-0 cursor-pointer">
              Start Date
            </label>
            <div className="attr-set-wrap input-type">
              <Input type="date" id="from_date" name="from_date" className="form-input--custom large" value={from_date} onChange={handleChangeData} />
            </div>
          </div>

          <div className="d-flex align-items-center flex-grow-1 justify-content-end">
            <label htmlFor="to_date" className="text-end fw-normal m-0 me-4 label-right cursor-pointer">
              End Date
            </label>
            <div className="attr-set-wrap input-type">
              <Input
                type="date"
                id="to_date"
                name="to_date"
                className="form-input--custom large"
                disabled={false}
                value={to_date}
                onChange={handleChangeData}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {/* </div> */}

          {/* <div className="d-flex align-items-center coupon-code-priority"> */}
          <div className="d-flex align-items-center w-fit-content">
            <label htmlFor="coupon_code" className="title text-end fw-normal m-0 me-4 cursor-pointer">
              Coupon Code
            </label>
            <div className="attr-set-wrap input-type">
              <Input
                type="text"
                id="coupon_code"
                name="coupon_code"
                className="form-input--custom large"
                disabled={false}
                value={coupon_code}
                onChange={handleChangeData}
              />
            </div>
          </div>

          <div className="d-flex align-items-center flex-grow-1 justify-content-end">
            <label htmlFor="priority" className="text-end fw-normal m-0 me-4 label-right cursor-pointer">
              Priority
            </label>
            <div className="attr-set-wrap input-type">
              <Input
                type="number"
                id="priority"
                name="sort_order"
                className="form-input--custom large"
                disabled={false}
                value={sort_order}
                onChange={handleChangeData}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center w-100">
          <label htmlFor="discount-type" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
            Websites
          </label>
          <div className="categories-coupon">
            <MultiSelect
              removeSearchWhenSelected
              status="fulfilled"
              options={listWebsiteActive.filter(
                (item: any) =>
                  !dataWebsiteSelect.map((item: any) => item.id).includes(item.id) &&
                  item.name.trim().toLowerCase().includes(query.trim().toLowerCase()),
              )}
              listSelected={dataWebsiteSelect}
              query={query}
              setQuery={setQuery}
              onSelectItem={(item) => {
                dispatch(onChangeListWebsiteSelected([...dataWebsiteSelect, item]));
              }}
              onRemoveAllItem={() => {
                dispatch(onChangeListWebsiteSelected([]));
              }}
              onRemoveItem={(itemRemove) => {
                dispatch(onChangeListWebsiteSelected(dataWebsiteSelect.filter((item: any) => item.id !== itemRemove.id)));
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className=" line-height-title separator-top mb-3 fs-5">Coupon Information</div>
      <div className="wrapper d-flex flex-column gap-3 header mt-3">
        {renderEnablePromotion()}
        {renderEnableLimit()}
        {renderName()}
        {renderContent()}
      </div>
    </>
  );
};

export default Header;
