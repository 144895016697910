import React from 'react';
import { CURRENT_YEAR } from '~/app/constants';
import Logout from '../Logout';

const Footer: React.FC = () => {
  /* const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logOutAction(navigate));
    dispatch(resetPathname());
  }; */

  return (
    <div className="footer py-2 py-xxl-4">
      <div className="logout text-center mt-3 mt-xxl-0">
        <div className="logout-icon">
          <Logout />
        </div>
      </div>
      <div className="text-center copy-right px-2">
        {CURRENT_YEAR} &copy; <span className="text-white">Addin {process.env.REACT_APP_LOCALIZATION}</span>
      </div>
    </div>
  );
};

export default Footer;
