import {
  iBrandItem,
  iCurrentAction,
  iPayloadCreateBrand,
  iPayloadDeleteBrandById,
  iPayloadEditBrandById,
  iPayloadGetBrand,
  iPayloadGetOptionByAttributeCode,
  iResponseListing,
} from '~/app/models';
import * as types from './index';
import brandApi from '~/app/apis/brand.api';
import { AxiosResponse } from 'axios';
import { convertMessageError, swalToast } from '~/app/utils';
import productAttributesApi from '~/app/apis/product-attributes.api';

const { rest } = types;

export const getData = (payload: iPayloadGetBrand) => async (dispatch: any) => {
  const { controller: cancelController } = payload;
  cancelController && cancelController.abort();

  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));
  try {
    const { data }: AxiosResponse<iResponseListing<iBrandItem[]>> = await brandApi.getBrand(payload);

    !data ? dispatch(rest.getDataRejected()) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;
    dispatch(rest.getDataRejected());
  } finally {
    dispatch(rest.changeController(null));
  }
};

export const deleteBrandById = (payload: iPayloadDeleteBrandById) => async (dispatch: any) => {
  dispatch(rest.deleteBrandByIdPending());

  try {
    await brandApi.deleteBrandById(payload);
    // Show notification
    swalToast.fire({
      title: 'Delete Successfully!',
      icon: 'success',
    });

    // Reset data
    dispatch(setCurrentAction('create'));
    dispatch(setFormDataBrand(null));
    dispatch(rest.deleteBrandByIdFulfilled());
  } catch (error: any) {
    const message = convertMessageError(error);
    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
    dispatch(rest.deleteBrandByIdRejected());
  }
};

export const createNewBrand = (payload: iPayloadCreateBrand) => async (dispatch: any) => {
  dispatch(rest.createBrandPending());

  try {
    await brandApi.createBrand(payload);
    // Show notification
    swalToast.fire({
      title: 'Create Brand Successfully!',
      icon: 'success',
    });
    dispatch(rest.createBrandFulfilled());
  } catch (error: any) {
    const message = convertMessageError(error);
    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
    dispatch(rest.createBrandRejected());
  }
};

export const editBrandById = (payload: iPayloadEditBrandById) => async (dispatch: any) => {
  dispatch(rest.editBrandByIdPending());

  try {
    const { data }: AxiosResponse<iBrandItem> = await brandApi.editBrandById(payload);
    // Show notification
    swalToast.fire({
      title: 'Create Brand Successfully!',
      icon: 'success',
    });
    dispatch(rest.editBrandByIdFulfilled(data));
  } catch (error: any) {
    const message = convertMessageError(error);
    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
    dispatch(rest.editBrandByIdRejected());
  }
};

export const getOptionsByAttributeCode = (payload: iPayloadGetOptionByAttributeCode) => async (dispatch: any) => {
  dispatch(rest.getOptionsByAttributeCodePending());
  try {
    const { data } = await productAttributesApi.getOptionsByAttrCode(payload);

    Array.isArray(data) && !!data.length && dispatch(rest.getOptionsByAttributeCodeFulfilled(data));
  } catch (error) {
    dispatch(rest.getOptionsByAttributeCodeRejected());
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};
export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(q));
};

export const setCurrentAction = (newAction: iCurrentAction) => (dispatch: any) => {
  dispatch(rest.setCurrentAction(newAction));
};

export const setFormDataBrand = (newFormDataBrand: iBrandItem | null) => (dispatch: any) => {
  dispatch(rest.setFormData(newFormDataBrand));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};
