import * as yup from 'yup';

export const formCategorySchema = yup.object().shape({
  name: yup.string().required('Category Name is required.'),
});

export const availableProductListSortBySchema = yup.object().shape({
  available_sort_by: yup.array().min(1, 'This field is required.'),
  default_sort_by: yup
    .string()
    .oneOf([yup.ref('available_sort_by'), null], 'Default Product Listing Sort by does not exist in Available Product Listing Sort By.'),
});

export const filterPriceRangeSchema = yup.object().shape({
  filter_price_range: yup.number().required('This field is required.'),
});
