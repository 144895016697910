import { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAllStores, getAllWebsites } from '~/components/pages/Account/redux/action';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import './style.scss';

interface Props {
  children: ReactNode;
  pageTitle: string;
}

const FullWidthLayout: FC<Props> = (props: Props) => {
  const { children, pageTitle } = props;
  const dispatch = useAppDispatch();
  const { isLogin, lastPathName } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    document.title = `${pageTitle} - ${process.env.REACT_APP_PRE_PAGE_TITLE}`;

    dispatch(getAllStores());
    dispatch(getAllWebsites());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle]);

  return isLogin ? (
    <Navigate to={lastPathName ? lastPathName : '/dashboard'} replace />
  ) : (
    <div className="main-content min-vh-100 d-flex flex-column justify-content-center align-items-center">{children}</div>
  );
};

export default FullWidthLayout;
