import React, { useMemo, useRef, useState } from 'react';
import './style.scss';
import Hamburger from '../../../common/Hamburger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser, faUserPen, faChevronUp, faRightFromBracket, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { setIsDisplayNav } from '../redux/actions';
import Button from '~/components/common/Button';
import { logOutAction, resetPathname } from '../../FullWidthLayout/redux/actions';
import { useClickOutside } from '~/app/hooks/useClickOutSide';
import storeImg from '~/app/images/store.svg';
import PopupComponent from '../../Popup';
import TransitPopup from '~/components/pages/Auth/Transit/Transit';

interface props {
  pageTitle: string;
  subTitle?: string;
}

const Search: React.FC<props> = (props: props) => {
  const { pageTitle, subTitle } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const imgPath = process.env.REACT_APP_IMAGE_URL;

  const [isDisplayTooltip, setIsDisplayToolTip] = useState<boolean>(false);
  const toolTipRef = useRef<HTMLDivElement>(null);

  const { data, status } = useAppSelector((state) => state.userReducer);
  const { isDisplayNav } = useAppSelector((state) => state.pageReducer);
  const { isLogin, currentStoreName } = useAppSelector((state) => state.authReducer);

  const [isDisplayPopup, setIsDisplayPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  // const onDisplayTooltip = () => {
  //   setIsDisplayToolTip(!isDisplayTooltip);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fullName = useMemo(() => data.last_name && data.first_name && `${data.last_name} ${data.first_name}`, [data.last_name, data.first_name]);
  const listStore = useMemo(() => {
    if (Object.keys(data).length < 1) {
      return [];
    }

    return Object.entries(JSON.parse(data.website_ids ?? {}))
      .map((item: any) => item[1].stores || [])
      .flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.website_ids]);

  const onDisplayNav = () => {
    dispatch(setIsDisplayNav(!isDisplayNav));
  };

  const logOut = () => {
    dispatch(logOutAction(navigate));
    dispatch(resetPathname());
  };

  useClickOutside(popupRef, () => {
    setIsDisplayPopup(false);
  });

  useClickOutside(toolTipRef, () => {
    setIsDisplayToolTip(false);
  });

  const renderPopup = () => {
    return (
      <PopupComponent status="fulfilled">
        <div ref={popupRef}>
          <TransitPopup isLogin={isLogin} toggleModal={() => setIsDisplayPopup(!isDisplayPopup)} />
        </div>
      </PopupComponent>
    );
  };

  const renderTooltips = () => {
    return (
      <div className={`tooltips ${isDisplayTooltip ? '' : 'd-none'} bg-white d-flex justify-content-center align-items-start flex-column`}>
        <Link
          className="tooltips--items d-flex align-items-center text-decoration-none fw-normal fs-15 mb-4 fw-medium fs-16 d-block w-100 px-4"
          to={'/profile/overview'}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={faUser} />
          </div>
          My Profile
        </Link>
        <Link
          className="tooltips--items d-flex align-items-center text-decoration-none fw-normal fs-15 mb-4 fw-medium fs-16 d-block w-100 px-4"
          to={'/profile/settings'}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={faUserPen} />
          </div>
          Settings
        </Link>
        <div className="footer-menu d-flex justify-content-center w-100">
          <Button className="border-rd-25 py-12 fs-16 fw-medium w-100 mx-4 gap-3" onClick={logOut}>
            <FontAwesomeIcon icon={faRightFromBracket} className="fs-6" />
            <span>Log Out</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="header header-search row bg-white">
      {isDisplayPopup && renderPopup()}
      <div className="col-12">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center px-3 gap-3">
          <div className="menu-bar-search d-flex align-items-center ps-1">
            <Hamburger onDisplayNav={onDisplayNav} />
            <div className="page-header">
              <h1 className="text-uppercase mb-0 fw-semibold" title={pageTitle}>
                {pageTitle}
              </h1>

              {subTitle && (
                <p className="m-0 sub-title-page fs-16" title={subTitle}>
                  {subTitle}
                </p>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-4">
            <div className="switch-store-btn d-flex align-items-center gap-3  p-12">
              {listStore.length > 1 ? (
                <div
                  className="header__features--settings cursor-pointer d-flex justify-content-center align-items-center border-0"
                  onClick={() => setIsDisplayPopup(!isDisplayPopup)}
                >
                  <img src={storeImg} alt="store" />
                </div>
              ) : null}

              <h3 className="mb-0 fw-medium fs-6">{currentStoreName || 'Singapore'}</h3>
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <div className="justify-content-end d-flex">
                <div
                  className="header__features--avatar d-flex align-items-center cursor-pointer dropdown"
                  onClick={() => {
                    setIsDisplayToolTip(!isDisplayTooltip);
                  }}
                  ref={toolTipRef}
                >
                  <div className="user-avatar d-flex align-items-center">
                    <img
                      className="rounded-circle logo logo-sm"
                      src={data.image ? imgPath + data.image : require('~/app/images/no-avatar.png')}
                      alt=""
                    />
                    <span className="triangle username mb-0 ps-3 cursor-pointer fw-medium text-truncate d-block mw-200px" title={fullName}>
                      {status === 'fulfilled' && `${fullName}`}
                      {status === 'rejected' && <FontAwesomeIcon icon={faTriangleExclamation} className="me-3 text-danger" />}
                    </span>
                    <FontAwesomeIcon icon={!isDisplayTooltip ? faChevronDown : faChevronUp} className="ms-3" />
                  </div>
                  {renderTooltips()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
