import { ReactNode } from 'react';

type colTableHeader = {
  className: string;
  name: string;
};

type Props = {
  tableHeader: colTableHeader[];
  children: ReactNode;
  className?: string;
};

const Table = ({ tableHeader, children, className }: Props) => {
  function renderHeader() {
    return (
      <thead>
        <tr>
          {tableHeader.map((col, i: number) => (
            <th className={col.className} key={i}>
              {col.name}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  return (
    <table id="table-config" className={`w-100 ${className}`}>
      {renderHeader()}
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
