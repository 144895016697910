import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status, iBrandItem, iCurrentAction, iInitState, iResponseListing } from '~/app/models';
import { Option } from '~/app/models/filter';

interface iState extends iInitState {
  data: iResponseListing<iBrandItem[]>;
  statusDeleteBrand: Status;
  statusCreateBrand: Status;
  statusEditBrand: Status;
  urlData: {
    pageSize: number;
    currentPage: number;
  };
  queryString: string;
  isDESC: boolean;
  keySearch: string;
  controller: null | AbortController;
  formData: iBrandItem | null;
  currentAction: iCurrentAction;
  optionsBrand: Option[];
}

const initialState: iState = {
  status: '',
  statusDeleteBrand: '',
  statusCreateBrand: '',
  statusEditBrand: '',
  controller: null,
  data: { items: [], search_criteria: {}, total_count: 0 } as iResponseListing<iBrandItem[]>,
  urlData: { pageSize: 10, currentPage: 1 },
  isDESC: false,
  keySearch: 'shopbrand_id',
  queryString: '',
  formData: null,
  currentAction: 'create',
  optionsBrand: [],
};

const brandSlice = createSlice({
  name: 'BRAND LIST',
  initialState,
  reducers: {
    // Synchronous Actions
    resetData: () => ({ ...initialState }),
    setQueryString: (state, action) => ({ ...state, queryString: action.payload }),

    setUrlData: (state, action) => ({ ...state, urlData: action.payload }),
    setIsDESC: (state, action) => ({ ...state, isDESC: action.payload }),
    setKeySearch: (state, action) => ({ ...state, keySearch: action.payload }),
    setCurrentAction: (state, action: PayloadAction<iCurrentAction>) => ({ ...state, currentAction: action.payload }),
    setFormData: (state, action: PayloadAction<iBrandItem | null>) => ({ ...state, formData: action.payload }),
    changeController: (state, action) => ({ ...state, controller: action.payload }),
    resetQueryString: (state) => ({ ...state, queryString: '' }),

    // Asynchronous Actions
    getDataFulfilled: (state, action) => ({ ...state, data: action.payload, status: 'fulfilled' }),
    getDataRejected: (state) => ({ ...state, status: 'rejected' }),
    getDataPending: (state) => ({ ...state, status: 'pending' }),

    deleteBrandByIdFulfilled: (state) => ({ ...state, statusDeleteBrand: 'fulfilled' }),
    deleteBrandByIdRejected: (state) => ({ ...state, statusDeleteBrand: 'rejected' }),
    deleteBrandByIdPending: (state) => ({ ...state, statusDeleteBrand: 'pending' }),

    createBrandFulfilled: (state) => ({ ...state, statusCreateBrand: 'fulfilled' }),
    createBrandRejected: (state) => ({ ...state, statusCreateBrand: 'rejected' }),
    createBrandPending: (state) => ({ ...state, statusCreateBrand: 'pending' }),

    editBrandByIdFulfilled: (state, { payload }: PayloadAction<iBrandItem>) => ({ ...state, statusEditBrand: 'fulfilled', formData: payload }),
    editBrandByIdRejected: (state) => ({ ...state, statusEditBrand: 'rejected' }),
    editBrandByIdPending: (state) => ({ ...state, statusEditBrand: 'pending' }),

    getOptionsByAttributeCodeFulfilled: (state, { payload }: PayloadAction<Option[]>) => ({ ...state, optionsBrand: payload }),
    getOptionsByAttributeCodeRejected: (state) => ({ ...state }),
    getOptionsByAttributeCodePending: (state) => ({ ...state }),
  },
});

export const { ...rest } = brandSlice.actions;
const brandReducer = brandSlice.reducer;

export default brandReducer;
