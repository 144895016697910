import DefaultLayout from '~/components/layout/DefaultLayout';
import TableBrand from './TableBrand';
import './style.scss';
import CreateEditBrand from './CreateEditBrand';
import { addFilterGroup, addSortOrder, filterEmptyProperties, searchCriteriaBuilder } from '~/app/utils';
import { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { useSearchParams } from 'react-router-dom';
import { getData, getOptionsByAttributeCode, setCurrentAction, setFormDataBrand, setQueryString } from './redux/action';
import Separator from '~/components/common/Separator';
import SearchBar from '~/components/common/Filter/searchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Pagination from '~/components/common/Pagination';
import { FilterButtons, ResetFilterButton, SelectFilter, TextFilter } from '~/components/common/Filter/filterInput';
import { formatFilterActive, formatFilterEnableStatus } from '~/components/common/Filter/formatFilter';
import { STATUS_OPTIONS } from '~/app/constants/filter';
import { builderCriteriaBrand } from '~/app/utils/builderCriteria';
import { iPayloadGetBrand } from '~/app/models';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { sorterCriteria } from '~/app/utils/sorterCriteria';

export type FormFilterValuesBrand = {
  status: string;
  title: string;
  urlkey: string;
  searchTerm: string;
};

const BrandListing = () => {
  const dispatch = useAppDispatch();

  const { currentStore } = useAppSelector((s) => s.authReducer);
  const {
    data,
    controller,
    keySearch,
    isDESC,
    status: getBrandStatus,
    statusDeleteBrand,
    statusCreateBrand,
    statusEditBrand,
  } = useAppSelector((s) => s.brandReducer);
  const { total_count } = data;

  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;
  const searchTerm = searchParams.get('searchTerm') || '';

  const status = searchParams.get('status') || '';
  const title = searchParams.get('title') || '';
  const urlkey = searchParams.get('urlkey') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    status,
    title,
    urlkey,
    searchTerm,
  } as FormFilterValuesBrand;

  const [query, setQuery] = useState<string>(window.location.search);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [filterPayload, setFilterPayload] = useState<FormFilterValuesBrand>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);

  useEffect(() => {
    setFirstMounted(false);
    dispatch(getOptionsByAttributeCode({ currentStore, attribute_code: 'brand' }));
    return () => {
      controller && controller.abort();
      dispatch(setCurrentAction('create'));
      dispatch(setFormDataBrand(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      status: '',
      title: '',
      urlkey: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      status: '',
      title: '',
      urlkey: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    handleGetBrandListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, currentStore, keySearch, isDESC, query, isFilterSubmit]);

  useEffect(() => {
    if (statusDeleteBrand === 'fulfilled') {
      handleGetBrandListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDeleteBrand]);

  useEffect(() => {
    if (statusCreateBrand === 'fulfilled') {
      handleGetBrandListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateBrand]);

  useEffect(() => {
    if (statusEditBrand === 'fulfilled') {
      handleGetBrandListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusEditBrand]);

  function handleGetBrandListing() {
    const filter = builderCriteriaBrand(submitFilterPayload);
    const sort = sorterCriteria(keySearch, isDESC);

    const payload: iPayloadGetBrand = {
      controller,
      search: searchCriteriaBuilder(
        convertStringToNumber(pageSize.toString(), 10),
        convertStringToNumber(currentPage.toString(), 1),
        addFilterGroup(...filter),
        addSortOrder(...sort),
      ),
      currentStore,
    };

    // Run at first load
    if (isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) {
      dispatch(getData(payload));
    }

    if (Object.keys(filterEmptyProperties(submitFilterPayload)).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
  }

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      status: '',
      title: '',
      urlkey: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      status: '',
      title: '',
      urlkey: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  }

  //  //Declare render component
  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      { label: 'Status', value: formatFilterEnableStatus(status), resetHandler: () => resetFilterSection('status') },
      { label: 'Title', value: title, resetHandler: () => resetFilterSection('title') },
      { label: 'URL Key', value: urlkey, resetHandler: () => resetFilterSection('urlkey') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={resetFilter} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilter = () => {
    return (
      <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>
        <div className="filter-section bg-white mb-4 p-4 wrapper border-rd-20">
          <div className="row gy-3">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>

            <SelectFilter
              filterClass="col-lg-4 col-xl-3"
              label="Status"
              name="status"
              options={STATUS_OPTIONS}
              value={filterPayload.status}
              onChange={onFilterChangeHandler}
            />

            <TextFilter filterClass="col-lg-4 col-xl-3" label="Title" name="title" value={filterPayload.title} onChange={onFilterChangeHandler} />
            <TextFilter filterClass="col-lg-4 col-xl-3" label="URL Key" name="urlkey" value={filterPayload.urlkey} onChange={onFilterChangeHandler} />
          </div>

          <FilterButtons onSubmit={onSubmit} status={'fulfilled'} setIsEnableFilter={() => setIsEnableFilter(false)} />
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="brand-page">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={'fulfilled'}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="text-end w-fit-content d-flex align-items-center justify-content-end justify-content-md-start">
            <div
              className="filters--button fs-14 d-flex justify-content-center align-items-center btn btn-border-radius fw-medium border-rd-25 text-uppercase mt-0"
              onClick={() => setIsEnableFilter(!isEnableFilter)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </div>
          </div>
        </Separator>

        {renderFilter()}
        {renderFilterActive()}

        <div className="row g-3 g-xxl-4">
          <div className="col-12 col-lg-8 col-xxl-9 order-1 order-lg-0">
            <TableBrand />
            <Pagination
              currentPage={+currentPage}
              pageSize={+pageSize}
              onChangePageSizeEvent={onChangePageSizeEvent}
              onChangePageEvent={onChangePage}
              status={getBrandStatus}
              totalCount={total_count}
            />
          </div>
          <div className="col-12 col-lg-4 col-xxl-3">
            <CreateEditBrand />
          </div>
        </div>
      </div>
    );
  };

  return <DefaultLayout pageTitle="Brands">{renderContentSection()}</DefaultLayout>;
};

export default BrandListing;
