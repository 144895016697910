import productApi from '~/app/apis/product.api';
import * as types from './index';
import { iGetAllProduct } from '~/app/models';

const { rest } = types;

export const getData = (payload: iGetAllProduct, cancelController?: AbortController) => async (dispatch: any) => {
  cancelController && cancelController.abort();
  dispatch(rest.getDataPending());
  const controller = new AbortController();
  dispatch(rest.changeController(controller));
  try {
    const res = await productApi.getAll(payload, controller);
    const { data } = res;
    !data ? dispatch(rest.getDataRejected('')) : dispatch(rest.getDataFulfilled(data));
  } catch (error: any) {
    if (error.message === 'canceled') return;
    // const { message } = error?.response?.data || '';
    dispatch(rest.getDataRejected(''));
  }
};

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};
export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(q));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};

export const resetDataAndStatus = () => (dispatch: any) => {
  dispatch(rest.resetDataAndStatus());
};
