import DefaultLayout from '~/components/layout/DefaultLayout';
import TableReview from './TableReview';
import './style.scss';
import Separator from '~/components/common/Separator';
import SearchBar from '~/components/common/Filter/searchBar';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateRangeFilter, FilterButtons, ResetFilterButton, SelectFilter, TextFilter } from '~/components/common/Filter/filterInput';
import { convertReviewValueTypeToText, filterEmptyProperties } from '~/app/utils';
import Pagination from '~/components/common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { REVIEW_TYPE_OPTIONS } from '~/app/constants/filter';
import { formatFilterActive } from '~/components/common/Filter/formatFilter';
import { setQueryString } from './redux/action';
import MenuHorizontal from '~/components/common/MenuHorizontal';
import { MENU_REVIEW } from '~/app/constants/menuHorizontal';
import moment from 'moment';

type FormFilterValues = {
  createAtFrom: string;
  createAtTo: string;
  type: string;
  id: string;
  title: string;
  nickname: string;
  review: string;
  searchTerm: string;
  menuId: string;
};

const ReviewListing = () => {
  const dispatch = useAppDispatch();

  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { data, controller, keySearch, isDESC } = useAppSelector((s) => s.reviewReducer);
  const { total_count } = data;

  const [searchParams, setSearchParams] = useSearchParams();

  // Declare data from Query String
  const isFilter = Boolean(searchParams.get('isFilter'));
  const isAdvancedFilter = Boolean(searchParams.get('isAdvancedFilter'));
  const pageSize = searchParams.get('pageSize') || 10;
  const currentPage = searchParams.get('currentPage') || 1;

  const id = searchParams.get('id') || '';
  const createAtFrom = searchParams.get('createAtFrom') || '';
  const createAtTo = searchParams.get('createAtTo') || '';
  const title = searchParams.get('title') || '';
  const nickname = searchParams.get('nickname') || '';
  const review = searchParams.get('review') || '';
  const menuId = searchParams.get('menuId') || '-1';
  const type = searchParams.get('type') || '';
  const searchTerm = searchParams.get('searchTerm') || '';

  // Declare initial Filter Payload
  const initialFilterPayload = {
    id,
    createAtFrom,
    createAtTo,
    nickname,
    title,
    review,
    type,
  } as FormFilterValues;

  const [query, setQuery] = useState<string>(window.location.search);
  const [isEnableFilter, setIsEnableFilter] = useState<boolean>(false);
  const [filterPayload, setFilterPayload] = useState<FormFilterValues>(initialFilterPayload);
  const [submitFilterPayload, setSubmitFilterPayload] = useState<any>(initialFilterPayload);
  const [isFilterSubmit, setIsFilterSubmit] = useState<Boolean>();
  const [firstMounted, setFirstMounted] = useState<Boolean>(true);
  const [activeMenuId, setActiveMenuId] = useState<number>(+menuId || -1);

  useEffect(() => {
    setFirstMounted(false);
    return () => {
      controller && controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstMounted) return;
    setIsEnableFilter(false);
    setFilterPayload({
      ...filterPayload,
      createAtFrom: '',
      createAtTo: '',
      type: '',
      id: '',
      nickname: '',
      review: '',
      title: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      createAtFrom: '',
      createAtTo: '',
      type: '',
      id: '',
      nickname: '',
      review: '',
      title: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    dispatch(setQueryString(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    // Run at first load
    if (isFilter || isAdvancedFilter || (!isFilter && !isAdvancedFilter)) {
      // Call API here
      // dispatch(getData(payload, controller));
    }

    // Alway menuId
    const newObject: any = filterEmptyProperties(submitFilterPayload);
    newObject.menuId && delete newObject.menuId;

    if (Object.keys(newObject).length === 0) {
      setIsFilterSubmit(false);
    } else {
      setIsFilterSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, currentStore, keySearch, isDESC, query, isFilterSubmit]);

  //Change Filter Handler
  const onFilterChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    setFilterPayload({ ...filterPayload, [name]: value });
  };

  // Reset Url
  const resetUrlData = (): void => {
    const { search } = window.location;
    setQuery(search);
  };

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isFilter) {
      setSearchParams({
        pageSize: value,
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        pageSize: value,
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: value });
      resetUrlData();
    }
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    if (isFilter) {
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        searchTerm,
        isFilter: 'true',
      });
      resetUrlData();
    } else if (isAdvancedFilter) {
      const filteredObject = filterEmptyProperties(submitFilterPayload);
      setSearchParams({
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        ...filteredObject,
        isAdvancedFilter: 'true',
      });
      resetUrlData();
    } else {
      setSearchParams({ pageSize: pageSize.toString(), currentPage: currentPage.toString() });
      resetUrlData();
    }
  };

  //Declare reset function
  const resetFilter = () => {
    setFilterPayload({
      ...filterPayload,
      createAtFrom: '',
      createAtTo: '',
      type: '',
      id: '',
      nickname: '',
      review: '',
      title: '',
      searchTerm: '',
    });
    setSubmitFilterPayload({
      ...filterPayload,
      createAtFrom: '',
      createAtTo: '',
      type: '',
      id: '',
      nickname: '',
      review: '',
      title: '',
      searchTerm: '',
    });
    setSearchParams({});
    resetUrlData();
    !isEnableFilter && setIsEnableFilter(true);
  };

  const resetFilterSection = (key: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${key}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${key}`]: '' });
    setSubmitFilterPayload({ ...filteredObject, [`${key}`]: '' });
    resetUrlData();
  };

  const resetFilterFromToSection = (keyFrom: string, keyTo: string) => {
    const filteredObject = filterEmptyProperties(filterPayload);
    setSearchParams({
      currentPage: currentPage.toString(),
      pageSize: pageSize.toString(),
      ...filteredObject,
      [`${keyFrom}`]: '',
      [`${keyTo}`]: '',
      isAdvancedFilter: 'true',
    });
    setFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    setSubmitFilterPayload({ ...filterPayload, [`${keyFrom}`]: '', [`${keyTo}`]: '' });
    resetUrlData();
  };

  //Declare submit filter function
  const searchKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    const filteredObject = filterEmptyProperties(filterPayload);
    if (key === 'Enter') {
      if (target instanceof HTMLInputElement) {
        setFilterPayload({ ...filterPayload, searchTerm: target.value });
        setSubmitFilterPayload(filterPayload);
        setSearchParams({
          currentPage: '1',
          pageSize: pageSize.toString(),
          searchTerm: target.value,
          ...filteredObject,
          isFilter: 'true',
        });
        resetUrlData();
        setIsFilterSubmit(true);
      }
    }
  };

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    setSubmitFilterPayload({ ...filterPayload });
    const filteredObject = filterEmptyProperties(filterPayload);

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
    setIsFilterSubmit(true);
  }

  function handleChangeMenuId(menuId: number) {
    setActiveMenuId(menuId);

    const filteredObject = filterEmptyProperties({ ...submitFilterPayload, menuId: menuId.toString() });

    setSearchParams({
      currentPage: '1',
      pageSize: pageSize.toString(),
      ...filteredObject,
      isAdvancedFilter: 'true',
    });
    resetUrlData();
  }

  //  //Declare render component
  const renderFilterActive = () => {
    const filterSections = [
      { label: 'Keyword', value: searchTerm, resetHandler: () => resetFilterSection('searchTerm') },
      {
        label: 'Create At',
        value:
          createAtFrom + createAtTo
            ? `${createAtFrom ? moment(createAtFrom).format('MM/DD/YYYY') : '...'} - ${createAtTo ? moment(createAtTo).format('MM/DD/YYYY') : '...'}`
            : '',
        resetHandler: () => resetFilterFromToSection('createAtFrom', 'createAtTo'),
      },
      { label: 'Type', value: convertReviewValueTypeToText(type), resetHandler: () => resetFilterSection('type') },
      { label: 'ID', value: id, resetHandler: () => resetFilterSection('id') },
      { label: 'Nickname', value: nickname, resetHandler: () => resetFilterSection('nickname') },
      { label: 'Title', value: title, resetHandler: () => resetFilterSection('title') },
      { label: 'Review', value: review, resetHandler: () => resetFilterSection('review') },
    ];

    return (
      isFilterSubmit && (
        <div className="col-12 mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="p-3 fs-6 fw-medium">Active Filters</span>
            {isFilterSubmit && <ResetFilterButton onClick={resetFilter} />}
          </div>
          <div className="border-top border-bottom p-3 align-items-center">
            {filterSections.map(({ label, value, resetHandler }) => value && formatFilterActive(label, value, resetHandler))}
          </div>
        </div>
      )
    );
  };

  const renderFilter = () => {
    return (
      <div className={`filter col-sm-12 col-lg-12${isEnableFilter ? '' : ' d-none'}`}>
        <div className="filter-section bg-white mb-4 p-4 wrapper border-rd-20">
          <div className="row gy-3">
            <h5 className="col-12 mb-4 text-dark filter-title">Filters</h5>
            <DateRangeFilter
              filterClass="col-lg-4 col-xl-3"
              label="Create At"
              name="createAt"
              value={filterPayload}
              onChange={onFilterChangeHandler}
            />

            <SelectFilter
              filterClass="col-lg-4 col-xl-3"
              label="Type"
              name="type"
              options={REVIEW_TYPE_OPTIONS}
              value={filterPayload.type}
              onChange={onFilterChangeHandler}
            />
          </div>

          <div className="row mt-3 gy-3">
            <TextFilter filterClass="col-lg-4 col-xl-3" label="ID" name="id" value={filterPayload.id} onChange={onFilterChangeHandler} />
            <TextFilter
              filterClass="col-lg-4 col-xl-3"
              label="Nickname"
              name="nickname"
              value={filterPayload.nickname}
              onChange={onFilterChangeHandler}
            />
            <TextFilter filterClass="col-lg-4 col-xl-3" label="Title" name="title" value={filterPayload.title} onChange={onFilterChangeHandler} />
            <TextFilter filterClass="col-lg-4 col-xl-3" label="Review" name="review" value={filterPayload.review} onChange={onFilterChangeHandler} />
          </div>

          <FilterButtons onSubmit={onSubmit} status={'fulfilled'} setIsEnableFilter={() => setIsEnableFilter(false)} />
        </div>
      </div>
    );
  };

  const renderContentSection = () => {
    return (
      <div className="review-page">
        <Separator className="mb-4 d-flex flex-column flex-lg-row justify-content-end justify-content-lg-between align-items-end align-items-lg-center gap-3">
          <SearchBar
            name="searchTerm"
            status={'fulfilled'}
            isTotalCount={true}
            totalCount={total_count}
            searchKeyPressHandler={searchKeyPressHandler}
            onFilterChangeHandler={onFilterChangeHandler}
            value={filterPayload.searchTerm}
          />
          <div className="text-end w-fit-content d-flex align-items-center justify-content-end justify-content-md-start">
            <div
              className="filters--button fs-14 d-flex justify-content-center align-items-center btn btn-border-radius fw-medium border-rd-25 text-uppercase mt-0"
              onClick={() => setIsEnableFilter(!isEnableFilter)}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </div>
          </div>
        </Separator>

        {renderFilter()}
        {renderFilterActive()}

        <MenuHorizontal activeMenuId={activeMenuId} onChange={handleChangeMenuId} menu={MENU_REVIEW} />
        <TableReview />

        <Pagination
          currentPage={+currentPage}
          pageSize={+pageSize}
          onChangePageSizeEvent={onChangePageSizeEvent}
          onChangePageEvent={onChangePage}
          status={''}
          totalCount={101}
        />
      </div>
    );
  };

  return <DefaultLayout pageTitle="Reviews">{renderContentSection()}</DefaultLayout>;
};

export default ReviewListing;
