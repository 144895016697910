import authApi from '~/app/apis/auth.api';
import { iCreateUser, iForgotPwd, iLogin, iResetPwd } from '~/app/models';
import userApi from '~/app/apis/user.api';
import * as actionsUser from '~/components/pages/Account/redux';
import { resetOrderQueryString } from '~/components/pages/Orders/redux/actions';
import { resetProductQueryString } from '~/components/pages/ProductListing/redux/action';
import { resetCustomerQueryString } from '~/components/pages/Customers/redux/action';
import { resetSellerQueryString } from '~/components/pages/Seller/redux/action';
import { resetPromotionQueryString } from '~/components/pages/PromotionListing/redux/action';

import * as types from './index';
import ticketApi from '~/app/apis/ticket.api';
import { defaultMessageError } from '~/app/constants';

const { rest: actions } = types;

export const allowedToLogin = () => (dispatch: any) => {
  dispatch(actions.loginForceAllowed());
};

export const login = (payload: iLogin, navigate: any) => async (dispatch: any) => {
  dispatch(actions.loginPending());
  try {
    const res = await authApi.login(payload);
    const { data: accessToken } = res;
    dispatch(actions.changeAccessToken(accessToken));

    try {
      const res = await userApi.getInfo();
      const { website_ids } = res.data;

      dispatch(actionsUser.rest.getUserFulfilled(res.data));

      let websiteObject;
      try {
        const _websiteObject = JSON.parse(website_ids);

        typeof _websiteObject === 'object' && !Array.isArray(_websiteObject) && _websiteObject !== null && (websiteObject = _websiteObject);
      } catch (error) {}

      // Count the number of management stores
      const listStore: any = Object.entries(websiteObject || {})
        .map((item: any) => item[1].stores || [])
        .flat();

      if (listStore.length < 1) {
        dispatch(actions.loginRejected("Your account hasn't been activated yet"));
      } else if (listStore.length === 1) {
        const websiteItem: any = Object.values(websiteObject)?.[0];
        const currentStoreName = websiteItem?.name || '';

        dispatch(actions.loginFulfilled(accessToken));
        dispatch(actions.changeStore(`${listStore[0].code}`));
        dispatch(actions.changeStoreName(currentStoreName));
        dispatch(allowedToLogin());
        navigate('/dashboard');
      } else {
        dispatch(actions.loginFulfilled(accessToken));
        navigate('/auth/transit');
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {};
      dispatch(actions.loginRejected(message || defaultMessageError));
    }
  } catch (error: any) {
    const { message } = error?.response?.data || {};
    dispatch(actions.loginRejected(message || defaultMessageError));
  }
};

export const tokenExpired = () => (dispatch: any) => {
  dispatch(actions.verifyTokenRejected());
};

export const resetPassword = (payload: iResetPwd) => async (dispatch: any) => {
  dispatch(actions.resetPwdPending());
  try {
    const res = await authApi.resetPwd(payload);
    const message = res.data;

    dispatch(actions.resetPwdFulfilled(message));
  } catch (error: any) {
    const { message } = error?.response?.data || {};

    dispatch(actions.resetPwdRejected(message || 'Something went wrong. Please try again.'));
  }
};

export const forgotPwdByEmail = (payload: iForgotPwd) => async (dispatch: any) => {
  dispatch(actions.checkEmailPending());
  try {
    const res = await authApi.forgotPwd(payload);
    const message = res.data;

    dispatch(actions.checkEmailFulfilled(message));
  } catch (error: any) {
    const { message } = error?.response?.data || {};

    dispatch(actions.checkEmailRejected(message || 'Something went wrong. Please try again.'));
  }
};

export const createUser = (payload: iCreateUser) => async (dispatch: any) => {
  dispatch(actions.createUserPending());

  try {
    await authApi.createUser(payload);
    dispatch(actions.createUserFulfilled());
  } catch (error: any) {
    const { message } = error?.response?.data || {};

    dispatch(actions.createUserRejected(message || 'Something went wrong. Please try again.'));
  }
};

export const clearMessage = () => (dispatch: any) => {
  dispatch(actions.deleteMessage());
};

export const logOutAction = (navigate: any) => (dispatch: any) => {
  dispatch(actions.logout());
  dispatch(actionChangeStore(''));
  dispatch(actionChangeStoreName(''));
  dispatch(resetOrderQueryString());
  dispatch(resetProductQueryString());
  dispatch(resetCustomerQueryString());
  dispatch(resetSellerQueryString());
  dispatch(resetPromotionQueryString());

  // Close popup ckfinder
  const closeBtn = document.querySelectorAll('#ckf-modal-close') as any;
  if (!closeBtn) return;
  closeBtn[0]?.click();

  navigate('/auth/sign-in');
};

export const actionChangeLastPathName = (lastPathName: string) => (dispatch: any) => {
  dispatch(actions.changeLastPathName(lastPathName));
};

export const actionChangeStore = (storeCode: string) => (dispatch: any) => {
  dispatch(actions.changeStore(storeCode));
};

export const actionChangeStoreName = (storeName: string) => (dispatch: any) => {
  dispatch(actions.changeStoreName(storeName));
};

export const actionChangeWebCurrency = (currency: string) => (dispatch: any) => {
  dispatch(actions.changeWebCurrency(currency));
};

export const fetchStores = () => async (dispatch: any) => {
  dispatch(actions.fetchStoresPending());

  try {
    const res = await authApi.fetchStores();
    !res.data ? dispatch(actions.fetchStoresRejected()) : dispatch(actions.fetchStoresFulfilled(res.data));
  } catch (error) {
    dispatch(actions.fetchStoresRejected());
  }
};

export const resetPathname = () => (dispatch: any) => {
  dispatch(actions.resetPathname());
};

export const getQtyNewTicket =
  (searchURL: any, accessToken: string, store: string = 'all') =>
  async (dispatch: any) => {
    try {
      const controller = new AbortController();
      const { data } = await ticketApi.getTicketListing(searchURL, accessToken, store, controller);
      const { total_count } = data;

      dispatch(actions.getQtyNewTicket(total_count || 0));
    } catch (error) {
      dispatch(actions.getQtyNewTicket(0));
    }
  };
