import {
  iCategoryItemPayload,
  iPayloadAddProductToCategoryId,
  iPayloadCreateCategory,
  iPayloadDeleteProductFromCategoryId,
  iPayloadGetCategoryById,
} from '../models/category';
import axiosConfig from './axiosConfig';

const categoryApi = {
  createCategory: (payload: iPayloadCreateCategory) => {
    const { data } = payload;
    const url = `all/V1/categories`;
    return axiosConfig.post(url, data);
  },
  getCategoryById: (payload: iPayloadGetCategoryById) => {
    const { currentStore, id } = payload;
    const url = `${currentStore}/V1/categories/${id}`;
    return axiosConfig.get(url);
  },
  editCategoryById: (payload: iPayloadGetCategoryById & { data: iCategoryItemPayload }) => {
    const { currentStore, id, data } = payload;
    const url = `${currentStore}/V1/categories/${id}`;
    return axiosConfig.put(url, data);
  },
  deleteCategoryById: (payload: iPayloadGetCategoryById) => {
    const { currentStore, id } = payload;
    const url = `${currentStore}/V1/categories/${id}`;
    return axiosConfig.delete(url);
  },
  getProductsInCategoryById: (payload: iPayloadGetCategoryById) => {
    const { currentStore, id } = payload;
    const url = `${currentStore}/V1/categories/${id}/products`;
    return axiosConfig.get(url);
  },
  addProductToCategoryId: (payload: iPayloadAddProductToCategoryId) => {
    const { currentStore, categoryId, data } = payload;
    const url = `${currentStore}/V1/categories/${categoryId}/products`;
    return axiosConfig.post(url, data);
  },
  deleteProductFromCategoryId: (payload: iPayloadDeleteProductFromCategoryId) => {
    const { currentStore, categoryId, sku } = payload;
    const url = `${currentStore}/V1/categories/${categoryId}/products/${sku}`;
    return axiosConfig.delete(url);
  },
};

export default categoryApi;
