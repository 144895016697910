import { FC } from 'react';
import moment from 'moment';
import { faUser, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '~/app/hooks/hooks';
import { formatCountry, formatEmailStatus, formatGroup, formatLastLogin } from '~/components/common/Filter/formatFilter';
import AsyncSection from '~/components/common/AsyncSection';

const Overview: FC = () => {
  //Declare data from Reducers
  const { status, customerDetail, customerStatus } = useAppSelector((state) => state.customerReducer);

  const { addresses, extension_attributes } = customerDetail;

  const renderPersonalInfoRow = (title: string, value: string, key: string) => {
    return (
      <div className="row mb-3" key={key}>
        <div className="col-sm-4">
          <span className="fs-14 mb-3 fw-medium">{title}</span>
        </div>
        <div className="col-sm-8">
          <span className="fs-14 mb-3">{value}</span>
        </div>
      </div>
    );
  };

  const renderInformation = () => {
    const infoFields = [
      {
        label: 'Last Logged In',
        value: extension_attributes && formatLastLogin(extension_attributes.last_login),
      },
      {
        label: 'Account Lock',
        value: extension_attributes && extension_attributes.locked,
      },
      { label: 'Confirmed email', value: formatEmailStatus(customerStatus) },
      { label: 'Account Created', value: moment(customerDetail.created_at).format('MMM DD, YYYY hh:mm:ss A') },
      { label: 'Account Created in', value: customerDetail.created_in },
      { label: 'Customer Group', value: formatGroup(customerDetail.group_id) },
    ];
    return (
      <div className="bg-white px-4 py-4 wrapper h-100">
        <div className="d-flex">
          <div className="">
            <FontAwesomeIcon icon={faUser} className="fs-5 border rounded-circle icon" />
          </div>
          <div className="ms-4 w-100">
            <p className="person-info-title mt-2 mb-4">personal information</p>
            {infoFields.map((field) => renderPersonalInfoRow(field.label, field.value, field.label))}
          </div>
        </div>
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className="bg-white px-4 py-4 wrapper h-100">
        <div className="d-flex">
          <div className="">
            <FontAwesomeIcon icon={faDollarSign} className="fs-5 border rounded-circle icon-billing" />
          </div>
          <div className="ms-4 w-100">
            <p className="person-info-title mt-2 mb-4">billing address</p>
            {addresses.length > 0 && addresses.filter((item: any) => item.default_billing === true).length > 0 ? (
              addresses
                .filter((item: any) => item.default_billing === true)
                .map((item: any) => {
                  const infoFields = [
                    { label: 'Name', value: `${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}` },
                    {
                      label: 'Street Address',
                      value: item.street.map((itemAdds: string, index: number) =>
                        index === item.street.length - 1 ? ` ${itemAdds}` : `${itemAdds}, `,
                      ),
                    },
                    { label: 'City & Zip', value: `${item.city}${item.region.region ? `, ${item.region.region}` : ''}, ${item.postcode}` },
                    { label: 'Country', value: formatCountry(item.country_id) },
                    { label: 'Phone', value: item.telephone },
                  ];
                  return infoFields.map((field) => renderPersonalInfoRow(field.label, field.value, field.label));
                })
            ) : (
              <div className="row mb-3">
                <span className="fs-14 fw-medium">The customer does not have default billing address.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <AsyncSection status={status} className="row mt-4">
      <div className="col-12 col-lg-6">{renderInformation()}</div>
      <div className="col-12 col-lg-6">{renderAddress()}</div>
    </AsyncSection>
  );
};

export default Overview;
