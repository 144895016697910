import React from 'react';
import FullWidthLayout from '~/components/layout/FullWidthLayout';
import TransitPopup from './Transit';
import './style.scss';
interface Props {}

const Transit: React.FC = (props: Props) => {
  return (
    <FullWidthLayout pageTitle="Auth || Transit">
      <TransitPopup isLogin={false} />
    </FullWidthLayout>
  );
};

export default Transit;
