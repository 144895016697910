import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { PopupFailure, PopupSuccess } from '~/components/common/Popup';
import { actionClearMessage, actionReset, getPromotionId } from './redux/actions';
import { useParams } from 'react-router-dom';

interface Props {
  action: 'create' | 'edit';
  formik: any;
}

const RenderPopup = ({ action, formik }: Props) => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);

  const { status, message, idPromotionEdit } = useAppSelector((s) => s.createEditPromotionReducer);
  const navigate = useNavigate();
  const { idPromotion = 0 } = useParams();

  // // Add a state to control the visibility of the popup
  // const [isPopupOpen, setIsPopupOpen] = useState(true);

  function renderPopup() {
    if (status === 'fulfilled') {
      return (
        <PopupSuccess
          textLink="OK"
          message={`You have successfully ${action === 'create' ? 'created' : 'edited'} promotion`}
          onClickLink={() => {
            if (action === 'create') {
              // setTimeOut to eliminate ckeditor's error
              setTimeout(() => {
                navigate(`/promotion/edit/${idPromotionEdit}`);
                dispatch(actionClearMessage());
              }, 0);
              return;
            }
            dispatch(actionClearMessage());

            dispatch(actionReset());
            dispatch(getPromotionId(+idPromotion, currentStore));
            dispatch(actionClearMessage());
            // Close the popup when "OK" is clicked
          }}
        />
      );
    } else if (status === 'rejected') {
      return (
        <PopupFailure
          message={message}
          textLink="OK"
          onClickLink={() => {
            // Close the popup when "OK" is clicked
            dispatch(actionClearMessage());
          }}
        />
      );
    }
  }

  return <>{renderPopup()}</>;
};

export default RenderPopup;
