import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { timeConvert } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import note from '~/app/images/note.png';
import AsyncSection from '~/components/common/AsyncSection';
import { getNotesHistoty } from '../redux/actions';

const History: React.FC = () => {
  const dispatch = useAppDispatch();
  const { commentHistories, status } = useAppSelector((s) => s.orderReducer);
  const { accessToken } = useAppSelector((s) => s.authReducer);
  const { orderId = 0 } = useParams();

  useEffect(() => {
    const payload: { id: number; accessToken: string } = {
      id: +orderId,
      accessToken,
    };
    dispatch(getNotesHistoty(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOrderStatus = () => {
    return (
      <div className="bg-white wrapper p-4 h-100">
        <div className="fw-medium fs-16 notes-title">ORDER STATUS</div>
        <div className="schedule-wrapper pe-3 mt-4">
          {commentHistories.length ? (
            commentHistories.map((item: any, i: number) => {
              return (
                item.title && (
                  <div key={i} className={`schedule-item ps-4 pb-4`}>
                    <p className="schedule-item--title fs-16 fw-normal mb-2">{item.title}</p>
                    <p className="schedule-item--status mb-2 text-dark-600 fs-14">{item.notified === 1 ? 'Notified' : 'Customer Not Notified'}</p>
                    <p className="schedule-item--status mb-0 text-dark-600 fs-14">{timeConvert(item.created_at.date)}</p>
                  </div>
                )
              );
            })
          ) : (
            <div>
              <p className="schedule-item--title fw-medium">We couldn't find any records!</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderNotes = () => {
    return (
      <div className="bg-white wrapper p-4 h-100">
        <div className="fw-medium fs-16 notes-title">NOTES FOR THIS ORDER</div>
        <div className="notes-wrapper">
          {commentHistories.length ? (
            commentHistories.map((item: any, i: number) => {
              return (
                item.comment && (
                  <div key={i} className="ps-1 notes d-flex mb-4">
                    <div className="notes--icon p-3 me-3">
                      <img src={note} alt="" />
                    </div>
                    <div className="notes--content">
                      <p className="mb-0 fs-16 pe-3 fw-normal" dangerouslySetInnerHTML={{ __html: item.comment.replaceAll('\n', '<br/>') }} />
                      <p className="time-at-comment schedule-item--status mb-0 text-dark-600 fs-14 fst-italic">
                        Comment added {timeConvert(item.created_at.date)}
                      </p>
                    </div>
                  </div>
                )
              );
            })
          ) : (
            <div>
              <p className="schedule-item--title fw-medium">We couldn't find any records!</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <AsyncSection status={status} className="history-wrapper mt-4 ">
      <div className="row gap-4 gap-lg-0">
        <div className="col-12 col-lg-5">{renderOrderStatus()}</div>
        <div className="col-12 col-lg-7">{renderNotes()}</div>
      </div>
    </AsyncSection>
  );
};

export default History;
