import qs from 'qs';
import {
  conditionType,
  directionType,
  filterItemInterface,
  filterItemInterfacePromotion,
  filtersInterface,
  filtersInterfacePromotion,
  sortOrderItemInterface,
} from '../models';

export const addFilterItem = (field: string, value: string, condition_type: conditionType): filterItemInterface => ({
  field,
  value,
  condition_type,
});
export const addFilterItemPromotion = (field: string, value: string, conditionType: conditionType): filterItemInterfacePromotion => ({
  field,
  value,
  conditionType,
});

export const addSortOrderItem = (field: string, direction?: directionType): sortOrderItemInterface => ({
  field,
  direction,
});

export const addFilters = (...filters: filterItemInterface[]): filtersInterface => {
  return {
    filters,
  };
};
export const addFiltersPromotion = (...filters: filterItemInterfacePromotion[]): filtersInterfacePromotion => {
  return {
    filters,
  };
};

export const addFilterGroup = (...filter_groups: filtersInterface[]) => filter_groups;

export const addSortOrder = (...sort_orders: sortOrderItemInterface[]) => sort_orders;

export const searchCriteriaBuilder = (
  pageSize: number,
  currentPage: number,
  filter_groups?: filtersInterface[],
  sort_orders?: sortOrderItemInterface[],
) =>
  qs.stringify(
    {
      searchCriteria: {
        filter_groups,
        sort_orders,
        pageSize,
        currentPage,
      },
    },
    { arrayFormat: 'indices' },
  );

export const search_criteriaBuilder = (
  pageSize: number,
  currentPage: number,
  filterGroups?: filtersInterface[],
  sort_orders?: sortOrderItemInterface[],
) =>
  qs.stringify(
    {
      searchCriteria: {
        filterGroups,
        sort_orders,
        pageSize,
        currentPage,
      },
    },
    { arrayFormat: 'indices' },
  );

export const criteriaBuilder = (pageSize: number, currentPage: number, filter_groups?: filtersInterface[], sort_orders?: sortOrderItemInterface[]) =>
  qs.stringify(
    {
      criteria: {
        filter_groups,
        sort_orders,
        pageSize,
        currentPage,
      },
    },
    { arrayFormat: 'indices' },
  );

export const customerDetailCriteria = <T>(payload: T) =>
  qs.stringify({
    customerId: payload,
  });

export const reportSearchCriteria = <T>(payload: T) =>
  qs.stringify({
    searchCriteria: { ...payload },
  });

export const dashboardCriteria = <T>(payload: T) =>
  qs.stringify({
    criteria: { ...payload },
  });

export const exportCSV = <T>(payload: T) =>
  qs.stringify({
    ...payload,
  });
