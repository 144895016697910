import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

// Auth
import Auth from '~/components/pages/Auth';
import SignIn from '~/components/pages/Auth/SignIn';
// import SignUp from '~/components/pages/Auth/SignUp';
// import ForgotPassword from '~/components/pages/Auth/ForgotPassword';
// import ResetPassword from '~/components/pages/Auth/ResetPassword';

import PrivateRoute from '~/components/common/PrivateRoute';
import DashBoard from '~/components/pages/DashBoard';
import Order from '~/components/pages/Orders';
import Overview from '~/components/pages/Account/Overview';
import Setting from '~/components/pages/Account/Settings';
import Account from '~/components/pages/Account';
import NotFoundPage from '~/components/pages/NotFoundPage';
import OrderDetails from '~/components/pages/Orders/Details';
import Shipment from '~/components/pages/Orders/Details/Shipment';
import Information from '~/components/pages/Orders/Details/Information';
import Invoice from '~/components/pages/Orders/Details/Invoice';
import CreditMemo from '~/components/pages/Orders/Details/CreditMemo';
import SalesReport from '~/components/pages/SalesReport';
import ProductListing from '~/components/pages/ProductListing';
import Transit from '~/components/pages/Auth/Transit';
import CreateEditProduct from '~/components/pages/CreateEditProduct';
import History from '~/components/pages/Orders/Details/History';
import Customers from '~/components/pages/Customers';
import CustomerDetails from '~/components/pages/Customers/Details';
import CustomerInformation from '~/components/pages/Customers/Details/Information';
import CustomerOverview from '~/components/pages/Customers/Details/Overview';
import Cart from '~/components/pages/Customers/Details/Cart';
import Orders from '~/components/pages/Customers/Details/Orders';
import ProductReview from '~/components/pages/Customers/Details/ProductReview';
import Sellers from '~/components/pages/Seller';
// import SellerDetails from '~/components/pages/Seller/Details';
// import SellerInformation from '~/components/pages/Seller/Details/Information';
// import SellerOverview from '~/components/pages/Seller/Details/Overview';
// import SellerCart from '~/components/pages/Seller/Details/Cart';
// import SellerOrders from '~/components/pages/Seller/Details/Orders';
// import SellerReview from '~/components/pages/Seller/Details/ProductReview';
import GeneralReport from '~/components/pages/SalesReport/GeneralReport';
import TicketListing from '~/components/pages/TicketListing';
import TicketDetails from '~/components/pages/TicketListing/Details';
import { useAppSelector } from '~/app/hooks/hooks';
import PromotionListing from '~/components/pages/PromotionListing';
import CreateEditPromotion from '~/components/pages/CreateEditPromotion';
import ReviewListing from '~/components/pages/ReviewListing';
import BrandListing from '~/components/pages/BrandListing';
import CategoryListing from '~/components/pages/CategoryListing';
import ProductAttributes from '~/components/pages/ProductAttributes';

const AppRoutes: React.FC = () => {
  const { orderQueries: reportOrderQueries } = useAppSelector((s) => s.reportReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);
  const { queryString: promotionQuery } = useAppSelector((s) => s.promotionReducer);

  return (
    <Suspense>
      <Routes>
        {/* Public router */}
        <Route path="/auth" element={<Auth />}>
          <Route path="sign-in" element={<SignIn />} />
          {/* <Route path="sign-up" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} /> */}
          <Route path="transit" element={<Transit />} />
        </Route>

        {/* Private router */}
        <Route element={<PrivateRoute />}>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<DashBoard />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="orders/" element={<Order />} />
          <Route path="orders/:orderId" element={<OrderDetails />}>
            <Route path="information" element={<Information />} />
            <Route path="shipments" element={<Shipment />} />
            <Route path="shipments/create" element={<Information isCreateShipment={true} />} />
            <Route path="shipments/:entity_id" element={<Information isShippingInfo={true} />} />
            <Route path="invoice" element={<Invoice />} />
            <Route path="invoice/create" element={<Information isCreateInvoice={true} />} />
            <Route path="invoice/:entity_id" element={<Information isInvoiceInfo={true} />} />
            <Route path="credit-memos" element={<CreditMemo />} />
            <Route path="credit-memos/create" element={<Information isCreateCreditMemo={true} />} />
            <Route path="credit-memos/:entity_id" element={<Information isMemosInfo={true} />} />
            <Route path="history" element={<History />} />
          </Route>
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:customerId" element={<CustomerDetails />}>
            <Route path="information" element={<CustomerInformation />} />
            <Route path="overview" element={<CustomerOverview />} />
            <Route path="cart" element={<Cart />} />
            <Route path="product-review" element={<ProductReview />} />
            <Route path="customer-orders" element={<Orders />} />
          </Route>
          <Route path="/sellers" element={<Sellers />} />
          {/* <Route path="/sellers/details" element={<SellerDetails />}>
            <Route index element={<SellerInformation />} />
            <Route path="information" element={<SellerInformation />} />
            <Route path="overview" element={<SellerOverview />} />
            <Route path="cart" element={<SellerCart />} />
            <Route path="product-review" element={<SellerReview />} />
            <Route path="seller-orders" element={<SellerOrders />} />
          </Route> */}
          <Route path="/profile" element={<Account />}>
            <Route index element={<Navigate to="overview" />} />
            <Route path="overview" element={<Overview />} />
            <Route path="settings" element={<Setting />} />
          </Route>
          <Route path="/sales-report" element={<SalesReport />}>
            <Route index element={<Navigate to={`orders-report/${reportOrderQueries}`} />} />
            <Route path="orders-report" element={<GeneralReport type="order" />} />
            <Route path="customers-report" element={<GeneralReport type="customer" />} />
            <Route path="invoices-report" element={<GeneralReport type="invoice" />} />
            <Route path="products-report" element={<GeneralReport type="product" />} />
            <Route path="refunds-report" element={<GeneralReport type="refund" />} />
          </Route>
          <Route path="product" element={<Navigate to={`/products/${productQuery}`} />} />
          <Route path="/products" element={<ProductListing />} />
          <Route path="/product/create" element={<Outlet />}>
            <Route index element={<CreateEditProduct type="simple" action="create" />} />
            <Route path="simple" element={<CreateEditProduct type="simple" action="create" />} />
            <Route path="config" element={<CreateEditProduct type="config" action="create" />} />
          </Route>
          <Route path="/product/edit/:idProduct" element={<CreateEditProduct action="edit" />} />
          <Route path="tickets" element={<TicketListing />} />
          <Route path="tickets/:ticketId" element={<TicketDetails />} />
          <Route path="promotion" element={<Navigate to={`/promotions/${promotionQuery}`} />} />
          <Route path="promotions" element={<PromotionListing />} />
          <Route path="/promotion/create" element={<CreateEditPromotion action="create" />} />
          <Route path="/promotion/edit/:idPromotion" element={<CreateEditPromotion action="edit" />} />
          <Route path="/reviews" element={<ReviewListing />} />
          <Route path="/brands" element={<BrandListing />} />
          <Route path="/categories" element={<CategoryListing />} />
          <Route path="/product-attributes" element={<ProductAttributes />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
