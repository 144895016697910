import { iCategoryItem, iCategoryTreeItem, iCurrentAction } from '~/app/models';
import * as types from './index';
import {
  iCategoryItemPayload,
  iDataAddProductToCategoryId,
  iPayloadCreateCategory,
  iPayloadGetCategoryById,
  iPayloadSaveChangeProductsInCategory,
} from '~/app/models/category';
import categoryApi from '~/app/apis/category.api';
import { convertMessageError, swalToast } from '~/app/utils';
import { getCategoriesTree } from '../../CreateEditProduct/redux/actions';
import { AxiosResponse } from 'axios';
import { defaultMessageError } from '~/app/constants';

const { rest } = types;

export const createCategories = (payload: iPayloadCreateCategory) => async (dispatch: any) => {
  const { currentStore } = payload;
  dispatch(rest.createCategoryPending());
  try {
    const { data }: AxiosResponse<iCategoryItem> = await categoryApi.createCategory(payload);
    dispatch(rest.createCategoryFulfilled());

    // Show notification
    swalToast.fire({
      title: 'Save Successfully!',
      icon: 'success',
    });

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Change current action to edit
    dispatch(setFormDataCategory(data));
    dispatch(setCurrentAction('edit'));
    dispatch(setParentCategory(null));
    dispatch(
      getCategoriesTree({
        currentStore,
        rootCategoryId: 1,
      }),
    );
  } catch (error: any) {
    const message = convertMessageError(error);
    dispatch(rest.createCategoryRejected());

    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
  }
};

export const getCategoryById = (payload: iPayloadGetCategoryById) => async (dispatch: any) => {
  // Scroll to top
  window.scrollTo({ top: 0, behavior: 'smooth' });

  dispatch(rest.getCategoryByIdPending());
  try {
    const { data }: AxiosResponse<iCategoryItem> = await categoryApi.getCategoryById(payload);
    dispatch(setFormDataCategory(data));

    dispatch(rest.getCategoryByIdFulfilled());
    dispatch(setCurrentAction('edit'));
    dispatch(setParentCategory(null));
  } catch (error: any) {
    const message = convertMessageError(error);
    dispatch(rest.getCategoryByIdRejected());

    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
  }
};

export const editCategoryById = (payload: iPayloadGetCategoryById & { data: iCategoryItemPayload }) => async (dispatch: any) => {
  const { currentStore } = payload;
  dispatch(rest.editCategoryByIdPending());
  try {
    const { data } = await categoryApi.editCategoryById(payload);

    dispatch(rest.editCategoryByIdFulfilled());
    dispatch(setCurrentAction('edit'));

    // Show notification
    swalToast.fire({
      title: 'Edit Successfully!',
      icon: 'success',
    });

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Reset data
    dispatch(setFormDataCategory(data));

    dispatch(
      getCategoriesTree({
        currentStore,
        rootCategoryId: 1,
      }),
    );
  } catch (error: any) {
    const message = convertMessageError(error);
    dispatch(rest.editCategoryByIdRejected());

    // Show notification
    swalToast.fire({
      title: message,
      icon: 'error',
    });
  }
};

export const deleteCategoryById = (payload: iPayloadGetCategoryById) => async (dispatch: any) => {
  const { currentStore } = payload;
  dispatch(rest.deleteCategoryByIdPending());
  try {
    await categoryApi.deleteCategoryById(payload);

    dispatch(rest.deleteCategoryByIdFulfilled());

    // Show notification
    swalToast.fire({
      title: 'Delete Successfully!',
      icon: 'success',
    });

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Reset data
    dispatch(setCurrentAction('create'));
    dispatch(setFormDataCategory(null));
    dispatch(setParentCategory(null));
    dispatch(
      getCategoriesTree({
        currentStore,
        rootCategoryId: 1,
      }),
    );
  } catch (error: any) {
    dispatch(rest.deleteCategoryByIdRejected());

    // Show notification
    swalToast.fire({
      titleText: defaultMessageError,
      icon: 'error',
    });
  }
};

export const getProductsInCategoryById = (payload: iPayloadGetCategoryById) => async (dispatch: any) => {
  try {
    const { data }: AxiosResponse<iCategoryItem> = await categoryApi.getProductsInCategoryById(payload);
    const newListSku = Array.isArray(data) && data.length ? data.map((item) => item.sku) : [];

    dispatch(rest.setOldListSkuOfCategory(newListSku));
    dispatch(rest.setCurrentListSkuOfCategory(newListSku));
  } catch (error: any) {
    dispatch(rest.setOldListSkuOfCategory([]));
  } finally {
    dispatch(rest.setCurrentCategoryId(payload.id));
  }
};

export const saveChangeProductsInCategory =
  ({ listCreateNew, listDelete, currentStore, categoryId, handleClose }: iPayloadSaveChangeProductsInCategory) =>
  async (dispatch: any) => {
    dispatch(rest.saveChangeProductsInCategoryPending());
    try {
      const resAddProductToCategory = await Promise.allSettled(
        listCreateNew.map((data) =>
          categoryApi.addProductToCategoryId({
            currentStore,
            categoryId,
            data,
          }),
        ),
      );

      const resDeleteProductFromCategory = await Promise.allSettled(
        listDelete.map((sku) =>
          categoryApi.deleteProductFromCategoryId({
            categoryId,
            currentStore,
            sku,
          }),
        ),
      );

      const listSkuFailure: string[] = [];

      resAddProductToCategory
        .filter((item) => item.status === 'rejected')
        .forEach((item: any) => {
          const dataRequest = item?.reason?.config?.data;
          try {
            const dataParse: iDataAddProductToCategoryId = JSON.parse(dataRequest);
            const skuFailure = dataParse.productLink.sku;

            skuFailure && listSkuFailure.push(skuFailure);
          } catch (error) {
            console.log(error);
          }
        });

      resDeleteProductFromCategory
        .filter((item) => item.status === 'rejected')
        .forEach((item: any) => {
          const responseURL = item?.reason?.request?.responseURL || '';
          const skuFailure = responseURL.replaceAll(/.*products\//g, '');

          skuFailure && listSkuFailure.push(skuFailure);
        });

      const message = !!listSkuFailure.length ? `Your request encountered an error on SKU (${listSkuFailure.join(', ')}).` : 'Save Successfully!';

      // Close data before show notification
      handleClose();
      dispatch(
        getCategoriesTree({
          currentStore,
          rootCategoryId: 1,
        }),
      );

      // Show notification
      swalToast.fire({
        title: message,
        icon: !!listSkuFailure.length ? 'error' : 'success',
        timer: 3000,
      });
      dispatch(rest.saveChangeProductsInCategoryFulfilled());
    } catch (error: any) {
      const message = convertMessageError(error);

      // Show notification
      swalToast.fire({
        title: message,
        icon: 'error',
      });
      dispatch(rest.saveChangeProductsInCategoryRejected());
    }
  };

export const setUrlData = (payload: { pageSize: number; currentPage: number }) => (dispatch: any) => {
  dispatch(rest.setUrlData(payload));
};
export const setIsDESC = (isDESC: boolean) => (dispatch: any) => {
  dispatch(rest.setIsDESC(isDESC));
};

export const setKeySearch = (keySearch: string) => (dispatch: any) => {
  dispatch(rest.setKeySearch(keySearch));
};

export const setQueryString = (q: string) => (dispatch: any) => {
  dispatch(rest.setQueryString(q));
};

export const setCurrentAction = (newAction: iCurrentAction) => (dispatch: any) => {
  dispatch(rest.setCurrentAction(newAction));
};

export const setCurrentListSkuOfCategory = (newListSku: string[]) => (dispatch: any) => {
  dispatch(rest.setCurrentListSkuOfCategory(newListSku));
};

export const setOldListSkuOfCategory = (newListSku: string[] | null) => (dispatch: any) => {
  dispatch(rest.setOldListSkuOfCategory(newListSku));
};

export const setFormDataCategory = (newFormDataBrand: iCategoryItem | null) => (dispatch: any) => {
  dispatch(rest.setFormCategory(newFormDataBrand));
};

export const setParentCategory = (newParentItem: iCategoryTreeItem | null) => (dispatch: any) => {
  dispatch(rest.setParentCategory(newParentItem));
};

export const resetProductQueryString = () => (dispatch: any) => {
  dispatch(rest.resetQueryString());
};
