import { faChartSimple, faListSquares, faTicketSimple } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { convertGetPastDay, getPastDay } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import InfoTag from '~/components/common/InfoTag';
import { getDataOverview } from './redux/actions';

import './style.scss';

const Overview = () => {
  const dispatch = useAppDispatch();

  const { accessToken, currentStore } = useAppSelector((s) => s.authReducer);
  const { dataOverview, statusOverview: status } = useAppSelector((s) => s.dashboardReducer);
  const { queryString: orderQuery } = useAppSelector((s) => s.orderReducer);
  const { queryString: productQuery } = useAppSelector((s) => s.productReducer);
  const { queryString: customerQuery } = useAppSelector((s) => s.customerReducer);
  const { total_order, total_product, total_user } = dataOverview;
  const currentDate = convertGetPastDay(getPastDay(1)[0]);
  // const aYearAgo = convertGetPastDay(getPastDay(366)[365]);

  useEffect(() => {
    dispatch(
      getDataOverview({
        accessToken,
        currentStore,
        to: moment(new Date(currentDate)).format('YYYY-MM-DD'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore, accessToken]);

  const overviewData = useMemo(
    () => [
      {
        title: 'Total Products',
        subtitle: 'products',
        icon: faChartSimple,
        qty: total_product || 0,
        bgc: '#000',
        path: `/products/${productQuery}`,
      },
      {
        title: 'Total Orders',
        subtitle: 'orders',
        icon: faListSquares,
        qty: total_order || 0,
        bgc: '#ED5348',
        path: `/orders/${orderQuery}`,
      },
      {
        title: 'Total Customers',
        subtitle: 'customers',
        icon: faTicketSimple,
        qty: total_user || 0,
        bgc: '#FFB062',
        path: `/customers/${customerQuery}`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [total_product, total_order, total_user],
  );

  const renderOverview = () => {
    return overviewData.map((item, index) => {
      const { title, subtitle, icon, qty, bgc, path } = item;
      return (
        <div className="col-md-4" key={index}>
          <InfoTag title={title} icon={icon} qty={qty} subtitle={subtitle} bgc={bgc} status={status} path={path} />
        </div>
      );
    });
  };

  return <div className="overview row gy-3 flex-grow-1">{renderOverview()}</div>;
};

export default Overview;
