import Button from '~/components/common/Button';
import CheckboxRounded from '~/components/common/CheckboxRounded';
import { Input, TextArea } from '~/components/common/Input';
import uploadImg from '~/app/images/camera.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { convertImageBase64ToPayload, convertSize, slugify, swalToast } from '~/app/utils';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { convertBase64 } from '~/app/utils/convertBase64';
import { useFormik } from 'formik';
import { formBrandSchema } from './Schema';
import { defaultMessageError } from '~/app/constants';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { createNewBrand, editBrandById, setCurrentAction, setFormDataBrand } from './redux/action';
import { iDataCreateEditBrand } from '~/app/models';
import Select from '~/components/common/Select';

export interface FormDataBrand {
  status: boolean;
  title: string;
  description: string;
  image: string;
  size: number;
  image_name: string;
  image_type: string;
  option_id: string;
}

const CreateEditBrand = () => {
  const dispatch = useAppDispatch();
  const { formData, currentAction, statusCreateBrand, statusEditBrand, statusDeleteBrand, optionsBrand } = useAppSelector((s) => s.brandReducer);
  const { currentStore } = useAppSelector((s) => s.authReducer);
  const { storeData } = useAppSelector((s) => s.userReducer);

  useEffect(() => {
    handleCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    if (statusCreateBrand === 'fulfilled') return resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCreateBrand]);

  useEffect(() => {
    if (statusDeleteBrand === 'fulfilled') return resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDeleteBrand]);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, setValues, resetForm } =
    useFormik<FormDataBrand>({
      initialValues: {
        status: true,
        title: '',
        size: 0,
        image: '',
        description: '',
        image_name: '',
        image_type: '',
        option_id: '',
      },
      validationSchema: formBrandSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: handleSubmitForm,
    });

  useMemo(() => {
    if (!formData) return;

    const { description, image, status, title, size, option_id } = formData;
    const logo = image ? `${process.env.REACT_APP_IMAGE_URL || ''}/${image}` : '';
    const image_name = image?.split('/')?.reverse()[0];
    resetForm();
    setValues({
      image_type: '',
      status: !!status,
      description,
      image: logo,
      image_name,
      title,
      size,
      option_id: option_id?.toString() || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  function handleCancel() {
    resetForm();

    currentAction === 'edit' && dispatch(setCurrentAction('create'));
    formData && dispatch(setFormDataBrand(null));
  }

  function handleRemoveBrand() {
    setFieldValue('image', '');
    setFieldValue('size', 0);
    setFieldValue('image_name', '');
  }

  function handleSubmitForm(values: FormDataBrand) {
    const { description, image, image_name, status, title, image_type, option_id } = values;
    const stores = storeData.filter((store) => store.code === currentStore).map((store) => store.id);

    const newImage = image.startsWith('data:image/')
      ? {
          image: {
            base64_encoded_data: convertImageBase64ToPayload(image),
            name: image_name,
            type: image_type,
          },
        }
      : {};
    const id = currentAction === 'edit' ? { shopbrand_id: formData?.shopbrand_id || 0 } : {};
    const data: iDataCreateEditBrand = {
      brand: {
        ...id,
        ...newImage,
        description,
        status: +status,
        title,
        stores,
        option_id: option_id || null,
      },
    };

    if (currentAction === 'edit') {
      return dispatch(
        editBrandById({
          currentStore,
          data,
          id: formData?.shopbrand_id || 0,
        }),
      );
    }

    dispatch(
      createNewBrand({
        currentStore,
        data,
      }),
    );
  }

  function summaryBrandLogoName(brandLogoName: string) {
    if (typeof brandLogoName !== 'string' || brandLogoName.length < 20) return brandLogoName;

    const type = brandLogoName.split('.').reverse()[0];
    const name = brandLogoName.split('.').reverse().slice(1).reverse().join('');

    const newName = `${name.slice(0, 5)}...${name.slice(-3)}.${type}`;

    return newName;
  }

  function handleUploadBrand(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    async function convertBase64Image() {
      if (file) {
        const typeFileAccept = ['image/png', 'image/jpeg'];
        const { type, name } = file;

        if (!typeFileAccept.includes(type)) {
          swalToast.fire({
            title: "Your photos couldn't be uploaded. Photos should be saved as JPG, PNG, JPEG.",
            icon: 'error',
          });

          // Remove file when have error
          e.target.value = '';
          return;
        }

        try {
          // Change name
          const nameLengthAfterReplace = name.replace(/[&/\\#,+()$~%'":*?<>{}||@!^]/g, '').split('.')[0].length === 0;
          const fileType = name.split('.').reverse()[0];
          const newName = nameLengthAfterReplace ? `${values.title}-logo` : name.replace(/[&/\\#,+()$~%'":*?<>{}|@!^]/g, '').split('.')[0];

          const fileCovert = (await convertBase64(file)) as string;
          if (typeof fileCovert !== 'string') return;

          const y = fileCovert.endsWith('==') ? 2 : 1;
          const newSize = convertImageBase64ToPayload(fileCovert).length * 0.75 - y;

          errors.image && setFieldTouched('image', false);
          setFieldValue('image', fileCovert);
          setFieldValue('size', Math.round(newSize));
          setFieldValue('image_name', `${slugify(newName)}.${fileType}`);
          setFieldValue('image_type', type);
        } catch (error) {
          swalToast.fire({
            title: defaultMessageError,
            icon: 'error',
          });
        }
      }
    }
    convertBase64Image();
  }

  return (
    <div className="create-edit-form wrapper p-4 d-flex flex-column gap-4">
      <h3 className="fs-5 fw-semibold m-0">{currentAction === 'create' ? 'Add New Brand' : 'Edit Brand'}</h3>

      <div className="form-body d-flex flex-column gap-3">
        <div className="d-flex align-items-center justify-content-between">
          <label className="fs-14 cursor-pointer" htmlFor="enable">
            Enable
          </label>
          <CheckboxRounded check={values.status} onChange={handleChange} name="status" id="enable" />
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fs-14 cursor-pointer" htmlFor="title">
            Title <span className="text-danger">*</span>
          </label>
          <Input
            className="fs-14"
            value={values.title}
            id="title"
            name="title"
            type="text"
            onChange={(e) => {
              errors.title && setFieldTouched('title', false);
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {errors.title && touched.title && <span className="text-danger fs-7">{errors.title}</span>}
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fs-14 cursor-pointer" htmlFor="description">
            Brand
          </label>
          <Select className="w-100" name="option_id" value={values.option_id} onChange={handleChange}>
            {optionsBrand.map((item, i) => (
              <option value={item.value} key={i}>
                {item.label}
              </option>
            ))}
          </Select>
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="fs-14 cursor-pointer" htmlFor="description">
            Description
          </label>
          <TextArea value={values.description} className="h-150px fs-14" id="description" onChange={handleChange} onBlur={handleBlur} />
        </div>

        {values.image ? (
          <div className="preview-brand d-flex align-items-center gap-3 px-3 py-12">
            <img className="preview-brand-image flex-shrink-0" src={values.image} alt="brand-preview" />
            <div className="d-flex flex-column gap-2 flex-grow-1">
              <h3 className="fs-14 m-0">{summaryBrandLogoName(values.image_name)}</h3>
              <span className="fs-7">{convertSize(values.size)}</span>
            </div>
            <FontAwesomeIcon className="close-icon cursor-pointer" icon={faClose} onClick={handleRemoveBrand} />
          </div>
        ) : (
          <div className="d-flex flex-column gap-2">
            <label
              htmlFor="upload-brand"
              className="upload-brand d-flex flex-column align-items-center justify-content-center gap-2 p-3 cursor-pointer"
            >
              <input type="file" id="upload-brand" accept="image/png, image/gif, image/jpeg" hidden onChange={handleUploadBrand} />
              <img className="upload-img user-select-none pe-none" src={uploadImg} alt="upload-img" />
              <span className="fs-14 text-dark-200">Click here to change</span>
            </label>
            {errors.image && touched.image && <span className="text-danger fs-7">{errors.image}</span>}
          </div>
        )}
      </div>

      <div className="d-flex flex-column gap-3">
        <Button
          disabled={statusEditBrand === 'pending' || statusCreateBrand === 'pending'}
          className="w-100"
          onClick={handleSubmit}
          showLoadingRight={statusEditBrand === 'pending' || statusCreateBrand === 'pending'}
        >
          SAVE BRAND
        </Button>
        <Button disabled={statusEditBrand === 'pending' || statusCreateBrand === 'pending'} className="w-100" outline onClick={handleCancel}>
          CANCEL
        </Button>
      </div>
    </div>
  );
};

export default CreateEditBrand;
