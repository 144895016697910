import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import DefaultLayout from '~/components/layout/DefaultLayout';
import { getCategoriesTree } from '../CreateEditProduct/redux/actions';
import CategoriesTree from './CategoriesTree';

import './style.scss';
import CreateEditCategory from './CreateEditCategory';

const CategoryListing = () => {
  const dispatch = useAppDispatch();
  const { currentStore } = useAppSelector((s) => s.authReducer);

  useEffect(() => {
    dispatch(
      getCategoriesTree({
        currentStore,
        rootCategoryId: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  return (
    <DefaultLayout pageTitle="Categories">
      <div className="categories-page row h-100 gy-4">
        <div className="col-12 col-xl-4 col-xxl-5 h-xl-100">
          <CreateEditCategory />
        </div>
        <div className="col-12 col-xl-8 col-xxl-7 h-100">
          <CategoriesTree />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CategoryListing;
