import { useAppSelector } from '~/app/hooks/hooks';
import {
  IDateInputProps,
  IDateRangeFilterProps,
  INumberFilterProps,
  ITextFilterProps,
  Props,
  ResetFilter,
  SelectFilterProps,
  WebsiteFilterProps,
} from '~/app/models/filter';
import { Input } from '../Input';
import Select from '../Select';

const regex = /^[\d\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/; //only allow number and special character

const NumberFromToFilter: React.FC<INumberFilterProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-price-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12 d-flex align-items-center">
          <span className="col-2 text-dark fw-normal fs-14">from</span>
          <Input className="w-100" type="number" value={value[`${name}From`]} name={`${name}From`} onChange={onChange} onlyInteger />
        </div>
        <div className="col-lg-12 d-flex align-items-center mt-3">
          <span className="col-2 text-dark fw-normal fs-14">to</span>
          <Input className="w-100" type="number" value={value[`${name}To`]} name={`${name}To`} onChange={onChange} onlyInteger />
        </div>
      </div>
    </div>
  );
};

const TextFilter: React.FC<ITextFilterProps> = ({ label, name, value, onChange, filterClass, regexEnable }) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const char = event.currentTarget.value + event.key;
    const isValidChar = regex.test(char);
    if (!isValidChar) {
      event.preventDefault();
    }
  };

  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-name-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Input
            className="w-100"
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            {...(regexEnable ? { onKeyPress: handleKeyPress } : {})}
          />
        </div>
      </div>
    </div>
  );
};

const NumberFilter: React.FC<ITextFilterProps> = ({ label, name, value, onChange, filterClass }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-name-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Input className="w-100" type="number" value={value} name={name} onChange={onChange} onlyInteger />
        </div>
      </div>
    </div>
  );
};

const SelectFilter: React.FC<SelectFilterProps> = ({ label, name, options, value, onChange, filterClass, optionNull }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-status-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12">
          <Select className="w-100" name={name} value={value} onChange={onChange}>
            {optionNull && <option value=""></option>}
            {options.length > 0 &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

const DateRangeFilter: React.FC<IDateRangeFilterProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-price-filter">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          {label}
        </label>
        <div className="col-lg-12 d-flex align-items-center">
          <span className="col-2 text-dark fw-normal fs-14">from</span>
          <Input className="w-100" type="date" name={`${name}From`} value={value[`${name}From`]} onChange={onChange} />
        </div>
        <div className="col-lg-12 d-flex align-items-center mt-3">
          <span className="col-2 text-dark fw-normal fs-14">to</span>
          <Input className="w-100" type="date" name={`${name}To`} value={value[`${name}To`]} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

const DateInput: React.FC<IDateInputProps> = ({ filterClass, label, name, value, onChange }) => {
  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <label htmlFor="" className="w-fit-content fw-medium text-dark pb-2 fs-14">
        {label}
      </label>
      <div className="row flex-column gap-3 flex-lg-row gap-lg-0">
        <div className="col-6">
          <div className="row align-items-center flex-nowrap">
            <span className="text-lg-end fs-14 text-dark-600 col-3 col-xxl-2">from</span>
            <Input
              type="date"
              name={`${name}_from`}
              className="w-unset flex-grow-1 col-9 col-xxl-10"
              value={value[`${name}_from`] || ''}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row align-items-center flex-nowrap">
            <span className="text-lg-end fs-14 text-dark-600 col-3 col-xxl-2">to</span>
            <Input
              type="date"
              name={`${name}to`}
              className="w-unset flex-grow-1 col-9 col-xxl-10"
              value={value[`${name}to`] || ''}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterButtons: React.FC<Props> = ({ onSubmit, status, setIsEnableFilter }) => {
  return (
    <div className="action mt-4 me-2">
      <button
        className="btn-apply fs-14 btn-border-radius border-rd-25 text-white btn btn-primary fw-medium"
        onClick={onSubmit}
        disabled={status === 'pending'}
      >
        Apply Filters
      </button>

      <button
        className="btn-cancel btn-border-radius border-rd-25 cursor-pointer ms-2 fw-light border-0 fs-14 fw-medium"
        onClick={() => setIsEnableFilter(false)}
      >
        Cancel
      </button>
    </div>
  );
};

const FilterWebsite: React.FC<WebsiteFilterProps> = ({ filterClass, value, onChange, name }) => {
  const { data: userData } = useAppSelector((s) => s.userReducer);
  const { website_ids } = userData;
  const websiteList = JSON.parse(website_ids || '{}');
  const convertWebsiteList = Object.keys(websiteList).map((key) => websiteList[key]);

  return (
    <div className={`col-sm-12 ${filterClass}`}>
      <div className="product-type-filter ps-1">
        <label htmlFor="" className="col-lg-10 fw-medium text-dark pb-2 fs-14">
          Website
        </label>
        <div className="col-lg-12">
          <select
            className="form-select form-control form-control-custom w-100 fs-16"
            name={name ? name : 'website'}
            onChange={(e: any) => onChange(e)}
            value={value}
          >
            <option value=""></option>
            {convertWebsiteList.length > 0 &&
              convertWebsiteList.map((item: any, i: number) => {
                return (
                  <option value={i + 1} key={i}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </div>
  );
};

const ResetFilterButton: React.FC<ResetFilter> = ({ onClick }) => {
  return (
    <button className="btn btn-border-radius border-rd-25 bg-primary fw-medium fs-14 text-uppercase" onClick={onClick}>
      Reset Filters
    </button>
  );
};
export { NumberFromToFilter, NumberFilter, TextFilter, SelectFilter, DateRangeFilter, FilterWebsite, FilterButtons, ResetFilterButton, DateInput };
