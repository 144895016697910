import { useAppDispatch, useAppSelector } from '~/app/hooks/hooks';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { getCateList, onChangeDataHeader } from '../redux/actions';
import { Input } from '~/components/common/Input';
import Select from '~/components/common/Select';
import { MultiSelectTree } from '~/components/common/MultiSelect';
import {
  searchCriteriaBuilder,
  addFilterItem,
  addFilterGroup,
  addFilters,
  getWebsiteId,
  addSortOrder,
  filterEmptyProperties,
  convertKeyToLabelOfPromotionFeature,
} from '~/app/utils';
import './style.scss';
import { iFormik } from '..';
import { getCategoriesTree, onChangeIdCategoriesSelected } from '../../CreateEditProduct/redux/actions';
import { dataProductHead, dataProductHeadForSkuOfPromotion } from '~/app/constants';

import Button from '~/components/common/Button';
import { PopupWrap } from '~/components/common/Popup';
import { Table } from '~/components/common/Table';
import { NoRecord } from '../../Err';

import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';

import { formatType } from '~/components/common/Filter/formatFilter';
import CustomCheckBox from '~/components/common/CustomCheckBox';
import Pagination from '~/components/common/Pagination';
import { getData, setIsDESC, setKeySearch } from '../../ProductListing/redux/action';
import { convertStringToNumber } from '~/app/utils/convertBase64';
import { iGetAllProduct } from '~/app/models';
import { sorterCriteria } from '~/app/utils/sorterCriteria';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { builderCriteriaSkuTable } from '~/app/utils/builderCriteria';
import closeCirImg from '~/app/images/closeCircle.svg';
import { useClickOutside } from '~/app/hooks/useClickOutSide';

interface Props {
  formik: FormikProps<iFormik>;
}

const Header: FC<Props> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { accessToken, currentStore } = useAppSelector((state) => state.authReducer);

  const { dataCatePromotion, dataHeader, data, statusGetCateList } = useAppSelector((state) => state.createEditPromotionReducer);
  const { dataCateTree, idCategoriesSelected, statusGetCategoriesTree } = useAppSelector((state) => state.createEditProductReducer);
  const { apply_to, simple_action, value_sku, discount_amount, discount_step, value_sub_total, discount_mode } = dataHeader;

  const [query, setQuery] = useState<string>('');
  const [categoriesSelected, setCategoriesSelected] = useState<any[]>([]);
  const [oldCateListSelect, setOldCateListSelect] = useState<any[] | null>(null);

  const [oldOtherField, setOldOtherField] = useState({});
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [check, setCheck] = useState<any>(idCategoriesSelected);

  const contentRef = useRef<HTMLDivElement>(null);

  useClickOutside(contentRef, () => {
    setShowPopup(false);
    setIsChangeCallSku(false);
  });

  useEffect(() => {
    setCategoriesSelected(() => {
      const categoriesSelected = check?.map((item: any) => {
        return dataCatePromotion.find((cate: any) => cate.id.toString() === item);
      });
      const result = categoriesSelected?.filter(Boolean);

      return result;
    });

    dispatch(onChangeIdCategoriesSelected(check));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);

  useEffect(() => {
    if (!Object.keys(data).length || !dataCatePromotion.length) return;
    const { action_serialized } = data;
    const { conditions = [] } = action_serialized || {};
    //find category
    const categorySelected = conditions.find((item) => item.attribute_name === 'category_ids');
    if (categorySelected) {
      const listIdCategoriesSelect = categorySelected.value.split(',').map((item: string) => +item);
      const arrayListCate = dataCatePromotion.filter((item) => listIdCategoriesSelect.includes(item.id));
      const newListIdCateSelect = arrayListCate.map((item) => item.id.toString());
      setOldCateListSelect(newListIdCateSelect);
      setCheck(newListIdCateSelect);
      // dispatch(onChangeListCateSelected(newListCateSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCatePromotion]);

  //for sku listing
  const { data: dataProductList, status: productStatus, message, isDESC, keySearch } = useAppSelector((state) => state.productReducer);
  const { items, total_count } = dataProductList;

  const [pagination, setPagination] = useState({ pageSize: 10, currentPage: 1 });

  const [websiteId, setWebsiteId] = useState<number>();
  const { storeData } = useAppSelector((state) => state.userReducer);
  const sort = sorterCriteria(keySearch, isDESC);

  // Change Page Size Event
  const onChangePageSizeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPagination((pre) => ({ ...pre, pageSize: +value || 10 }));
    setIsFitler(true);
  };

  // Change current Page
  const onChangePage = (currentPage: number) => {
    setPagination((pre) => ({ ...pre, currentPage }));
    setIsFitler(true);
  };

  // const { discount_qty } = dataHeader;
  const { handleBlur, errors, touched } = formik;

  useEffect(() => {
    setIsFirstMount(false);
  }, []);

  useEffect(() => {
    if (!Object.keys(data).length) return;

    const { discount_amount, action_serialized, condition_serialized, discount_step, simple_action } = data;
    const { conditions } = action_serialized || {};

    const skuSelected = conditions?.find((item) => item.attribute_name === 'sku');
    const subtotalObj = condition_serialized.conditions?.find((item) => item.attribute_name === 'base_subtotal_total_incl_tax');

    const buyXGetOff = conditions?.find((item) => item.attribute_name === 'quote_item_qty');

    //find sku
    const categorySelected = conditions?.find((item) => item.attribute_name === 'category_ids');

    if (simple_action === 'by_percent' && !buyXGetOff) {
      let oldData: any = { discount_amount, apply_to: 'order_total' };

      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      if (skuSelected || categorySelected) {
        oldData = { ...oldData, apply_to: 'order_line_item' };
      }
      if (subtotalObj) {
        const value_sub_total = subtotalObj.value;
        oldData = { ...oldData, value_sub_total };
      }
      setOldOtherField(oldData);
      return;
    }

    if (buyXGetOff) {
      let oldData: any = { discount_amount, discount_mode: simple_action === 'cart_fixed' ? 'cart_fixed' : 'by_percent' };
      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      if (skuSelected || categorySelected) {
        oldData = { ...oldData, apply_to: 'order_line_item' };
      }
      setOldOtherField(oldData);
      return;
    }

    if (simple_action === 'free_shipping') {
      let oldData: any = { apply_to: 'order_total' };

      //find sku
      const categorySelected = conditions?.find((item) => item.attribute_name === 'category_ids');

      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      if (skuSelected || categorySelected) {
        oldData = { ...oldData, apply_to: 'order_line_item' };
      }
      if (subtotalObj) {
        const value_sub_total = subtotalObj.value;
        oldData = { ...oldData, value_sub_total };
      }
      setOldOtherField(oldData);
      return;
    }

    if (simple_action === 'buy_x_get_y') {
      let oldData: any = { discount_amount, discount_step, value_sku: skuSelected?.value };
      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      setOldOtherField(oldData);
      return;
    }

    if (simple_action === 'cart_fixed') {
      let oldData: any = { discount_amount, apply_to: 'order_total' };

      //find sku
      const categorySelected = conditions?.find((item) => item.attribute_name === 'category_ids');

      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      if (skuSelected || categorySelected) {
        oldData = { ...oldData, apply_to: 'order_line_item' };
      }
      if (subtotalObj) {
        const value_sub_total = subtotalObj.value;
        oldData = { ...oldData, value_sub_total };
      }
      setOldOtherField(oldData);
      return;
    }

    if (simple_action === 'buy_one_get_one_off') {
      // let oldData: any = { discount_amount, discount_step, value_sku: skuSelected?.value, apply_to };
      // if (skuSelected) {
      //   oldData = { ...oldData, value_sku: skuSelected.value };
      // }
      // setOldOtherField(oldData);
      // return;
      let oldData: any = { discount_amount, discount_step, value_sku: skuSelected?.value, apply_to, discount_mode };
      if (skuSelected) {
        oldData = { ...oldData, value_sku: skuSelected.value };
      }
      setOldOtherField(oldData);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isFirstMount || !Object.keys(data).length) return;
    const buyXGetOff = data.action_serialized.conditions?.find((item) => item.attribute_name === 'quote_item_qty');

    let oldSimpleAction = simple_action;

    if (simple_action === 'by_percent' && data.simple_free_shipping === '1' && !buyXGetOff) {
      oldSimpleAction = 'free_shipping';
    }
    if (buyXGetOff) {
      oldSimpleAction = 'buy_one_get_one_off';
    }

    if (simple_action === oldSimpleAction) {
      dispatch(onChangeDataHeader({ ...dataHeader, ...oldOtherField }));

      setCheck(oldCateListSelect);
      // dispatch(onChangeListCateSelected(oldCateListSelect ? oldCateListSelect : []));
    } else {
      const newDataHeader: any = { ...dataHeader, apply_to: 'order_total' };
      delete newDataHeader.discount_amount;
      delete newDataHeader.value_sku;
      delete newDataHeader.value_sub_total;
      delete newDataHeader.discount_step;

      dispatch(onChangeDataHeader(newDataHeader));
      // dispatch(onChangeListCateSelected([]));
      setCheck([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simple_action]);

  // useEffect(() => {
  //   if (simple_action !== 'buy_x_get_y') return;
  //   const newData = { ...dataHeader };

  //   dispatch(onChangeDataHeader(newData));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [simple_action]);

  useEffect(() => {
    if (apply_to !== 'order_line_item') return;
    const newData = { ...dataHeader };
    // delete newData.value_sub_total;
    dispatch(onChangeDataHeader(newData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply_to]);

  useEffect(() => {
    if (
      (simple_action === 'free_shipping' || apply_to === 'order_line_item' || simple_action === 'buy_x_get_y') &&
      statusGetCateList !== 'fulfilled'
    ) {
      let shouldLoading = false;

      if (Object.keys(data).length) {
        const { action_serialized } = data;
        const { conditions = [] } = action_serialized || {};
        //find category
        const categorySelected = conditions.find((item) => item.attribute_name === 'category_ids');

        if (categorySelected) {
          shouldLoading = true;
        }
      }
      dispatch(getCategoriesTree({ currentStore, onlyActive: true }));
      dispatch(getCateList(searchCriteriaBuilder(Number.MAX_VALUE, 1), accessToken, currentStore, shouldLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simple_action, apply_to]);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    let newData = { ...dataHeader, [name]: value };

    dispatch(onChangeDataHeader(newData));
  };

  // related to choose sku when create/edit promotion

  const [showPopup, setShowPopup] = useState(false);

  const [isChangeCallSKu, setIsChangeCallSku] = useState(false);

  function RenderShowSKU() {
    return (
      <div>
        <div className="ms-2 flex-shrink-0">
          <Button
            onClick={() => {
              setOldValueSKU(dataHeader.value_sku || '');
              setIsChangeCallSku(true);
            }}
          >
            Add SKUs
          </Button>
        </div>
      </div>
    );
  }

  function renderCategories() {
    return (
      <div className="categories-coupon">
        <MultiSelectTree
          options={
            query
              ? dataCatePromotion.filter(
                  (item) => item.name.toLowerCase().includes(query.toLowerCase().trim()) && !check.includes(item.id.toString()),
                )
              : JSON.parse(
                  JSON.stringify(dataCateTree)
                    .replace(/,"children_data":\[\]/g, '')
                    .replace(/"id"/g, '"value"')
                    .replace(/"name"/g, '"label"')
                    .replace(/"children_data"/g, '"children"'),
                )
          }
          status={statusGetCategoriesTree}
          listSelected={categoriesSelected || []}
          check={check}
          setCheck={setCheck}
          expanded={expanded}
          query={query}
          setQuery={setQuery}
          setExpanded={setExpanded}
          setListSelected={setCategoriesSelected}
        />
      </div>
    );
  }

  function renderAttrSet() {
    return (
      <div className="d-flex align-items-center w-100">
        <label htmlFor="discount-type" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
          Discount Type
        </label>
        <div className="flex-grow-1 w-100">
          <Select
            name="simple_action"
            value={simple_action}
            onChange={handleChange}
            className="select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
          >
            <option value="by_percent">% Discount</option>
            <option value="free_shipping">Free Shipping</option>
            <option value="buy_x_get_y">Buy X Get Y</option>
            <option value="cart_fixed">Fixed Amount Discount</option>
            <option value="buy_one_get_one_off">Buy One Get One Off</option>
          </Select>
        </div>
      </div>
    );
  }

  function renderContentPercent() {
    return (
      <>
        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Apply To
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="apply_to"
              value={apply_to}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="order_total">Order Total</option>
              <option value="order_line_item">Order Line Item</option>
            </Select>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-4 align-items-start discount-amount-subtotal">
          <div className="d-flex w-fit-content">
            <label htmlFor="discount_amount" className="title title-validate text-end fw-normal m-0 me-4 cursor-pointer">
              Discount Amount <span className="text-danger">*</span>
            </label>
            <div className="attr-set-wrap input-type">
              <div className="d-flex flex-column gap-2">
                <Input
                  type="number"
                  id="discount_amount"
                  name="discount_amount"
                  className="form-input--custom large"
                  value={discount_amount}
                  onChange={(e) => {
                    handleChange(e);
                    formik.handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                {touched.discount_amount && errors.discount_amount ? (
                  <div className="error-message validate-amount">{errors.discount_amount}</div>
                ) : null}
              </div>
            </div>
          </div>
          {/* change apply to */}
          {/* {apply_to === 'order_total' && ( */}
          <div className="d-flex align-items-center w-fit-content ">
            <label htmlFor="value_sub_total" className="title-subtotal text-end fw-normal m-0 me-4 cursor-pointer">
              Sub Total
            </label>
            <div className="attr-set-wrap input-type">
              <Input
                type="number"
                id="value_sub_total"
                name="value_sub_total"
                className="form-input--custom large"
                value={value_sub_total}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        {apply_to === 'order_total' ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <label htmlFor="sku" className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">
                SKUs
              </label>
              <Input
                type="search"
                id="sku"
                name="value_sku"
                className=" coupon-res-input-sku fs-16"
                disabled={false}
                value={value_sku}
                onChange={handleChange}
              />
              <div className="flex-shrink-0"> {RenderShowSKU()}</div>
            </div>
            <div className="d-flex align-items-center">
              <h3 className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">Categories</h3>
              {renderCategories()}
            </div>
          </>
        )}
      </>
    );
  }

  function renderContentFree() {
    return (
      <>
        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Apply To
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="apply_to"
              value={apply_to}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="order_total">Order Total</option>
              <option value="order_line_item">Order Line Item</option>
            </Select>
          </div>
        </div>
        {apply_to === 'order_total' ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <label htmlFor="sku" className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">
                SKUs
              </label>
              <Input
                type="search"
                id="sku"
                name="value_sku"
                className="fs-16 coupon-res-input-sku"
                disabled={false}
                value={value_sku}
                onChange={handleChange}
              />
              <div className="flex-shrink-0"> {RenderShowSKU()}</div>
            </div>
            <div className="d-flex align-items-center">
              <label className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer" htmlFor="categories">
                Categories
              </label>
              {renderCategories()}
            </div>
          </>
        )}
      </>
    );
  }

  // function renderMultiSelect() {
  //   return (
  //     <div className="categories-coupon">
  //       <MultiSelect
  //         removeSearchWhenSelected
  //         status={statusGetCateList}
  //         options={dataCatePromotion.filter((item) => item.name.toLowerCase().includes(query.toLowerCase().trim()))}
  //         listSelected={dataCateSelected}
  //         query={query}
  //         setQuery={setQuery}
  //         onRemoveAllItem={() => {
  //           dispatch(onChangeListCateSelected([]));
  //         }}
  //         onSelectItem={(item) => {
  //           dispatch(onChangeListCateSelected([...dataCateSelected, item]));
  //         }}
  //         onRemoveItem={(itemRemove) => {
  //           dispatch(onChangeListCateSelected(dataCateSelected.filter((item: any) => item.id !== itemRemove.id)));
  //         }}
  //       />
  //     </div>
  //   );
  // }

  function renderContentBuyXGetY() {
    return (
      <>
        {/* <div className="d-flex align-items-center">
          <label htmlFor="discount_qty" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
            Max Qty Discount Applied to
          </label>
          <Input type="text" id="discount_qty" name="discount_qty" className="coupon-res-input" value={discount_qty} onChange={handleChange} />
          <div className="attr-set-wrap flex-grow-1">
          </div>
        </div> */}

        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Apply To
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="apply_to"
              value={apply_to}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="order_total">Order Total</option>
              <option value="order_line_item">Order Line Item</option>
            </Select>
          </div>
        </div>
        {apply_to === 'order_total' ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <label htmlFor="sku" className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">
                SKUs
              </label>
              <Input
                type="search"
                id="sku"
                name="value_sku"
                className=" coupon-res-input-sku fs-16"
                disabled={false}
                value={value_sku}
                onChange={handleChange}
              />
              <div className="flex-shrink-0"> {RenderShowSKU()}</div>
            </div>
            <div className="d-flex align-items-center">
              <label className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer" htmlFor="categories">
                Categories
              </label>
              {renderCategories()}
            </div>
          </>
        )}
        <div>
          <div className="d-flex align-items-center ">
            <label htmlFor="name" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
              Discount Amount <span className="text-danger">*</span>
            </label>
            <div className="d-flex flex-column flex-grow-1">
              <Input
                type="text"
                id="discount_amount"
                name="discount_amount"
                className="coupon-res-input"
                value={discount_amount}
                onChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {touched.discount_amount && errors.discount_amount ? <div className="error-message validate-coupon">{errors.discount_amount}</div> : null}
        </div>
        {/*  */}
        <div className="d-flex align-items-center  ">
          <label htmlFor="discount_step" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
            Discount Qty Step (Buy X)
          </label>
          <Input type="text" id="discount_step" name="discount_step" className="coupon-res-input" value={discount_step} onChange={handleChange} />
          {/* <div className="attr-set-wrap flex-grow-1">
          </div> */}
        </div>
      </>
    );
  }

  function renderContentFixedAmount() {
    return (
      <>
        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Apply To
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="apply_to"
              value={apply_to}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="order_total">Order Total</option>
              <option value="order_line_item">Order Line Item</option>
            </Select>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-4 align-items-start discount-amount-subtotal">
          <div className="d-flex w-fit-content">
            <label htmlFor="discount_amount" className="title title-validate text-end fw-normal m-0 me-4 cursor-pointer">
              Discount Amount <span className="text-danger">*</span>
            </label>
            <div className="attr-set-wrap input-type">
              <div className="d-flex flex-column gap-2">
                <Input
                  type="number"
                  id="discount_amount"
                  name="discount_amount"
                  className="form-input--custom large"
                  value={discount_amount}
                  onChange={(e) => {
                    handleChange(e);
                    formik.handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                {touched.discount_amount && errors.discount_amount ? (
                  <div className="error-message validate-amount">{errors.discount_amount}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center w-fit-content ">
            <label htmlFor="value_sub_total" className="title-subtotal text-end fw-normal m-0 me-4 cursor-pointer">
              Sub Total
            </label>
            <div className="attr-set-wrap input-type">
              <Input
                type="number"
                id="value_sub_total"
                name="value_sub_total"
                className="form-input--custom large"
                value={value_sub_total}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        </div>
        {apply_to === 'order_total' ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <label htmlFor="sku" className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">
                SKUs
              </label>
              <Input
                type="search"
                id="sku"
                name="value_sku"
                className=" coupon-res-input-sku fs-16"
                disabled={false}
                value={value_sku}
                onChange={handleChange}
              />
              <div className="flex-shrink-0"> {RenderShowSKU()}</div>
            </div>
            <div className="d-flex align-items-center">
              <h3 className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">Categories</h3>
              {renderCategories()}
            </div>
          </>
        )}
      </>
    );
  }

  function renderContentBuyXGetOneOff() {
    return (
      <>
        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Apply To
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="apply_to"
              value={apply_to}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="order_total">Order Total</option>
              <option value="order_line_item">Order Line Item</option>
            </Select>
          </div>
        </div>
        {apply_to === 'order_total' ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center">
              <label htmlFor="sku" className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer">
                SKUs
              </label>
              <Input
                type="search"
                id="sku"
                name="value_sku"
                className=" coupon-res-input-sku fs-16"
                disabled={false}
                value={value_sku}
                onChange={handleChange}
              />
              <div className="flex-shrink-0"> {RenderShowSKU()}</div>
            </div>
            <div className="d-flex align-items-center">
              <label className="title text-end fw-normal m-0 me-4 fs-16 cursor-pointer" htmlFor="categories">
                Categories
              </label>
              {renderCategories()}
            </div>
          </>
        )}

        <div className="d-flex align-items-center w-100">
          <label htmlFor="coupon" className="title text-end fw-normal m-0 me-4 cursor-pointer">
            Discount Mode
          </label>
          <div className="flex-grow-1 w-100">
            <Select
              name="discount_mode"
              value={discount_mode}
              onChange={handleChange}
              className="select form-control cursor-pointer fw-normal text-truncate select form-control cursor-pointer fw-normal text-truncate coupon-res-input large w-100"
            >
              <option value="by_percent">Percent</option>
              <option value="cart_fixed">Fixed</option>
            </Select>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center ">
            <label htmlFor="name" className="title text-end fw-normal m-0 me-4 fs-16 flex-shrink-0 cursor-pointer">
              Discount Amount <span className="text-danger">*</span>
            </label>
            <div className="d-flex flex-column flex-grow-1">
              <Input
                type="text"
                id="discount_amount"
                name="discount_amount"
                className="coupon-res-input"
                value={discount_amount}
                onChange={(e) => {
                  handleChange(e);
                  formik.handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {touched.discount_amount && errors.discount_amount ? <div className="error-message validate-coupon">{errors.discount_amount}</div> : null}
        </div>
      </>
    );
  }

  function renderContent() {
    switch (simple_action) {
      case 'by_percent':
        return renderContentPercent();
      case 'free_shipping':
        return renderContentFree();
      case 'buy_x_get_y':
        return renderContentBuyXGetY();
      case 'cart_fixed':
        return renderContentFixedAmount();
      case 'buy_one_get_one_off':
        return renderContentBuyXGetOneOff();
      default:
        return null;
    }
  }

  // show table choose table sku

  const [oldValueSKU, setOldValueSKU] = useState('');

  const value_sku_arr = dataHeader.value_sku ? dataHeader.value_sku.split(', ') : [];

  const [showFilterSku, setShowFilterSku] = useState(false);

  const handleShowFilterSKU = () => {
    setShowFilterSku(!showFilterSku);
  };

  const initValue = useMemo(() => {
    return {
      sku_in: '',
      name: '',
      sku: '',
      type_id: '',
      attribute_set_id: '',
    };
  }, []);

  const [filterPayload, setFitlerPayload] = useState(initValue);

  const [submitFilterPayload, setSubmitFitlerPayload] = useState(initValue);
  const [isFilter, setIsFitler] = useState(false);

  const isCheckAllCurrentPage = useMemo(() => {
    if (!items.length) return false;

    const arrSkuSelect = dataHeader.value_sku ? dataHeader.value_sku.split(', ') : [];
    const arrSKUCurrentPage = items.map((item) => item.sku);

    const isCheckAllCurrentPage = arrSKUCurrentPage.every((item) => arrSkuSelect.includes(item));
    return isCheckAllCurrentPage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHeader.value_sku]);

  const resetFilter = () => {
    setFitlerPayload(initValue);
    setSubmitFitlerPayload(initValue);
    !isFilter && setIsFitler(true);
  };

  const payload: iGetAllProduct = {
    accessToken,
    searchUrl: searchCriteriaBuilder(
      convertStringToNumber(pagination.pageSize.toString(), 10),
      convertStringToNumber(pagination.currentPage.toString(), 1),
      addFilterGroup(addFilters(addFilterItem('website_id', `${websiteId}`, 'eq'))),
      addSortOrder(...sort),
    ),
    currentStore,
  };

  useMemo(() => {
    setIsFitler(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDESC, keySearch]);

  useEffect(() => {
    if (!isFilter || !websiteId) return;
    const filter = builderCriteriaSkuTable(submitFilterPayload);
    const payloadSKU: iGetAllProduct = {
      accessToken,
      searchUrl: searchCriteriaBuilder(
        convertStringToNumber(pagination.pageSize.toString(), 10),
        convertStringToNumber(pagination.currentPage.toString(), 1),
        addFilterGroup(...filter, addFilters(addFilterItem('website_id', `${websiteId}`, 'eq'))),
        addSortOrder(...sort),
      ),
      currentStore,
    };
    dispatch(getData(payloadSKU));

    setIsFitler(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilter, pagination, submitFilterPayload, websiteId]);

  // Declare useEffect
  useEffect(() => {
    setWebsiteId(getWebsiteId(storeData, currentStore));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData, currentStore]);

  useEffect(() => {
    if (!isChangeCallSKu) return;

    dispatch(getData(payload));
    setShowPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeCallSKu]);

  function handleChangeTableFilter(e: any) {
    const { value, name } = e.target;
    setFitlerPayload((prev) => ({ ...prev, [name]: value }));
  }

  const sortOrder = (key: string) => {
    if (!key) return;
    keySearch !== key ? dispatch(setIsDESC(false)) : dispatch(setIsDESC(!isDESC));
    dispatch(setKeySearch(key));
  };

  const handleSelectAll = () => {
    const arrSkuSelect = dataHeader.value_sku ? dataHeader.value_sku.split(', ') : [];
    const arrSKUCurrentPage = items.map((item) => item.sku);
    // dont select all yet
    if (arrSkuSelect?.length < items.length || !items.every((item) => arrSkuSelect.includes(item.sku))) {
      const newArrSKU = [...arrSkuSelect, ...arrSKUCurrentPage];
      const filterArrSKU = Array.from(new Set(newArrSKU));
      dispatch(onChangeDataHeader({ ...dataHeader, value_sku: filterArrSKU.join(', ') }));
      return;
    }
    // disselect sku in current page
    const newArrSKUDiselect = arrSkuSelect.filter((item) => !arrSKUCurrentPage.includes(item));
    dispatch(onChangeDataHeader({ ...dataHeader, value_sku: newArrSKUDiselect.join(', ') }));
  };
  function renderTableSKU() {
    return (
      <div className="table-style-sku border-rd-20">
        <div className="bg-white border-rd-20 bd-sku">
          <Table
            sortOrder={sortOrder}
            dataTableHead={dataProductHeadForSkuOfPromotion}
            status={productStatus}
            message={message}
            className="table striped-table table-responsive box-table-start border-rd-20 "
            keySearch={keySearch}
            isDESC={isDESC}
            onChange={handleSelectAll}
            checked={isCheckAllCurrentPage}
          >
            {productStatus === 'fulfilled' && items && items.length ? (
              <>
                {showFilterSku && (
                  <tr>
                    <td className="td-item py-3 px-2 text-center box-table-start">
                      <div className="d-flex justify-content-center align-items-center">
                        <Select onChange={handleChangeTableFilter} value={filterPayload.sku_in} name="sku_in" className="w-100 resp-select">
                          <option value="">Any</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </div>
                    </td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">
                      <Input value={filterPayload.name} name="name" onChange={handleChangeTableFilter} className="w-100" />
                    </td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">
                      <Input value={filterPayload.sku} name="sku" onChange={handleChangeTableFilter} className="w-100" />
                    </td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">
                      <Select onChange={handleChangeTableFilter} value={filterPayload.type_id} className="w-100" name="type_id">
                        <option value="">All Type</option>
                        <option value="simple">Simple Product</option>
                        <option value="configurable">Configurable Product</option>
                        <option value="virtual">Virtual Product</option>
                      </Select>
                    </td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">
                      <Select
                        name="attribute_set_id"
                        onChange={handleChangeTableFilter}
                        value={filterPayload.attribute_set_id}
                        className="w-100 resp-select"
                      >
                        <option value="">All Attribute Set</option>
                        <option value="4">Default</option>
                        <option value="17">Configuration</option>
                        <option value="13">Simple product</option>
                      </Select>
                    </td>
                  </tr>
                )}
                {items.map((item, i: number) => (
                  <tr key={i}>
                    <td className="td-item py-3 px-2 text-center box-table-start">
                      <div className="d-flex justify-content-center align-items-center ms-3">
                        <CustomCheckBox
                          onChange={() => {
                            const isCheckValueSku = value_sku_arr.includes(item.sku);

                            if (isCheckValueSku) {
                              const newArrValueSku = value_sku_arr.filter((sku) => sku !== item.sku).join(', ');
                              dispatch(onChangeDataHeader({ ...dataHeader, value_sku: newArrValueSku }));
                            } else {
                              const newValueSKu = [...value_sku_arr, item.sku].join(', ');
                              dispatch(onChangeDataHeader({ ...dataHeader, value_sku: newValueSKu }));
                            }
                          }}
                          checked={value_sku_arr.includes(item.sku)}
                        />
                      </div>
                    </td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">{item.name}</td>
                    <td className="td-item py-3 px-2 fw-normal fs-14">{item.sku}</td>
                    <td className="td-item py-3 px-2 fs-14 fw-normal">{formatType(item.type_id)}</td>
                    <td className="td-item py-3 px-2 fw-normal fs-14">
                      {item.attribute_set_id === 17
                        ? 'Configuration'
                        : item.attribute_set_id === 4
                        ? 'Default'
                        : item.attribute_set_id === 13
                        ? 'Simple product'
                        : ''}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <NoRecord tableHeaders={dataProductHead} message={message} />
            )}
          </Table>
        </div>
      </div>
    );
  }

  function filterActive() {
    const newFilterActive = filterEmptyProperties(submitFilterPayload);
    if (!Object.keys(newFilterActive).length) return <></>;

    const arrFilter = Object.entries(newFilterActive);
    return arrFilter.map((item: any, index) => {
      const [label, value] = item;

      const handleRemoveItem = (name: string) => {
        setFitlerPayload({ ...filterPayload, [name]: '' });
        setSubmitFitlerPayload({ ...filterPayload, [name]: '' });
        setPagination({ ...pagination, currentPage: 1 });
        setIsFitler(true);
      };

      let newValue = value;

      if (value === 'simple') {
        newValue = 'Simple Product';
      }
      if (value === 'configurable') {
        newValue = 'Configurable Product';
      }
      if (value === 'virtual') {
        newValue = 'Virtual Product';
      }

      if (value === '4') {
        newValue = 'Default';
      }
      if (value === '17') {
        newValue = 'Configuration';
      }
      if (value === '13') {
        newValue = 'Simple product';
      }
      return (
        <div className="sku-selected d-flex align-items-center px-3 py-2 gap-2" key={index}>
          <span key={index} className="d-block fs-14 font-convert-filter  text-truncate mw-sku">
            {convertKeyToLabelOfPromotionFeature(label)}: {newValue}
          </span>
          <img
            src={closeCirImg}
            alt="close"
            className="cursor-pointer"
            style={{ width: '22px', height: '22px' }}
            onClick={() => handleRemoveItem(label)}
          />
        </div>
      );
    });
  }

  return (
    <>
      <div>
        {showPopup && (
          <PopupWrap className="bg-white h-fit-content popup-size-sku">
            <div className="p-32 overflow-auto" ref={contentRef}>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="fw-normal text-start fw-semibold color-input text-nowrap">ADD SKUs</h3>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setShowPopup(false);
                      setIsChangeCallSku(false);
                      resetFilter();
                      setPagination({ ...pagination, currentPage: 1 });
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} size="2x" color="gray" className="close-icon" />
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div
                    className="filters--buttoncustom d-flex justify-content-center align-items-center fw-medium cursor-pointer"
                    onClick={handleShowFilterSKU}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                  </div>
                  <Input
                    type="search"
                    id="sku"
                    name="value_sku"
                    className="w-100 ms-3 pointer-x"
                    disabled={false}
                    value={value_sku}
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex flex-row gap-2 ">
                  <span className="fw-normal fs-14 border-0 me-2 w-fit-content text-lg-wrap text-nowrap border-right">
                    Total {total_count ? total_count : '0'} Record(s) Found
                  </span>
                  <div className="color-light text-end separator-space align-items-center justify-content-center">|</div>
                  <div className="d-flex custom-gap-tablesku"> {filterActive()}</div>
                </div>

                {renderTableSKU()}

                <Pagination
                  className=""
                  currentPage={+pagination.currentPage}
                  pageSize={+pagination.pageSize}
                  onChangePageSizeEvent={onChangePageSizeEvent}
                  onChangePageEvent={onChangePage}
                  status={productStatus}
                  totalCount={total_count}
                />
              </div>

              <div className="d-flex flex-row justify-content-end mt-4 flex-shrink-0">
                {showFilterSku && (
                  <Button
                    className="d-flex flex-column ms-2 btn-custom-height text-uppercase button"
                    onClick={() => {
                      setIsFitler(true);
                      setSubmitFitlerPayload(filterPayload);
                      setPagination({ ...pagination, currentPage: 1 });
                    }}
                  >
                    Filter
                  </Button>
                )}
                {showFilterSku && (
                  <Button className="d-flex flex-column ms-2 btn-custom-height text-uppercase button" onClick={resetFilter}>
                    Reset Filter
                  </Button>
                )}

                <Button
                  className="d-flex flex-column btn-custom-height text-uppercase ms-2 button"
                  onClick={() => {
                    setShowPopup(false);
                    setIsChangeCallSku(false);
                  }}
                >
                  Confirm
                </Button>

                <Button
                  className="d-flex flex-column btn-custom-height text-uppercase ms-2 button"
                  outline
                  onClick={() => {
                    resetFilter();
                    dispatch(onChangeDataHeader({ ...dataHeader, value_sku: oldValueSKU }));
                    setShowPopup(false);
                    setIsChangeCallSku(false);
                    setPagination({ ...pagination, currentPage: 1 });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </PopupWrap>
        )}
      </div>
      <div className=" line-height-title separator-top mb-4 mt-3 fs-5">Promotion Details</div>
      <div className="wrapper d-flex flex-column gap-3 header mt-3">
        {renderAttrSet()}
        {!showPopup && renderContent()}
      </div>
    </>
  );
};

export default Header;
