import React from 'react';
import Loading from '~/components/common/Loading';
import './style.scss';
/**
 * Hook that alerts clicks outside of the passed ref
 */
interface PropsInterface {
  children: React.ReactNode;
  status: string;
}
const PopupComponent = (props: PropsInterface) => {
  const { children, status } = props;

  return (
    <div className={`main-popup`}>
      <div className="popup-wrapper bg-white">
        {status === 'pending' && <Loading />}
        {status === 'fulfilled' && children}
        {status === 'rejected' && <p>Nothing to show!</p>}
      </div>
    </div>
  );
};

export default PopupComponent;
