import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { registerables } from 'chart.js';
import { Chart as ChartJS } from 'chart.js/auto';
ChartJS.register(...registerables);

interface iBarChart {
  chartData: any;
  options?: any;
  type: 'Bar' | 'Line' | 'Doughnut';
}

function Chart(props: iBarChart) {
  const { type, chartData, options } = props;

  const TypeChart = type === 'Bar' ? Bar : type === 'Doughnut' ? Doughnut : Line;

  return <TypeChart data={chartData} options={options} />;
}

export default Chart;
